.upperDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
    font-family: "Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 2rem;
    padding: 2rem 2rem;
    text-align: center;

}

.payoutHeaderMain {
    width: 23%;
    height: 10rem;
    padding: 2rem;
    padding-top: 1rem;
    font-family: "Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif;

    margin-left: 5%;
    background-color: #DDEFE3;
    border-radius: 15px;

}

.payoutHeader {
    display: flex;
}

.headerIcon svg {
    width: 2rem;
    height: 2rem;
}

.payoutHeader h3 {
    margin-left: 5%;
    font-size: 1.6rem;
}

.payoutPara {
    width: 90%;
    margin-left: 15%;
    font-size: 1.3rem;
    font-family: "Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif;


}

.payoutPara h5 {
    font-weight: 100;
}