* {
    margin: 0;
    padding: 0;
}

.mainPAge_Certificate {
    width: 100%;
}

.headerMAinDIv_Certificate {
    /* background-image: url("../images//agendaBG.png");
    background-size: cover;
    background-repeat: no-repeat; */
    height: 100%;
    padding: 2rem 0;
    margin-top: 4rem;
    position: relative;
    z-index: 1;
}
.headingDiv_Certificate {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem 5rem 0 5rem;
}

.headingDiv_Certificate h1 {
    color: black;
    font-weight: 600;
}



.headingDiv_Certificate h3 {
    padding-top: 2rem;
    width: 84%;
    text-align: center;
    color: #fff;
    text-transform: capitalize;
}

.underLine_Certificate {
    height: 3px;
    background: #FFEE58;
    margin-top: 1rem;
    width: 45%;
    position: relative;
}

.InnerMAinDiv_Certificate {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MAinDiv_Certificate {
    width: 100%;
    display: flex;
    justify-content: center;
    /* padding-top: 4rem; */
}

.MainDiv_Certificate {
    width: 80%;

}

.Header_Certificate {
    text-transform: capitalize;
}

.Header_Certificate h2 {
    font-weight: 600;
    text-align: center;
    color: #0000009e;
}

.underLineHeadingMainDiv_Certificate {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 1rem;
}

.underLineHeading_Certificate {
    height: 2px;
    background-color: #FFEE58;
    width: 5%;
}
.ContentMainDiv_Certificate{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 4rem;
}
.ContentInnerMainDiv_Certificate{
    width: 80%;
    display: flex;
    justify-content: space-between;
}
.contentImgMainDiv_Certificate{
    width: 100%;
}
.contentImgMainDiv_Certificate img{
    width: 100%;
}

