* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    font-weight: 500;
}

.main_Div_Manage {
    width: 100%;
    height: 52rem;
    margin-bottom: 7%;
    padding-top: 3rem;
}

.header_Manage {
    text-align: center;
    font-size: 1.8rem;
    padding: 2rem;
}

.manage_Manage {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.contain_Manage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3%;
    width: 20%;
    text-align: center;
    font-size: 1.3rem;
}

.containH4Div_Manage {
    display: flex;
    justify-content: center;
}

.contain_Manage h4 {
    font-weight: 200;
    width: 80%;

}

.contain_Manage button {
    margin-top: 5%;
    width: 200px;
    height: 30px;
    border: 1px solid;
    border-radius: 5px;
    background-color: transparent;
}

.card_1_Manage {
    width: 32%;
    margin-left: 40% !important;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* border: 1px solid #787777; */
    margin-top: 5%;
    -webkit-box-shadow: 0 0 10px #787777 !important;
    box-shadow: 0 0 10px #787777 !important;
    border-radius: 15px;
}

.iconMainDiv_Manage {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.iconDiv_Manage {
    border: 1px solid;
    width: 60px !important;
    height: 20px !important;
    padding: 2px !important;
}

.iconDiv_Manage img {
    width: 13px;
    height: 15px;
}

.name_Btn_Manage {
    margin-top: 10%;

}

.name_Btn_Manage button {
    margin-top: 5%;
    width: 100px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid;
    background-color: #FFEE58;
}

.lineDemo_Manage {
    height: 100px;
}

.lineDemo1_Manage {
    /* border: 1px solid black; */
    width: 90%;
    height: 10px;
    margin: 5% 0% 0% 0%;
    background-color: #C0C0C0;
}

.lineDemo2_Manage {
    /* border: 1px solid black; */
    width: 70%;
    height: 10px;
    margin: 5% 0% 0% 0%;
    background-color: #C0C0C0;

}

.lineDemo3_Manage {
    /* border: 1px solid black; */
    width: 100%;
    height: 10px;
    margin: 5% 0% 0% 0%;
    background-color: #C0C0C0;

}

.card2Header_Manage {
    text-align: end;
    margin-right: 3%;
    margin-top: 5%;
}

.card2MianDiv_Manage {
    width: 30%;
    height: auto;
    /* border: 1px solid; */
    position: absolute;
    margin-left: 28%;
    margin-top: -5%;
    background-color: #fff;
    -webkit-box-shadow: 0 0 10px #787777;
    box-shadow: 0 0 10px #787777;
    border-radius: 15px;
}

.card2InnerMainDiv_Manage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card2Header_Manage {
    text-align: end;
    margin-right: 10%;
    font-size: 1.4rem;
}

.card2leftDiv_Manage {
    width: 80%;
    margin-left: 10%;
}

.card2leftdetails_Manage {
    /* border: 1px solid green; */
    background-color: #DDEFE3;
    margin-top: -20%;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: ; */
    /* justify-content: space-around; */
}

.card2leftdetails1_Manage {

    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    width: 100%;
    margin-bottom: 5%;
    height: auto;

}

.card2leftdetails1_Manage h4 {
    margin-left: 10%;
    font-weight: 400;
}

.card2LowerMainDiv_Manage {
    background-color: #DDEFE3;
    margin-top: 20%;
    border-radius: 5px;
    width: 70%;
    text-align: center;
    height: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid blue; */
}

.card1lowerFirst_Manage {
    /* border: 1px solid; */

}

.lastIconDiv_Manage {
    margin-top: 25%;
    width: 100%;
}

.lastIcon_Manage {
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    width: 70%;
    height: 2rem;
    padding: 0.3rem;
    background-color: #DDEFE3;

}

.card3mainDiv_Manage {
    width: 11.1%;
    height: 15%;
    /* border: 1px solid red; */
    position: absolute;
    margin-left: 58.4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    margin-top: 1%;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 10px #787777;
    box-shadow: 0 0 10px #787777;
}

.card3mainDiv_Manage h2 {
    font-size: 15px;
    font-weight: 100;
}

.card3mainDiv_Manage img {
    width: 1em;
    height: 1em;
}

.card3mainDiv_header_Manage {
    width: 80%;
    margin-left: 15%;
    /* border: 1px solid green; */
    display: flex;
    justify-content: space-around;
}

.card3mainDiv_contain_Manage {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.card3mainDiv_Card3_Manage {
    display: flex;
    margin-top: 3%;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.card3mainDiv_Card3_Manage h2 {
    font-size: 13px;
}

.card4mainDiv_Manage {
    margin-left: 71%;
    margin-top: -10%;
    width: 20%;
    height: 22rem;
    position: absolute;
    border: 1px solid;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 10px #787777;
    box-shadow: 0 0 10px #787777;
}



.card4InnerDiv_Manage {
    margin-top: 5%;
    display: flex;
    justify-content: space-around;
}

.card4mainDivImage_Manage {
    width: 10rem;
    height: 20rem;
    left: 50%;
    margin-left: -60%;
    /* margin-right: -50vw; */
    max-width: 10vw;
    position: relative;
    right: 50%;
    width: 100vw;
}

.card4RightDiv_Manage {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.card4RightContianDiv_Manage {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-top: 10%;
}

.designShade_Manage {
    width: 647px;
    height: 339px;
    flex-shrink: 0;
    background: rgba(221, 239, 227, 0.50);
}

.donate_Manage {
    border: 1px solid;
    width: 5rem;
    height: 2rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #FFEE58;
}

@media (min-width:720px) and (max-width:1080px) {
    .card_1_Manage {
        width: 41%;
        margin-left: 33% !important;
    }

    .card2MianDiv_Manage {
        margin-left: 5%;
        width: 44%;
        height: auto !important;
    }

    .card3mainDiv_Manage {
        width: 20%;
        margin-left: 49.3%;
    }

    .card4mainDiv_Manage {
        width: 27%;
    }

    .manageFundUpperBtn {
        width: 100%;
    }

    .manageFundUpperBtn button {
        width: 100%;
    }

    .contain_Manage {
        height: 13rem;
        display: flex;
        justify-content: space-around;
        font-size: 1rem;
    }

    .rightCircleShadeMainDiv {
        display: flex;
        justify-content: center;
        height: 211px;
        position: absolute;
        width: 100%;
    }

    .rightCircleShade {
        margin-left: 78.7%;
        width: 348px;
        height: 174px;
    }
    .card2InnerMainDiv_Manage{
        margin-bottom: 5%;
    }
}

@media (min-width:1080px) and (max-width:1440px) {
    .card_1_Manage {
        width: 41%;
        margin-left: 33% !important;
    }

    .card2MianDiv_Manage {
        margin-left: 5%;
        width: 44%;
        height: auto;
    }

    .card3mainDiv_Manage {
        width: 20%;
        margin-left: 49.3%;
    }

    .card4mainDiv_Manage {
        width: 27%;
    }

    .manageFundUpperBtn {
        width: 100%;
    }

    .manageFundUpperBtn button {
        width: 100%;
    }

    .contain_Manage {
        height: 13rem;
        display: flex;
        justify-content: space-around;
        font-size: 1rem;
    }

    .rightCircleShadeMainDiv {
        display: flex;
        justify-content: center;
        height: 211px;
        position: absolute;
        width: 100%;
    }

    .rightCircleShade {
        margin-left: 78.7%;
        width: 348px;
        height: 174px;
    }
    .card2leftdetails_Manage{
        margin-bottom: 5%;
    }
    .lastIconDiv_Manage{
        margin-bottom: 5%;
    }
}

@media (min-width:320px) and (max-width:375px) {
    .rightCircleShadeMainDiv {
        display: none;
    }
}

@media (min-width:320px) and (max-width:375px) {
    .header_Manage {
        font-size: 1rem;
    }

    .designShade_Manage {
        width: 30%;
        height: 25%;
    }

    .contain_Manage_img img {
        width: 30px;
        height: 30px;
    }

    .containH4Div_Manage {
        font-size: 0.8rem;
    }

    .manageFundUpperBtn {
        width: 100%;
    }

    .manageFundUpperBtn button {
        width: 100%;
    }

    .contain_Manage {
        justify-content: space-between;
    }

    .card_1_Manage {
        width: 55%;
        margin-left: 21% !important;
    }

    .tajImg {
        width: 3rem !important;
    }

    .rightMinDivManage {
        width: 30%;
    }

    .iconDiv_Manage {
        width: 20px !important;
        height: 20px !important;
    }

    .name_Btn_Manage h3 {
        font-size: 0.5rem;
    }

    .name_Btn_Manage button {
        width: 70%;
        font-size: 0.5rem;
        height: 19px;
    }

    .lineDemo1_Manage {
        height: 5px;
    }

    .card2MianDiv_Manage {
        margin-left: 5%;
        width: 41%;
        height: auto;
    }

    .card2Header_Manage {
        font-size: 0.7rem;
        margin-right: 5%;
    }

    .card2leftImg_Manage img {
        width: 90%;
    }

    .card2leftdetails_Manage {
        font-size: 0.5rem;
        height: 4rem;
    }

    .card2LowerMainDiv_Manage {
        width: 90%;
    }

    .card2LowerMainDiv_Manage h3 {
        font-size: 0.7rem;

    }

    .card2LowerMainDiv_Manage h4 {
        font-size: 0.6rem;
    }

    .lastIconDiv_Manage {
        margin-top: 10%;
    }

    .lastIcon_Manage {
        width: 90%;
        margin-bottom: 5%;

    }

    .card4mainDiv_Manage {
        width: 30%;
        height: 15rem;
    }

    .card4mainDivImage_Manage {
        height: 14rem;
    }

    .card4RightDiv_ManageTittle {
        font-size: 0.8rem;
    }

    .card4RightContianDiv_Manage img {
        width: 0.5rem !important;
    }

    .card4RightContianDiv_Manage h5 {
        font-size: 0.5rem;
    }

    .card4RightDiv_Manage_price {
        font-size: 0.5rem;
    }

    .donate_Manage {
        width: 80%;
        font-size: 0.9rem;
        height: 1.5rem;
    }

    .card3mainDiv_Manage {
        width: 24%;
        margin-left: 46%;
    }

    .card3mainDiv_Manage h2 {
        font-size: 0.5rem;
    }

    .card3mainDiv_Card3_Manage h2 {
        font-size: 0.4rem;
    }

    .main_Div_Manage {
        height: 35rem;
    }

}

@media (min-width:375px) and (max-width:420px) {
    .header_Manage {
        font-size: 1rem;
    }

    .designShade_Manage {
        width: 30%;
        height: 25%;
    }

    .contain_Manage_img img {
        width: 30px;
        height: 30px;
    }

    .containH4Div_Manage {
        font-size: 0.8rem;
    }

    .manageFundUpperBtn {
        width: 100%;
    }

    .manageFundUpperBtn button {
        width: 100%;
    }

    .contain_Manage {
        justify-content: space-between;
    }

    .card_1_Manage {
        width: 55%;
        margin-left: 21% !important;
    }

    .tajImg {
        width: 3rem !important;
    }

    .rightMinDivManage {
        width: 30%;
    }

    .iconDiv_Manage {
        width: 20px !important;
        height: 20px !important;
    }

    .name_Btn_Manage h3 {
        font-size: 0.5rem;
    }

    .name_Btn_Manage button {
        width: 70%;
        font-size: 0.5rem;
        height: 19px;
    }

    .lineDemo1_Manage {
        height: 5px;
    }

    .card2MianDiv_Manage {
        margin-left: 5%;
        width: 41%;
        height: auto;
    }

    .card2Header_Manage {
        font-size: 0.7rem;
        margin-right: 5%;
    }

    .card2leftImg_Manage img {
        width: 90%;
    }

    .card2leftdetails_Manage {
        font-size: 0.5rem;
        height: 4rem;
    }

    .card2LowerMainDiv_Manage {
        width: 90%;
    }

    .card2LowerMainDiv_Manage h3 {
        font-size: 0.7rem;

    }

    .card2LowerMainDiv_Manage h4 {
        font-size: 0.6rem;
    }

    .lastIconDiv_Manage {
        margin-top: 10%;
    }

    .lastIcon_Manage {
        width: 90%;
        margin-bottom: 5%;

    }

    .card4mainDiv_Manage {
        width: 30%;
        height: 15rem;
    }

    .card4mainDivImage_Manage {
        height: 14rem;
    }

    .card4RightDiv_ManageTittle {
        font-size: 0.8rem;
    }

    .card4RightContianDiv_Manage img {
        width: 0.5rem !important;
    }

    .card4RightContianDiv_Manage h5 {
        font-size: 0.5rem;
    }

    .card4RightDiv_Manage_price {
        font-size: 0.5rem;
    }

    .donate_Manage {
        width: 80%;
        font-size: 0.9rem;
        height: 1.5rem;
    }

    .card3mainDiv_Manage {
        width: 24%;
        margin-left: 46%;
    }

    .card3mainDiv_Manage h2 {
        font-size: 0.5rem;
    }

    .card3mainDiv_Card3_Manage h2 {
        font-size: 0.4rem;
    }

    .main_Div_Manage {
        height: 35rem;
    }

}

@media (min-width:420px) and (max-width:580px) {
    .header_Manage {
        font-size: 1rem;
    }

    .designShade_Manage {
        width: 30%;
        height: 25%;
    }

    .contain_Manage_img img {
        width: 30px;
        height: 30px;
    }

    .containH4Div_Manage {
        font-size: 0.8rem;
    }

    .manageFundUpperBtn {
        width: 100%;
    }

    .manageFundUpperBtn button {
        width: 100%;
    }

    .contain_Manage {
        justify-content: space-between;
    }

    .card_1_Manage {
        width: 55%;
        margin-left: 21% !important;
    }

    .tajImg {
        width: 3rem !important;
    }

    .rightMinDivManage {
        width: 30%;
    }

    .iconDiv_Manage {
        width: 20px !important;
        height: 20px !important;
    }

    .name_Btn_Manage h3 {
        font-size: 0.5rem;
    }

    .name_Btn_Manage button {
        width: 70%;
        font-size: 0.5rem;
        height: 19px;
    }

    .lineDemo1_Manage {
        height: 5px;
    }

    .card2MianDiv_Manage {
        margin-left: 5%;
        width: 41%;
        height: auto;
    }

    .card2Header_Manage {
        font-size: 0.7rem;
        margin-right: 5%;
    }

    .card2leftImg_Manage img {
        width: 90%;
    }

    .card2leftdetails_Manage {
        font-size: 0.5rem;
        height: 4rem;
    }

    .card2LowerMainDiv_Manage {
        width: 90%;
    }

    .card2LowerMainDiv_Manage h3 {
        font-size: 0.7rem;

    }

    .card2LowerMainDiv_Manage h4 {
        font-size: 0.6rem;
    }

    .lastIconDiv_Manage {
        margin-top: 10%;
    }

    .lastIcon_Manage {
        width: 90%;
        margin-bottom: 5%;

    }

    .card4mainDiv_Manage {
        width: 30%;
        height: 15rem;
    }

    .card4mainDivImage_Manage {
        height: 14rem;
    }

    .card4RightDiv_ManageTittle {
        font-size: 0.8rem;
    }

    .card4RightContianDiv_Manage img {
        width: 0.5rem !important;
    }

    .card4RightContianDiv_Manage h5 {
        font-size: 0.5rem;
    }

    .card4RightDiv_Manage_price {
        font-size: 0.5rem;
    }

    .donate_Manage {
        width: 80%;
        font-size: 0.9rem;
        height: 1.5rem;
    }

    .card3mainDiv_Manage {
        width: 24%;
        margin-left: 46%;
    }

    .card3mainDiv_Manage h2 {
        font-size: 0.5rem;
    }

    .card3mainDiv_Card3_Manage h2 {
        font-size: 0.4rem;
    }

    .main_Div_Manage {
        height: 35rem;
    }

}

@media (min-width:580px) and (max-width:720px) {
    .header_Manage {
        font-size: 1rem;
    }

    .designShade_Manage {
        width: 30%;
        height: 25%;
    }

    .contain_Manage_img img {
        width: 30px;
        height: 30px;
    }

    .containH4Div_Manage {
        font-size: 0.8rem;
    }

    .manageFundUpperBtn {
        width: 100%;
    }

    .manageFundUpperBtn button {
        width: 100%;
    }

    .contain_Manage {
        justify-content: space-between;
    }

    .card_1_Manage {
        width: 55%;
        margin-left: 21% !important;
    }

    .tajImg {
        width: 3rem !important;
    }

    .rightMinDivManage {
        width: 30%;
    }

    .iconDiv_Manage {
        width: 20px !important;
        height: 20px !important;
    }

    .name_Btn_Manage h3 {
        font-size: 0.5rem;
    }

    .name_Btn_Manage button {
        width: 70%;
        font-size: 0.5rem;
        height: 19px;
    }

    .lineDemo1_Manage {
        height: 5px;
    }

    .card2MianDiv_Manage {
        margin-left: 5%;
        width: 41%;
        height: auto;

    }

    .card2Header_Manage {
        font-size: 0.7rem;
        margin-right: 5%;
    }

    .card2leftImg_Manage img {
        width: 90%;
    }

    .card2leftdetails_Manage {
        font-size: 0.5rem;
        height: 4rem;
    }

    .card2LowerMainDiv_Manage {
        width: 90%;
    }

    .card2LowerMainDiv_Manage h3 {
        font-size: 0.7rem;

    }

    .card2LowerMainDiv_Manage h4 {
        font-size: 0.6rem;
    }

    .lastIconDiv_Manage {
        margin-top: 10%;
        margin-bottom: 5%;
    }

    .lastIcon_Manage {
        width: 90%;
        margin-bottom: 5%;

    }

    .card4mainDiv_Manage {
        width: 30%;
        height: 15rem;
    }

    .card4mainDivImage_Manage {
        height: 14rem;
    }

    .card4RightDiv_ManageTittle {
        font-size: 0.8rem;
    }

    .card4RightContianDiv_Manage img {
        width: 0.5rem !important;
    }

    .card4RightContianDiv_Manage h5 {
        font-size: 0.5rem;
    }

    .card4RightDiv_Manage_price {
        font-size: 0.5rem;
    }

    .donate_Manage {
        width: 80%;
        font-size: 0.9rem;
        height: 1.5rem;
    }

    .card3mainDiv_Manage {
        width: 24%;
        margin-left: 46%;
    }

    .card3mainDiv_Manage h2 {
        font-size: 0.5rem;
    }

    .card3mainDiv_Card3_Manage h2 {
        font-size: 0.4rem;
    }

    .main_Div_Manage {
        height: 35rem;
    }

}

@media (min-width:1440px) and (max-width:1980px) {
    .card2MianDiv_Manage {
        height: auto;
        margin-top: -2%;
    }

    .mobileToolBarMenu_toolbarNenu1 {
        font-size: 1.3rem;
    }
    .mobileToolBarMenu_howitwork{
        font-size: 1.3rem;
    }
    .mobileToolBarMenu_dropbtn{
        font-size: 1.3rem;
    }
    .lastIconDiv_Manage {
        margin-bottom: 5%;
    }

}

@media (min-width:1980px) {
    .card2MianDiv_Manage {
        height: auto;
    }

    .lastIconDiv_Manage {
        margin-bottom: 5%;
    }

    .card2MianDiv_Manage {
        margin-top: 0;
    }

    .tajImg {
        width: 14rem;
    }

    .card4mainDivImage_Manage {
        height: auto;
    }

    .card4mainDiv_Manage {
        height: auto;
    }
    .mobileToolBarMenu_toolbarNenu1 {
        font-size: 1.6rem;
    }
    .mobileToolBarMenu_howitwork{
        font-size: 1.6rem;
    }
    .mobileToolBarMenu_dropbtn{
        font-size: 1.6rem;
    }
}