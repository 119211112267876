* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    font-weight: 500;
}

.SimpleStepShade {
    position: absolute;
    width: 100%;
    margin-top: -5%;
}

.SimpleStepShade2 {
    position: absolute;
    width: 100%;
    margin-top: -13.3%;
    opacity: .2;
}

.headerSimpleStep {
    text-align: center;
    font-size: 2rem;
    filter: brightness(100);
    margin-bottom: 2%;
    padding-top: 3rem;
}

.step1 {
    display: flex;
    flex-direction: row;
    margin-left: 15%;
    filter: brightness(100);
}

.step1Heading {
    /* margin-left: 15%; */
    font-size: 3.5rem;
}
.step3HeadingHeader{
    text-decoration: underline;
}

.step1Icon {
    width: 45px;
    height: 45px;
}

.footStep1Rignt {
    margin-left: 5%;
}

.footStep1 {
    width: 35%;
    display: flex;
    justify-content: space-around;
}

.footStep1RightDiv {
    margin-right: 1%;
    width: 20%;
    display: flex;
    justify-content: space-evenly;
}

.footStep1RightUpper {
    width: 70px;
    height: 80px;
}

.footStep1Right {
    margin-left: 2%;
    width: 70px;
    height: 85px;
}

.rotateFoot1 {
    transform: rotate(45deg);
    margin-left: 25%;
    margin-top: 40%;
    margin-bottom: 15%;
}

.rotateFoot2 {
    transform: rotate(90deg);
    margin-left: 100%;
    margin-top: 25%;
}

.rotateFoot3 {
    transform: rotate(165deg);
    /* margin-right: 12%; */
    /* margin-top: 25%; */
}

.rotateFoot_3 {
    transform: rotate(165deg);
    /* margin-right: 12%; */
    /* margin-top: 25%; */
}

.footStep1RightRev {
    transform: rotate(180deg);
    margin-right: 12%;
    margin-bottom: 95%;
}

.step2 {
    display: flex;
    flex-direction: row;
    width: 85%;
}

.step2Main {
    display: flex;
    flex-direction: row;
    margin-left: 15%;
    margin-top: -5%;
    filter: brightness(100);
}

.step2Icon {
    width: 45px;
    height: 45px;
}

.footLeft1 {
    transform: rotate(135deg);
    filter: brightness(100);
}

.footLeft2 {
    transform: rotate(45deg);
}

.footLeft3 {
    transform: rotate(0deg);
    margin-left: 2%;
}

.step3Main {
    width: 100%;
    filter: brightness(100);
}

.step3 {
    width: 40%;
    margin-left: 60%;
}

.step3 h4 {
    width: 50%;
    font-weight: 400;
    font-size: 1.2rem;
}

.step2 h4 {
    font-weight: 400;
    font-size: 1.2rem;
}

.step1 h4 {
    font-size: 1.2rem;
    font-weight: 400;
}

p {
    margin-top: 2%;
}

.stepLowerDiv {
    width: 100%;
    margin-top: 4%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.StepLowerDivBtn {
    width: 60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 20%;
}

.startBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    width: 390px;
    height: 50px;
    border-radius: 10px;
    background-color: #FFEE58;
    border: 1px solid;
    z-index: 1;
}

.talkBtn {
    width: 200px;
    border-radius: 10px;
    height: 50px;
    background-color: transparent;
    border: 1px solid;
    z-index: 1;
}

.chatBtn {
    width: 60%;
    margin-left: 20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 3%;
}

.chatUSBtn {
    width: 200px;
    border-radius: 10px;
    height: 50px;
    background-color: transparent;
    border: 1px solid;
    z-index: 1;
}

.step1_Heading {
    width: 40%;
}

.footStep2Rotate {
    display: flex;
    margin-left: 20%;
}
.step1Header{
    font-size: 1.5rem;
    display: flex;
}
.step1Header h1{
    text-decoration: underline;
}
.step2Header h1{
    text-decoration: underline !important;
}
.step2Header{
    font-size: 1.5rem;
}

@media (min-width:720px) and (max-width:1080px) {
    .headerSimpleStep {
        font-size: 1.55rem;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .footStep1RightUpper {
        width: 50px;
        height: 50px;
    }

    .footStep1Right {
        width: 50px;
        height: 50px;
    }

    .step1Heading {
        font-size: 1.2rem;
    }

    .step1_Heading {
        width: 36%;
    }

    .step1Header {
        font-size: 0.6rem;
    }
    .step1Icon{
        width: 30px;
        height: 25px;
    }
    .step1 h4{
        font-size: 1rem;
    }

    .rotateFoot1 {
        width: 50px;
        height: 50px;
        margin-top: 10%;
    }

    .rotateFoot2 {
        width: 50px;
        height: 50px;
        margin-left: 36%;
        margin-top: 0;
    }

    .rotateFoot_3 {
        width: 50px;
        height: 50px;
    }

    .rotateFoot3 {
        width: 50px;
        height: 50px;
    }

    .step1Heading {
        font-size: 1.2rem;
    }

    .step2_Heading {
        width: 40%;
    }

    .step2Header {
        font-size: 0.6rem;
    }

    .step2 {
        width: 90%;
    }
    .step2 h4{
        font-size: 1rem;
    }

    .footStep2Rotate {
        margin-left: 15%;
    }

    .footLeft1 {
        width: 50px;
        height: 50px;
    }

    .footLeft2 {
        width: 50px;
        height: 50px;
    }

    .footLeft3 {
        width: 50px;
        height: 50px;
    }

    .step3 h4 {
        width: 70%;
        font-size: 1rem;
    }

    .StepLowerDivBtn {
        width: 70%;
    }

    .chatBtn {
        width: 80%;
        margin-left: 10%;
    }

    .headerSimpleStepH1 {
        width: 90%;
    }

}

@media (min-width:1080px) and (max-width:1440px) {
    .headerSimpleStep {
        font-size: 1.55rem;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .footStep1RightUpper {
        width: 50px;
        height: 50px;
    }

    .footStep1Right {
        width: 50px;
        height: 50px;
    }

    .step1Heading {
        font-size: 1.5rem;
    }

    .step1_Heading {
        width: 36%;
    }

    .step1Header {
        font-size: 0.8rem;
    }

    .rotateFoot1 {
        width: 50px;
        height: 50px;
        margin-top: 10%;
    }

    .rotateFoot2 {
        width: 50px;
        height: 50px;
        margin-left: 36%;
        margin-top: 0;
    }

    .rotateFoot_3 {
        width: 50px;
        height: 50px;
    }

    .rotateFoot3 {
        width: 50px;
        height: 50px;
    }

    .step1Heading {
        font-size: 1.5rem;
    }

    .step2_Heading {
        width: 40%;
    }

    .step2Header {
        font-size: 0.8rem;
    }

    .step2 {
        width: 100%;
    }

    .footStep2Rotate {
        margin-left: 6%;
        display: flex;
        justify-content: space-between;
        width: 33%;
    }

    .footLeft1 {
        width: 50px;
        height: 50px;
    }

    .footLeft2 {
        width: 50px;
        height: 50px;
    }

    .footLeft3 {
        width: 50px;
        height: 50px;
    }

    .step3 h4 {
        width: 70%;
    }

    .StepLowerDivBtn {
        width: 70%;
    }

    .chatBtn {
        width: 80%;
        margin-left: 10%;
    }

    .headerSimpleStepH1 {
        width: 90%;
    }

}

@media (min-width:320px) and (max-width:375px) {


    .headerSimpleStepH1 {
        font-size: 1.5rem;
    }

    .step1 {
        margin-left: 0;
    }

    .footStep1RightDiv {
        margin-left: 0;
    }

    .footStep1RightUpper {
        width: 20px;
        height: 35px;
    }

    .step1Heading {
        font-size: 1rem;

    }

    .step1_Heading {
        width: 52%;
    }

    .step1Header h1 {
        font-size: 0.8rem;
    }

    .step1Icon {
        width: 20px;
        height: 20px;
    }

    .step1 h4 {
        font-size: 0.8rem;
    }

    .footStep1Right {
        width: 20px;
        height: 35px;
    }

    .rotateFoot1,
    .rotateFoot2,
    .rotateFoot_3 {
        width: 20px;
        height: 35px;
    }

    .footStep1 {
        height: 20px;
        width: 20px;
        margin-top: -2%;
    }

    .step2 {
        margin-left: 9%;
    }

    .step2Main {
        padding-top: 3rem;
        padding-left: 1rem;
        margin-left: 0;

    }

    .step1Heading {
        font-size: 1rem;
    }

    .step2Header h1 {
        font-size: 1rem;
    }

    .step2_Heading h4 {
        font-size: 0.8rem;
    }

    .step2_Heading p {
        font-size: 0.6rem;
    }

    .rotateFoot3 {
        width: 20px;
        height: 20px;
    }

    .footStep2Rotate {
        margin-left: 0;
    }

    .rotateFoot3MobileView {
        display: none;
    }

    .footLeft1 {
        width: 20px;
        height: 20px;
    }

    .footLeft2 {
        width: 20px;
        height: 20px;
    }

    .footLeft3 {
        width: 20px;
        height: 20px;
    }

    .step3Heading {
        font-size: 1rem;
    }

    .step3Inner h4 {
        font-size: 0.8rem;
    }

    .step3Inner p {
        font-size: 0.6rem;
    }

    .step3 h4 {
        width: 100%;
    }

    .StepLowerDivBtn {
        width: 100%;
        margin-left: 0;
    }

    .startBtn {
        font-size: 0.7rem;
        width: 60%;
    }

    .talkBtn {
        font-size: 0.7rem;
        width: 30%;
    }

    .chatBtn {
        width: 100%;
        margin-left: 1%;
    }

    .chatBtn h4 {
        font-size: 0.7rem;
    }

    .chatUSBtn {
        font-size: 0.7rem;
    }

}

@media (min-width:375px) and (max-width:420px) {


    .headerSimpleStepH1 {
        font-size: 1.5rem;
    }

    .step1 {
        margin-left: 0;
    }

    .footStep1RightDiv {
        margin-left: 0;
    }

    .footStep1RightUpper {
        width: 20px;
        height: 35px;
    }

    .step1Heading {
        font-size: 1rem;

    }

    .step1_Heading {
        width: 52%;
    }

    .step1Header h1 {
        font-size: 0.8rem;
    }

    .step1Icon {
        width: 20px;
        height: 20px;
    }

    .step1 h4 {
        font-size: 0.8rem;
    }

    .footStep1Right {
        width: 20px;
        height: 35px;
    }

    .rotateFoot1,
    .rotateFoot2,
    .rotateFoot_3 {
        width: 20px;
        height: 35px;
    }

    .footStep1 {
        height: 20px;
        width: 20px;
        margin-top: -2%;
    }

    .step2 {
        margin-left: 9%;
    }

    .step2Main {
        padding-top: 3rem;
        padding-left: 1rem;
        margin-left: 0;

    }

    .step1Heading {
        font-size: 1rem;
    }

    .step2Header h1 {
        font-size: 1rem;
    }

    .step2_Heading h4 {
        font-size: 0.8rem;
    }

    .step2_Heading p {
        font-size: 0.6rem;
    }

    .rotateFoot3 {
        width: 20px;
        height: 20px;
    }

    .footStep2Rotate {
        margin-left: 0;
    }

    .rotateFoot3MobileView {
        display: none;
    }

    .footLeft1 {
        width: 20px;
        height: 20px;
    }

    .footLeft2 {
        width: 20px;
        height: 20px;
    }

    .footLeft3 {
        width: 20px;
        height: 20px;
    }

    .step3Heading {
        font-size: 1rem;
    }

    .step3Inner h4 {
        font-size: 0.8rem;
    }

    .step3Inner p {
        font-size: 0.6rem;
    }

    .step3 h4 {
        width: 100%;
    }

    .StepLowerDivBtn {
        width: 100%;
        margin-left: 0;
    }

    .startBtn {
        font-size: 0.7rem;
        width: 60%;
    }

    .talkBtn {
        font-size: 0.7rem;
        width: 30%;
    }

    .chatBtn {
        width: 100%;
        margin-left: 1%;
    }

    .chatBtn h4 {
        font-size: 0.7rem;
    }

    .chatUSBtn {
        font-size: 0.7rem;
    }

}

@media (min-width:420px) and (max-width:580px) {


    .headerSimpleStepH1 {
        font-size: 1.5rem;
    }

    .step1 {
        margin-left: 0;
    }

    .footStep1RightDiv {
        margin-left: 0;
    }

    .footStep1RightUpper {
        width: 20px;
        height: 35px;
    }

    .step1Heading {
        font-size: 1rem;

    }

    .step1_Heading {
        width: 52%;
    }

    .step1Header h1 {
        font-size: 0.8rem;
    }

    .step1Icon {
        width: 20px;
        height: 20px;
    }

    .step1 h4 {
        font-size: 0.8rem;
    }

    .footStep1Right {
        width: 20px;
        height: 35px;
    }

    .rotateFoot1,
    .rotateFoot2,
    .rotateFoot_3 {
        width: 20px;
        height: 35px;
    }

    .rotateFoot2 {
        margin-top: -77%;
    }

    .footStep1 {
        height: 20px;
        width: 20px;
        margin-top: -2%;
    }

    .step2 {
        margin-left: 9%;
    }

    .step2Main {
        padding-top: 3rem;
        padding-left: 1rem;
        margin-left: 0;

    }

    .step1Heading {
        font-size: 1rem;
    }

    .step2Header h1 {
        font-size: 1rem;
    }

    .step2_Heading h4 {
        font-size: 0.8rem;
    }

    .step2_Heading {
        width: 60%;
    }

    .step2_Heading p {
        font-size: 0.6rem;
    }

    .rotateFoot3 {
        width: 20px;
        height: 20px;
    }

    .footStep2Rotate {
        margin-left: 0;
    }

    .rotateFoot3MobileView {
        display: none;
    }

    .footLeft1 {
        width: 20px;
        height: 20px;
    }

    .footLeft2 {
        width: 20px;
        height: 20px;
    }

    .footLeft3 {
        width: 20px;
        height: 20px;
    }

    .step3Heading {
        font-size: 1rem;
    }

    .step3Inner h4 {
        font-size: 0.8rem;
    }

    .step3Inner p {
        font-size: 0.6rem;
    }

    .step3 h4 {
        width: 100%;
    }

    .StepLowerDivBtn {
        width: 100%;
        margin-left: 0;
    }

    .startBtn {
        font-size: 0.7rem;
        width: 60%;
    }

    .talkBtn {
        font-size: 0.7rem;
        width: 30%;
    }

    .chatBtn {
        width: 100%;
        margin-left: 1%;
    }

    .chatBtn h4 {
        font-size: 0.7rem;
    }

    .chatUSBtn {
        font-size: 0.7rem;
    }

}

@media (min-width:580px) and (max-width:720px) {


    .headerSimpleStepH1 {
        font-size: 1.5rem;
    }

    .step1 {
        margin-left: 0;
    }

    .footStep1RightDiv {
        margin-left: 0;
    }

    .footStep1RightUpper {
        width: 20px;
        height: 35px;
    }

    .step1Heading {
        font-size: 1rem;

    }

    .step1_Heading {
        width: 52%;
    }

    .step1Header h1 {
        font-size: 0.8rem;
    }

    .step1Icon {
        width: 20px;
        height: 20px;
    }

    .step1 h4 {
        font-size: 0.8rem;
    }

    .footStep1Right {
        width: 20px;
        height: 35px;
    }

    .rotateFoot1,
    .rotateFoot2,
    .rotateFoot_3 {
        width: 20px;
        height: 35px;
    }
    .rotateFoot2{
        margin-top: -88%;
    }

    .footStep1 {
        height: 20px;
        width: 20px;
        margin-top: -2%;
    }

    .step2 {
        margin-left: 9%;
    }

    .step2Main {
        padding-top: 3rem;
        padding-left: 1rem;
        margin-left: 0;

    }

    .step1Heading {
        font-size: 1rem;
    }

    .step2Header h1 {
        font-size: 1rem;
    }

    .step2_Heading h4 {
        font-size: 0.8rem;
    }

    .step2_Heading p {
        font-size: 0.6rem;
    }

    .step2_Heading {
        width: 70%;
    }

    .rotateFoot3 {
        width: 20px;
        height: 20px;
    }

    .footStep2Rotate {
        margin-left: 0;
    }

    .rotateFoot3MobileView {
        display: none;
    }

    .footLeft1 {
        width: 20px;
        height: 20px;
    }

    .footLeft2 {
        width: 20px;
        height: 20px;
    }

    .footLeft3 {
        width: 20px;
        height: 20px;
    }

    .step3Heading {
        font-size: 1rem;
    }

    .step3Inner h4 {
        font-size: 0.8rem;
    }

    .step3Inner p {
        font-size: 0.6rem;
    }

    .step3 h4 {
        width: 100%;
    }

    .StepLowerDivBtn {
        width: 100%;
        margin-left: 0;
    }

    .startBtn {
        font-size: 0.7rem;
        width: 60%;
    }

    .talkBtn {
        font-size: 0.7rem;
        width: 30%;
    }

    .chatBtn {
        width: 100%;
        margin-left: 1%;
    }

    .chatBtn h4 {
        font-size: 0.7rem;
    }

    .chatUSBtn {
        font-size: 0.7rem;
    }

}

@media (min-width:1440px) {
    .footStep2Rotate {
        margin-left: 0;
    }

    .step2 {
        width: 76%;
    }

    .rotateFoot1 {
        transform: rotate(45deg);
        margin-left: 25%;
        margin-top: 14%;
        margin-bottom: 15%;
    }

    .rotateFoot2 {
        transform: rotate(90deg);
        margin-left: 68%;
        margin-top: 0%;
    }

    .rotateFoot_3 {
        margin-left: 20%;
    }
    /* .SimpleStepShade{
        display: none;
    } */
    .rotateFoot3{
        width: 70px;
        height: 85px;
    }
    .step3Main{
        margin-top: 0%;
    }
    .footStep2Rotate{
        width: 50%;
        display: flex;
        justify-content: space-around;
    }

    .step2Main{
        margin-top: 0;
    }
}