* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-weight: 500;
}

.UpperShade {
    position: absolute;
    width: 100%;
}

.bodyHeading {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 2rem;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    filter: brightness(10);
    margin-bottom: 3%;
    margin-top: 2%;
}

.bodyHeadingInner {
    width: 80%;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';


}
.bodyHeadingInner h1{
    font-size: 4rem;
}

.containAndSignIn {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 2rem 1rem 0 1rem;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_Contain_upper_main_3Div {
    display: flex;
    width: 95%;
    justify-content: end;
    position: inherit;
    bottom: 30%;
    margin-top: -15%;
}
.containAndSignIn_Contain_upper_Main{
    width: 55%;
}


.containAndSignIn_Contain_upper {
    /* padding: 1rem; */
    display: flex;
    flex-direction: column;
    width: 55%;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    position: relative;

}
.containAndSignIn_Contain {
    /* padding: 1rem; */
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_Contain_down {
    /* position: relative; */
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_Contain_upper_main_1 {
    width: 70%;
    border: 1px solid black;
    background-color: #DDEFE3;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_Contain_down_main_1 {
    /* margin-top: -25%; */
    width: 70%;
    border: 1px solid black;
    border-right: none;
    background-color: #DDEFE3;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_Contain_upper_1 {
    width: 100%;
    display: flex;
    justify-content: space-around;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_Contain_down_1 {
    /* margin-top: -30%; */
    width: 100%;
    display: flex;
    justify-content: space-around;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

/* .containAndSignIn_Contain_upper_main_1 {
    height: 253px;
    width: 583px;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

} */

.containAndSignIn_Contain_upper_1_1 {
    flex-direction: column;
    border-right: 3px solid black;
    width: 152px;
    height: 72px;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    padding: 0.5rem;


}

.containAndSignIn_Contain_down_1_1 {
    flex-direction: column;
    border-right: 3px solid black;
    width: 152px;
    height: 72px;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';


}

.containAndSignIn_Contain_upper_1_1 h4 {
    font-size: 27px;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_Contain_down_1_1 h4 {
    font-size: 27px;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_Contain_upper_1_1 h6 {
    font-size: 16px;
    line-height: 23px;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
}

.containAndSignIn_Contain_upper_main_photo {
    position: relative;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_Contain_upper_main_photo img {
    width: 75%;
    /* height: 179px; */
    position: inherit;
    z-index: 2;

    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_Contain_upper_main_2 {
    border: 1px solid black;
    height: 356px;
    width: 186px;
    width: 28%;
    height: 19rem;
    left: 70%;
    top: -2%;
    display: flex;
    justify-content: center;
    position: absolute;
    font-weight: bold;
    text-align: right;
    font-size: 30px;
    background-color: #DDEFE3;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_Contain_upper_main_2 button {
    position: relative;
    width: 163px;
    width: 80%;
    height: 46px;
    top: 5%;
    border-radius: 5px;
    background-color: #FFEE58;
    border: 1px solid black;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_Contain_upper_main_3 {
    border-left: 1px solid;
    border-bottom: 1px solid;
    width: 49%;
    z-index: 1;
    height: auto;
    display: flex;
    justify-content: flex-end;
    background-color: #DDEFE3;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_Contain_upper_main_3Inner {
    display: flex;
    justify-content: end;
}

.containAndSignIn_Contain_upper_main_3Inner img {
    width: 75%;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_Contain_down_main_2 {
    border: 1px solid black;
    left: 70%;
    position: absolute;
    width: 28%;
    z-index: 1;
    border-left: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* margin-left: 70%;
    position: absolute; */
    top: 0%;
    font-weight: bold;
    text-align: right;
    font-size: 30px;
    background-color: #DDEFE3;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_Contain_down_main_2 button {
    width: 80%;
    height: 2rem;
    height: 3rem;
    margin-top: 5%;
    margin-bottom: 2%;
    border-radius: 5px;
    background-color: #FFEE58;
    border: 1px solid black;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
}

.containAndSignIn_Contain_down_main_2 img {
    width: -webkit-fill-available;
    height: 84.9%;
}

.containAndSignIn_Contain_down_main_3Div {
    display: flex;
    width: 70%;
    justify-content: end;
    position: inherit;
    bottom: 30%;
}

/* new styles */

.containAndSignIn_Contain_down_main_3 {
    border-left: 1px solid;
    border-bottom: 1px solid;
    width: 92%;
    z-index: 1;
    height: auto;
    display: flex;
    justify-content: flex-end;
    background-color: #DDEFE3;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
}

.containAndSignIn_Contain_down_main_3Inner {
    display: flex;
    justify-content: end;
    width: 100%;
}

.containAndSignIn_Contain_down_main_3Inner img {
    width: 94%;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
}

/* new styles end */




.containAndSignIn_SignIn {
    border: 1px solid;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    margin-top: -1%;
    position: inherit;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    height: fit-content;

}

.containAndSignIn_SignIn_Inner_Main {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.containAndSignIn_SignIn_Header {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    filter: brightness(1);

}

.containAndSignIn_SignIn_Header img {
    width: 97px;
    height: 97px;
}


.containAndSignIn_SignIn_Header h1 {
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_SignIn_Header h5 {
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_SignIn_SignIn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    filter: brightness(1);

}

.userName_mobile_fund {
    display: flex;
    width: 100%;
    height: 40px;
    border-bottom: 3px solid black;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.userName_icon {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}


.userName_label {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    font-weight: 400;
    border: none;
    text-align: center;
    background-color: transparent;
    font-size: 1.2rem;
}

.userName_label:focus {
    outline: none;
}


.containAndSignIn_SignIn_SignIn_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_SignIn_SignIn_button_para {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.btn_whatssapp {
    font-size: 20px;
    background-color: #FFEE58;
    height: 40px;
    width: 70%;
    margin-bottom: 5%;
    border-radius: 5px;
    border: 1px solid;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.btn_getCall {
    font-size: 20px;
    background-color: transparent;
    height: 40px;
    width: 40%;
    margin-bottom: 5%;
    border-radius: 5px;
    border: 1px solid;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.containAndSignIn_Contain_down {
    /* margin-top: 20%; */
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.UpperLeftShade {
    position: absolute;
    margin-top: 35%;
}

.crowdfunding_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    filter: brightness(1);

}

.crowdfunding_1 h1 {
    font-size: 3.7rem;
    padding: 2rem;
}
.crowdfunding_1 h2 {
    padding: 0 0 1rem 0;
}

.crowdfunding_1 h2 {
    text-align: center;
    /* margin-left: 3%; */
    width: 90%;
    font-weight: 100;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.steps_howitwork {
    display: flex;
}

.steps_howitwork h1 {
    text-decoration: underline;
}


.crowdfunding_1_img {
    margin-top: 2%;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.crowdfunding_1_img img {
    width: 100%;
    filter: brightness(1);
}

.monsoonTrust {
    text-align: center;
    margin-top: 1%;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    filter: brightness(1);
    display: flex;
    flex-direction: column;
    align-items: center;

}

.monsoonTrust h1 {
    width: 90%;
    margin-top: 2%;
    font-size: 3.95rem;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';

}

.monsoonTrust h2 {
    width: 90%;
    margin-top: 2%;
    font-weight: 100;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    text-align: justify;
    font-size: 1.8rem;

}

.payoutHeaderMain {
    margin-top: 5%;
}
.detailsDonarLower{
    width: 100%;
    display: flex;
}
.detailsDonarLowerInner{
    width: 55%;
    padding: 1rem;
}
.detailsDonarLowerInnerCard{
    padding: 1rem;
    width: 44%;
    z-index: 1;
}
.detailsDonarLowerInnerCard img{
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    border-radius: 15px;
}

@media (min-width:320px) and (max-width:375px) {
    .bodyHeadingInner h1 {
        font-size: 0.9rem;
    }

    .UpperLeftShade {
        width: 90%;
    }

    .containAndSignIn_Contain_upper_1_1 h4 {
        font-size: 1rem;
    }

    .containAndSignIn_Contain_upper_1_1 h6 {
        font-size: 0.5rem;
    }

    /* 
    .containAndSignIn_Contain_upper_main_photo img {
        width: 100%;
        height: 50%;
    } */

    .containAndSignIn {
        flex-direction: column;
    }

    .containAndSignIn_Contain {
        width: 100% !important;
    }

    .containAndSignIn_Contain_upper_1 {
        width: 90%;
    }

    .containAndSignIn_Contain_upper_main_photo {
        width: 90%;
    }

    /* .containAndSignIn_Contain_upper_main_2 {
        margin-left: 48%;
    } */

    .containAndSignIn_Contain_down_main_1 {
        width: 47%;
    }

    /* .containAndSignIn_Contain_upper_main_3 {
        margin-left: -8%;
        margin-top: 0;
        height: 384px;
    }

    .containAndSignIn_Contain_upper_main_3 img {
        width: 85%;
    } */



    /* .containAndSignIn_Contain_down_main_2 {
        justify-content: start;
        margin-left: 46%;
        height: 100%;
    }

    .containAndSignIn_Contain_down_main_2 button {
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .containAndSignIn_Contain_down_main_2 img {
        width: 100%;
    } */


    .containAndSignIn {
        padding: 1rem;
    }

    .containAndSignIn_Contain_down_1_1 {
        width: 100%;
    }

    .containAndSignIn_Contain_down_1_1 h4 {
        font-size: 1rem;
    }

    .containAndSignIn_SignIn {
        width: 100% !important;
        margin-top: 10%;

    }

    .containAndSignIn_SignIn_Header h1 {
        font-size: 1rem;
    }

    .btn_whatssapp {
        font-size: 0.8rem;
    }

    .btn_getCall {
        font-size: 0.8rem;
    }

    .crowdfunding_1 h1 {
        font-size: 2rem;
    }

    .crowdfunding_1 h2 {
        font-size: 1.5rem;
    }

    .monsoonTrust h1 {
        font-size: 2rem;
    }

    .monsoonTrust h2 {
        font-size: 1rem;
    }

    .headerSimpleStep {
        margin-top: 10%;
    }

    .headerSimpleStep h1 {
        font-size: 2rem;
    }

    .footStep1Right1 {
        display: none;
    }

    .upperDiv h1 {
        font-size: 1.5rem;
    }

    .payoutHeaderMain {
        width: 80% !important;
        height: auto !important;
    }

}

@media (min-width:375px) and (max-width:420px) {
    .bodyHeadingInner h1 {
        font-size: 1rem;
    }

    .UpperLeftShade {
        width: 90%;
    }

    .containAndSignIn_Contain_upper_1_1 h4 {
        font-size: 1rem;
    }

    .containAndSignIn_Contain_upper_1_1 h6 {
        font-size: 0.5rem;
    }

    /* .containAndSignIn_Contain_upper_main_photo img {
        width: 100%;
        height: 50%;
    } */

    .containAndSignIn {
        flex-direction: column;
    }

    .containAndSignIn_Contain {
        width: 100%;
    }

    .containAndSignIn_Contain_upper_1 {
        width: 90%;
    }

    .containAndSignIn_Contain_upper_main_photo {
        width: 90%;
    }

    /* .containAndSignIn_Contain_upper_main_2 {
        margin-left: 48%;
    } */

    .containAndSignIn_Contain_down_main_1 {
        width: 47%;
    }

    /* .containAndSignIn_Contain_upper_main_3 {
        margin-left: 4%;
        margin-top: -73px;
        height: 253px;
    }

    .containAndSignIn_Contain_upper_main_3 img {
        width: 52%;
        height: 72%;
    } */



    /* .containAndSignIn_Contain_down_main_2 {
        justify-content: start;
        margin-left: 46%;
        height: 100%;
    }

    .containAndSignIn_Contain_down_main_2 button {
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .containAndSignIn_Contain_down_main_2 img {
        width: 100%;
    } */

    .containAndSignIn {
        padding: 1rem;
    }

    .containAndSignIn_Contain_down_1_1 {
        width: 100%;
    }

    .containAndSignIn_Contain_down_1_1 h4 {
        font-size: 1rem;
    }

    .containAndSignIn_SignIn {
        width: 100%;
        margin-top: 10%;
    }

    .containAndSignIn_SignIn_Header h1 {
        font-size: 1rem;
    }

    .btn_whatssapp {
        font-size: 0.8rem;
    }

    .btn_getCall {
        font-size: 0.8rem;
    }

    .crowdfunding_1 h1 {
        font-size: 2rem;
    }

    .crowdfunding_1 h2 {
        font-size: 1.5rem;
    }

    .monsoonTrust h1 {
        font-size: 2rem;
    }

    .monsoonTrust h2 {
        font-size: 1rem;
    }

    .headerSimpleStep {
        margin-top: 10%;
    }

    .headerSimpleStep h1 {
        font-size: 2rem;
    }

    .footStep1Right1 {
        display: none;
    }

    .upperDiv h1 {
        font-size: 1.5rem;
    }

    .payoutHeaderMain {
        width: 50% !important;
        height: auto !important;
    }

}

@media (min-width:420px) and (max-width:580px) {
    .bodyHeadingInner h1 {
        font-size: 1.1rem;
    }

    .UpperLeftShade {
        width: 90%;
    }

    .containAndSignIn_Contain_upper_1_1 h4 {
        font-size: 1rem;
    }

    .containAndSignIn_Contain_upper_1_1 h6 {
        font-size: 0.5rem;
    }

    /* .containAndSignIn_Contain_upper_main_photo img {
        width: 100%;
        height: 50%;
    } */

    .containAndSignIn {
        flex-direction: column;
    }

    .containAndSignIn_Contain {
        width: 100%;
    }

    .containAndSignIn_Contain_upper_1 {
        width: 90%;
    }

    .containAndSignIn_Contain_upper_main_photo {
        width: 90%;
    }

    /* .containAndSignIn_Contain_upper_main_2 {
        margin-left: 48%;
    } */

    .containAndSignIn_Contain_down_main_1 {
        width: 47%;
    }

    /* .containAndSignIn_Contain_upper_main_3 {
        margin-left: 9%;
        margin-top: -87px;
        height: 253px;
    }

    .containAndSignIn_Contain_upper_main_3 img {
        width: 52%;
        height: 72%;
    } */


    /* .containAndSignIn_Contain_down_main_2 {
        justify-content: start;
        margin-left: 46%;
        height: 100%;
    }

    .containAndSignIn_Contain_down_main_2 button {
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .containAndSignIn_Contain_down_main_2 img {
        width: 100%;
    } */

    .containAndSignIn {
        padding: 1rem;
    }

    .containAndSignIn_Contain_down_1_1 {
        width: 100%;
    }

    .containAndSignIn_Contain_down_1_1 h4 {
        font-size: 1rem;
    }

    .containAndSignIn_SignIn {
        width: 100%;
        margin-top: 10%;
    }

    .containAndSignIn_SignIn_Header h1 {
        font-size: 1rem;
    }

    .btn_whatssapp {
        font-size: 0.8rem;
    }

    .btn_getCall {
        font-size: 0.8rem;
    }

    .crowdfunding_1 h1 {
        font-size: 2rem;
    }

    .crowdfunding_1 h2 {
        font-size: 1.5rem;
    }

    .monsoonTrust h1 {
        font-size: 2rem;
    }

    .monsoonTrust h2 {
        font-size: 1rem;
    }

    .headerSimpleStep {
        margin-top: 10%;
    }

    .headerSimpleStep h1 {
        font-size: 2rem;
    }

    .footStep1Right1 {
        display: none;
    }

    .upperDiv h1 {
        font-size: 1.5rem;
    }

    .payoutHeaderMain {
        width: 60% !important;
        height: auto !important;
    }

}

@media (min-width:580px) and (max-width:720px) {
    .bodyHeadingInner h1 {
        font-size: 1.5rem;
    }

    .UpperLeftShade {
        width: 90%;
    }

    .containAndSignIn_Contain_upper_1_1 h4 {
        font-size: 1rem;
    }

    .containAndSignIn_Contain_upper_1_1 h6 {
        font-size: 0.5rem;
    }

    /* 
    .containAndSignIn_Contain_upper_main_photo img {
        width: 100%;
        height: 50%;
    } */

    .containAndSignIn {
        flex-direction: column;
    }

    .containAndSignIn_Contain {
        width: 100%;
    }

    .containAndSignIn_Contain_upper_1 {
        width: 90%;
    }

    .containAndSignIn_Contain_upper_main_photo {
        width: 90%;
    }

    /* .containAndSignIn_Contain_upper_main_2 {
        margin-left: 48%;
    } */

    .containAndSignIn_Contain_down_main_1 {
        width: 47%;
    }

    /* .containAndSignIn_Contain_upper_main_3 {
        margin-left: 4%;
        margin-top: -73px;
        height: 253px;
    }

    .containAndSignIn_Contain_upper_main_3 img {
        width: 52%;
        height: 72%;
    } */



    /* .containAndSignIn_Contain_down_main_2 {
        justify-content: start;
        margin-left: 46%;
        height: 100%;
    }

    .containAndSignIn_Contain_down_main_2 button {
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .containAndSignIn_Contain_down_main_2 img {
        width: 100%;
    } */

    .containAndSignIn {
        padding: 1rem;
    }

    .containAndSignIn_Contain_down_1_1 {
        width: 100%;
    }

    .containAndSignIn_Contain_down_1_1 h4 {
        font-size: 1rem;
    }

    .containAndSignIn_SignIn {
        width: 100%;
        margin-top: 10%;
    }

    .containAndSignIn_SignIn_Header h1 {
        font-size: 1rem;
    }

    .btn_whatssapp {
        font-size: 0.8rem;
    }

    .btn_getCall {
        font-size: 0.8rem;
    }

    .crowdfunding_1 h1 {
        font-size: 2rem;
    }

    .crowdfunding_1 h2 {
        font-size: 1.5rem;
    }

    .monsoonTrust h1 {
        font-size: 2rem;
    }

    .monsoonTrust h2 {
        font-size: 1rem;
    }

    .headerSimpleStep {
        margin-top: 10%;
    }

    .headerSimpleStep h1 {
        font-size: 2rem;
    }

    .footStep1Right1 {
        display: none;
    }

    .upperDiv h1 {
        font-size: 1.5rem;
    }

    .payoutHeaderMain {
        width: 50% !important;
        height: auto !important;
    }

}

@media (min-width:320px) {
    .UpperLeftShade {
        width: 90%;
    }
}

@media (min-width:720px) and (max-width:1080px) {
    /* .containAndSignIn_Contain_upper_main_1 {
        width: 55%;
    } */

    .bodyHeadingInner h1 {
        font-size: 1.9rem;
    }

    .containAndSignIn_Contain_upper_1_1 {
        width: 30%;
    }

    .containAndSignIn_Contain_upper_1_1 h4 {
        font-size: 1rem;
    }

    .containAndSignIn_Contain_upper_1_1 h6 {
        font-size: 0.6rem;
    }

    /* .containAndSignIn_Contain_upper_main_3 {
        margin-left: 15%;
    } */


    /* .containAndSignIn_Contain_upper_main_2 {
        margin-left: 55%;
    } */

    /* .containAndSignIn_Contain_upper_main_3 img {
        width: auto;
    } */

    .containAndSignIn_SignIn {
        width: 45%;
    }

    .btn_whatssapp {
        font-size: 0.9rem;
    }

    .btn_getCall {
        font-size: 0.9rem;
    }

    .containAndSignIn {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
    }

    .payoutHeaderMain {
        width: 45% !important;
        margin-top: 2%;
        height: auto !important;
    }


}
@media (min-width:880px) and (max-width:1080px){
    .bodyHeadingInner h1 {
        font-size: 2.2rem;
    }
}

@media (min-width:1080px) and (max-width:1440px) {
    /* .containAndSignIn_Contain_upper_main_3 img {
        width: auto;
    }

    .containAndSignIn_Contain_upper_main_3 {
        margin-left: 15%;
    } */
    .bodyHeadingInner h1 {
        font-size: 2.85rem;
    }


    .containAndSignIn_SignIn {
        width: 45%;
    }

    .btn_whatssapp {
        font-size: 0.9rem;
    }

    .btn_getCall {
        font-size: 0.9rem;
    }

    .containAndSignIn {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
    }
}

@media (min-width:1080px) {
    .payoutHeaderMain {
        width: 35% !important;
        height: auto !important;
    }
    .payoutHeader h3 {
        font-size: 2.6rem !important;
    }
    .payoutPara{
        font-size: 2.3rem !important;
    }
    .headerIcon{
        margin-top: 3%;
    }
}

@media (max-width:720px){
    .containAndSignIn_Contain_upper_main_3Div{
        margin-top: -20%;
        position: none;
    }
    .containAndSignIn_Contain_upper_main_2{
        height: 15rem;
    }
    .containAndSignIn_SignIn_SignIn_button{
        width: 100%;
        text-align: center;
    }
    .crowdfunding_1 h1{
        font-size: 1.8rem;
    }
    .crowdfunding_1 h2{
        font-size: 1rem;
    }
    .detailsDonarLower{
        flex-direction: column;
    }
    .detailsDonarLowerInner{
        width: 100%;
    }
    .detailsDonarLowerInnerCard{
        width: 100%;
    }
    .containAndSignIn_Contain_upper_Main{
        width: 100%;
    }
    .containAndSignIn_Contain_upper{
        width: 100%;
    }
}
@media (min-width:720px){
    .containAndSignIn_Contain_upper_Main{
        width: 55%;
    }
    .containAndSignIn_Contain_upper{
        width: 100%;
    }
}



@media (min-width:1440px) and (max-width:1680px) {
    .bodyHeadingInner h1 {
        font-size: 3rem;
    }

}
@media (min-width:1680px) and (max-width:1980px) {
    .bodyHeadingInner h1 {
        font-size: 4.4rem;
    }
}
@media (min-width:1980px) and (max-width:2240px) {
    .bodyHeadingInner h1 {
        font-size: 5.2rem;
    }
}
@media (min-width:2240px) and (max-width:2420px) {
    .bodyHeadingInner h1 {
        font-size: 5.6rem;
    }
}
@media (min-width:2420px) {
    .bodyHeadingInner h1 {
        font-size: 5.8rem;
    }
}
