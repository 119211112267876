* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    font-weight: 500;
}

.mainDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 0;
    overflow: hidden;
}

.uppperMianDiv {
    width: 50%;
}

.SignUpLeftShaide {
    position: absolute;
    width: 50%;
}

.headerMainDivSignUp {
    /* padding: 1rem 0 0 0; */
}

.logoDiv {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: brightness(100%);
}

.logoDiv img {
    width: 8rem;
}

.signupHeader {
    display: flex;
    flex-direction: column;
    filter: brightness(100%);
}

.signupHeader h1 {
    font-size: 3rem;
    margin-top: 10%;
    text-align: left;
}

.signupHeader h4 {
    font-weight: 400;
}

.signupEmail {
    display: flex;
    align-items: center;
    width: 80%;
    /* border: 1px solid; */
    border-bottom: 3px solid black;
    margin-top: 2%;

}

.signEmailLogo {
    width: 10%;
}

.signEmailLogo {
    width: 10%;
}

.signEmailInput {
    width: 100%;
}

.signEmailInput input {
    width: 100%;
    border: none;
    font-size: 1.3rem;
    text-align: center;
}

.input :focus {
    outline: none;
}

.signUpBtn {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.btnSignup {
    width: 90%;
    display: flex;
    justify-content: center;
    margin-top: 5%;
    /* border: 1px solid; */
}

.btnSignup button {
    width: 70%;
    height: 2.8rem;
    font-size: 1.5rem;
    background-color: #FFEE58;
    border: 1px solid;
    border-radius: 10px;
}

.facebook-googleBtn {
    width: 80%;
    margin-left: 5%;
    margin-top: 5%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.facebook-googleBtn button {
    width: 29%;
    height: 2.8rem;
    font-size: 1.4rem;
    background-color: #FFEE58;
    border: 1px solid;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.lowerMainDiv {
    width: 50%;
}

.lowerMainDiv img {
    width: 100%;
}

.leftCircleShade {
    width: 421px;
    height: 421px;
    flex-shrink: 0;
    border-radius: 421px;
    background: rgba(221, 239, 227, 0.50);
    position: absolute;
    margin-left: -65%;
    margin-top: -10%;
}

.leftdownCircleShade {
    /* width: 221px;
    height: 70px;
    flex-shrink: 0;
    border-radius: 421px;
    background: rgba(221, 239, 227, 0.50);
    position: absolute;
    margin-top: 10%;
    margin-left: 32.5%;

    flex-shrink: 0;
    border-radius: 400rem 400rem 0 0;
    background: rgba(221, 239, 227, 0.50);
    position: absolute;
    transform: translate(-50%, -50%);
    transform: rotate(0deg); */

    /* float: left;
    width: 150px;
    height: 75px;
    flex-shrink: 0;
    margin: 20px;
    shape-outside: ellipse(20% 50%);
    clip-path: ellipse(20% 50%);
    background: lightblue;
    transform: translate(-50%, -60%);
    transform: rotate(90deg); */

    background-color: #DDEFE380;
    width: 300px;
    height: 30px;
    border-radius: 55% / 100%;
    border-bottom-left-radius: 40%;
    border-bottom-right-radius: 40%;
    margin-left: 32.5%;
    margin-top: 23%;
    position: absolute;
}

.details_signUp {
    padding: 1rem 0 0 4rem;
    margin-top: 6%;
}

@media (min-width:320px) and (max-width:375px) {
    .lowerMainDiv {
        display: none;
    }

    .uppperMianDiv {
        width: 100%;
    }

    .leftdownCircleShade {
        display: none;
    }

    .details_signUp {
        padding: 1.5rem;
    }

    .signupEmail {
        width: 100%;
    }

    .signEmailLogo {
        width: 20%;
    }

    .SignUpLeftShaide {
        width: 100%;
    }

    .signupHeader {
        width: 80%;
    }

    .signupHeader h1 {
        font-size: 1.5rem;
        text-align: center;
    }

    .logoDiv img {
        width: 5rem;
    }

    .btnSignup {
        width: 100%;
    }

    .facebook-googleBtn {
        width: 100%;
        margin-left: 0;
    }

    .facebook-googleBtn button {
        font-size: 1rem;
    }

    .leftCircleShade {
        display: none;
    }


}

@media (min-width:375px) and (max-width:420px) {
    .lowerMainDiv {
        display: none;
    }

    .uppperMianDiv {
        width: 100%;
    }

    .leftdownCircleShade {
        display: none;
    }

    .details_signUp {
        padding: 2rem;
    }

    .signupEmail {
        width: 100%;
    }

    .signEmailLogo {
        width: 20%;
    }

    .SignUpLeftShaide {
        width: 100%;
    }

    .signupHeader {
        width: 80%;
    }

    .signupHeader h1 {
        font-size: 1.5rem;
        text-align: center;
    }

    .logoDiv img {
        width: 5rem;
    }

    .btnSignup {
        width: 100%;
    }

    .facebook-googleBtn {
        width: 100%;
        margin-left: 0;
    }

    .facebook-googleBtn button {
        font-size: 1rem;
    }

    .leftCircleShade {
        display: none;
    }


}

@media (min-width:420px) and (max-width:580px) {
    .lowerMainDiv {
        display: none;
    }

    .uppperMianDiv {
        width: 100%;
    }

    .leftdownCircleShade {
        display: none;
    }

    .details_signUp {
        padding: 2rem;
    }

    .signupEmail {
        width: 100%;
    }

    .signEmailLogo {
        width: 20%;
    }

    .SignUpLeftShaide {
        width: 100%;
    }

    .signupHeader {
        width: 80%;
    }

    .signupHeader h1 {
        font-size: 1.5rem;
        text-align: center;
    }

    .logoDiv img {
        width: 5rem;
    }

    .btnSignup {
        width: 100%;
    }

    .facebook-googleBtn {
        width: 100%;
        margin-left: 0;
    }

    .facebook-googleBtn button {
        font-size: 1rem;
    }

    .leftCircleShade {
        display: none;
    }


}

@media (min-width:580px) and (max-width:720px) {
    .lowerMainDiv {
        display: none;
    }

    .uppperMianDiv {
        width: 100%;
    }

    .leftdownCircleShade {
        display: none;
    }

    .details_signUp {
        padding: 2.5rem;
    }

    .signupEmail {
        width: 100%;
    }

    .signEmailLogo {
        width: 20%;
    }

    .SignUpLeftShaide {
        width: 100%;
    }

    .signupHeader {
        width: 80%;
    }

    .signupHeader h1 {
        font-size: 1.5rem;
        text-align: center;
    }

    .logoDiv img {
        width: 5rem;
    }

    .btnSignup {
        width: 100%;
    }

    .facebook-googleBtn {
        width: 100%;
        margin-left: 0;
    }

    .facebook-googleBtn button {
        font-size: 1rem;
    }

    .leftCircleShade {
        display: none;
    }


}

@media (min-width:720px) and (max-width:1080px) {
    .details_signUp {
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .leftCircleShade {
        width: 421px;
        height: 421px;
        flex-shrink: 0;
        border-radius: 421px;
        background: rgba(221, 239, 227, 0.50);
        position: absolute;
        margin-left: -65%;
        margin-top: -29%;
    }

    .leftdownCircleShade {
        margin-top: 20%;
    }
    .mainDiv{
        height: 40rem;
    }
}
@media (min-width:1080px) and (max-width:1440px) {
    .details_signUp {
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .leftCircleShade {
        width: 421px;
        height: 421px;
        flex-shrink: 0;
        border-radius: 421px;
        background: rgba(221, 239, 227, 0.50);
        position: absolute;
        margin-left: -65%;
        margin-top: -29%;
    }

    .leftdownCircleShade {
        display: none;
    }
    .mainDiv{
        height: 40rem;
    }
}
@media (min-width:1440px) {
    .details_signUp {
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .leftCircleShade {
        width: 421px;
        height: 421px;
        flex-shrink: 0;
        border-radius: 421px;
        background: rgba(221, 239, 227, 0.50);
        position: absolute;
        margin-left: -65%;
        margin-top: -29%;
    }

    .leftdownCircleShade {
        display: none;
    }
    .mainDiv{
        height: 51rem;
    }
}

