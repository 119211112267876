* {
    margin: 0;
    padding: 0;
}

.headerMainDiv_whatiscrowd {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.heading_whatiscrowd {
    display: flex;
    justify-content: center;
    padding: 3rem;
    font-size: 1.8rem;
    flex-direction: column;
    align-items: center;
}

.heading_whatiscrowd p {
    width: 80%;
    text-align: center;
    font-weight: 100;
    font-size: 1.3rem;
    color: rgb(145, 146, 146);
    line-height: 1.2;
}

.content_whatiscrowd {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content_whatiscrowd h2 {
    display: flex;
    justify-content: center;
}

.content_whatiscrowd p {
    width: 90%;
    text-align: center;
    font-weight: 100;
    font-size: 1.3rem;
    color: rgb(145, 146, 146);
    line-height: 1.2;

}

.details_whatiscrowdfund {
    width: 100%;
    display: flex;
    justify-content: center;
}

.detailsInner_whatiscrowdfund {
    width: 80%;
    display: flex;
    flex-direction: column;
   padding: 3rem;
}


.details1Row p{
    width: 75%;
}
.detailsHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 1rem;
}
.detailsHeader h2{
    padding-left: 2rem;
}
.detailParagraph{
    position: relative;
    left: 20%;
}
.detailsHeader span{
    padding-bottom: 5px;
    line-height: 0px;
    border-bottom: 2px solid #2b653e;
}