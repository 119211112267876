* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    font-weight: 500;
}

.hambugerMenu {
    margin-right: 2%;
}

.leftCornerShade {
    width: 411px;
    height: 411px;
    flex-shrink: 0;
    border-radius: 411px;
    background: rgba(221, 239, 227, 0.50);
    position: absolute;
    margin-left: -13%;
    margin-top: -13%;
    opacity: 1;
}

.mainToolbarDiv {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;


}

.toolbarLogo {
    /* width: 10%; */
    /* display: flex; */
    /* align-items: center; */
    /* width: 5%; */
    /* height: 60px; */
    /* justify-content: center; */
    filter: brightness(100%)
}

/* .toolbarLogo img {
    width: 42px;
    height: 40px;
} */

.toolbarMenu {
    width: 55%;
    display: flex;
    justify-content: space-between;

}

.toolbarNenu1 {
    /* border-bottom: 1px solid black; */
    text-decoration: none;
    color: black;
}

.howitwork {
    text-decoration: none;
    color: black;
}

.dropdownMenuSub {
    display: flex;
    justify-content: space-around;
    width: 20%;

}

.dropbtn {
    background-color: transparent;
    color: white;
    border: none;
    color: black;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    /* margin-top: 2%; */
    border-radius: 5px;
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: rgb(98, 108, 144);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    border-radius: 5px;
    border-bottom: 1px solid #ddd;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: transparent;
}

.menuDivder {
    align-items: center;
}

.toolbarMenuSearch {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 20%;
    /* filter: brightness(10); */

}

.mobileBarMenuSearchItem__ToolBar {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 35%;
}

.searchItem {
    position: absolute;
    top: 2.5rem;
    width: 15%;
    z-index: 1;
}

.searchItemInnerDiv {
    border-radius: 10px;
    width: 100%;
    background-color: #f1f1f1;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 0.5rem 3rem 1rem 3rem;
    padding: 1rem;
    font-size: 1.2rem;
}

.searchNotFound_ToolBar {
    border-radius: 10px;
    width: 100%;
    background-color: #f1f1f1;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 0.5rem 3rem 1rem 3rem;
    padding: 1rem;
    font-size: 1.2rem;
}

.searchItem h6 {
    text-align: center;
    font-size: 1rem;
}

.searchItemInnerMain_Toolbar {
    /* padding: 0.5rem; */
}

.searchItemInnerMain_Toolbar h5 {
    text-decoration: none;
    color: black;
    font-weight: 500;
}

.searchItemInnerMain_Toolbar a {
    text-decoration: none;
    color: black;
}

.searchItemAtag {
    color: rgb(98, 108, 144);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    border-radius: 5px;
    border-bottom: 1px solid #ddd;
}

.searchItemAtag:hover {
    background-color: #ddd;
}

.toolbarMenuSearchIcon {
    display: flex;
    width: 100%;
    align-items: center;
    border: 1px solid;
    height: 2rem;
    /* width: 18rem; */
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    padding: 0.5rem;
}

.searchIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchInput {
    border: none;
    text-align: inherit;
    font-size: .9rem;
}

.searchInput:focus {
    outline: none;
}

.toolbarMenuSearchText {
    font-size: 1.9em;

}

.toolbarButton {
    width: 25%;
    display: flex;
    /* justify-content: space-between; */
    padding-right: 2rem;

}

.toolbarButton1 {
    width: 50%;
    border: 1px solid;
    border-radius: 7px;
    height: 35px;
    background-color: #FFEE58;
    filter: brightness(1);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;

}

.toolbarButton2 {
    width: 25%;
    border-radius: 7px;
    height: 35px;
    margin-left: 2rem;
    background-color: transparent;
    border: 1px solid;
    filter: brightness(1);
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;

}


@media (min-width:780px) and (max-width:1080px) {
    .mainToolbarDiv {
        width: 100%;
    }

    .toolbarNenu1 {
        filter: brightness(1);
    }

    .toolbarMenu {
        width: 65%;
        font-size: 0.88rem;
    }

    .dropbtn {
        font-size: 0.8rem;
    }

    .toolbarButton {
        width: 25%;
        justify-content: space-around;
        padding: 0;
    }

    .searchInput {
        width: 100%;
    }

    .toolbarButton1 {
        width: 65%;
        justify-content: space-around;
    }

    .toolbarButton2 {
        margin-left: 0%;
    }

    .dropdownMenuSub {
        width: 25%;
        justify-content: center;
    }

    .searchItem {
        position: fixed;
        top: 10%;
        width: auto;
        margin-top: 0;
    }

    .searchItem h6 {
        font-size: 0.6rem;
        margin-top: -20%;
    }

    .searchItemInnerDiv {
        padding: 0.5rem 1rem 1rem 1rem;
    }

    .searchItemInnerMain_Toolbar h5 {
        font-size: 0.8rem;
    }

    .searchItem h6 {
        margin-top: 0 !important;
    }
.mobileToolBarMenu_searchInput{
    font-size: 0.7rem;
}


}

@media (min-width:1080px) and (max-width:1440px) {
    .mainToolbarDiv {
        width: 100%;
    }

    .toolbarNenu1 {
        filter: brightness(1);
    }

    .toolbarMenu {
        width: 65%;
    }

    .toolbarButton {
        width: 25%;
        justify-content: space-around;
        padding: 0;
    }

    .searchInput {
        width: 80%;
    }

    .toolbarButton1 {
        width: 65%;
        justify-content: space-around;
    }

    .toolbarButton2 {
        margin-left: 0%;
    }
}

@media (min-width:780px) {

    .hambugerMenu {
        display: none;
    }

    .mobileBarMenuSearchItem__ToolBar {
        display: none !important;
        border: 1px solid red;
    }
   
}

@media (max-width:780px) {
    .toolbarMenu {
        display: none;
    }

    .leftCornerShade {
        display: none;
    }
    .mobileToolBarMenu {
        display: flex;
        flex-direction: column;
        width: 50% !important;
        height: 20rem;
        margin-left: 50%;
        background-color: #f9f9f9;
        box-shadow: 0px 4px 28px 15px rgba(138, 225, 167, 0.5);
        z-index: 10;
        margin-top: 50%;
        border-radius: 15px;

    }

    .mobileToolBarMenuMian {
        padding: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
    }

    .mobileToolBarMenu_menuDivder {
        display: none;
    }

    .mobileToolBarMenu_toolbarMenuSearch {
        display: none;
    }

    .mobileToolBarMenu_toolbarNenu1 {
        font-size: 1.5rem;
        padding: 0.5rem;
    }

    .mobileToolBarMenu_dropbtn {
        font-size: 1.5rem;
        padding: 0.5rem;
    }

    .mobileToolBarMenu_howitwork {
        font-size: 1.5rem;
        padding: 0.5rem;
    }

    .mobileToolBarMenu_toolbarButton {
        display: flex;
        width: 100% !important;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 8rem;
    }

    .mobileToolBarMenu_toolbarButton1 {
        width: 75%;
    }

    .mobileToolBarMenu_toolbarButton2 {
        width: 60%;
    }
    .mobileToolBarMenu_toolbarNenu1{
        width: 100%;
    }
    .mobileToolBarMenu_howitwork{
        width: 100%;
    }

}

@media (min-width:320px) and (max-width:375px) {
    .mobileToolBarMenu {
        margin-top: 100%;
        width: 100% !important;
        margin-left: 0 !important;
    }

    .mobileToolBarMenu_dropbtn {
        font-size: 1.5rem;
        padding: 0.5rem;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .mobileToolBarMenuMian {
        width: 100% !important;
        align-items: flex-start;
    }

    .dropdownMenuSub {
        width: 100%;
        justify-content: flex-start;
    }

    .mobileToolBarMenu_toolbarButton {
        font-size: 0.8rem !important;
        width: 100% !important;
        padding: 0;
    }

    .toolbarButton2 {
        margin-left: 0 !important;
    }

    .mobileToolBarMenu_toolbarNenu1 {
        font-size: 1.4rem;
    }

    .mobileToolBarMenu_dropbtn {
        font-size: 1.4rem;
    }

    .mobileToolBarMenu_howitwork {
        font-size: 1.4rem;
    }

    .mobileBarMenuSearchItem__ToolBar {
        width: 35%;
    }

    .mobileToolBarMenu_searchInput {
        width: 100%;
    }

    .searchItem {
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    .searchItemInnerMain_Toolbar h5 {
        font-size: 0.8rem;
    }
}

@media (min-width:375px) and (max-width:420px) {
    .mobileToolBarMenu {
        margin-top: 100%;
        width: 100% !important;
        margin-left: 0 !important;
    }

    .mobileToolBarMenu_dropbtn {
        font-size: 1.5rem;
        padding: 0.5rem;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .mobileToolBarMenuMian {
        width: 100% !important;
        align-items: flex-start;
    }

    .dropdownMenuSub {
        width: 100%;
        justify-content: flex-start;
    }

    .mobileToolBarMenu_toolbarButton {
        width: 100% !important;
        padding: 0;
    }

    .toolbarButton2 {
        margin-left: 0 !important;
    }

    .mobileBarMenuSearchItem__ToolBar {
        width: 45%;
    }

    .mobileToolBarMenu_searchInput {
        width: 100%;
    }

    .searchItem {
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    .searchItemInnerMain_Toolbar h5 {
        font-size: 1rem;
    }
}

@media (min-width:420px) and (max-width:475px) {
    .mobileToolBarMenu {
        margin-top: 70%;
        width: 100% !important;
        margin-left: 0 !important;
    }

    .mobileToolBarMenu_dropbtn {
        font-size: 1.5rem;
        padding: 0.5rem;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .mobileToolBarMenuMian {
        width: 100% !important;
        align-items: flex-start;
    }

    .dropdownMenuSub {
        width: 100%;
        justify-content: flex-start;
    }

    .mobileToolBarMenu_toolbarButton {
        width: 100% !important;
        padding: 0;
    }

    .toolbarButton2 {
        margin-left: 0 !important;
    }

    .searchItem {
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    .searchItemInnerMain_Toolbar h5 {
        font-size: 1rem;
    }
}

@media (min-width:475px) and (max-width:580px) {
    .mobileToolBarMenu {
        margin-top: 60%;
        width: 100% !important;
        margin-left: 0 !important;
    }

    .mobileToolBarMenu_dropbtn {
        font-size: 1.5rem;
        padding: 0.5rem;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .mobileToolBarMenuMian {
        width: 100% !important;
    }

    .dropdownMenuSub {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .mobileToolBarMenu_toolbarButton {
        width: 100% !important;
        padding: 0;
    }

    .toolbarButton2 {
        margin-left: 0 !important;
    }

    .searchItem {
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    .searchItemInnerMain_Toolbar h5 {
        font-size: 1rem;
    }
}

@media (min-width:580px) and (max-width:780px) {
    .mobileToolBarMenu {
        margin-top: 60%;
        width: 100% !important;
        margin-left: 0 !important;
    }

    .mobileToolBarMenu_dropbtn {
        font-size: 1.5rem;
        padding: 0.5rem;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .mobileToolBarMenuMian {
        width: 100% !important;
    }

    .dropdownMenuSub {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .mobileToolBarMenu_toolbarButton {
        width: 100% !important;
        padding: 0;
    }

    .toolbarButton2 {
        margin-left: 0 !important;
    }

    .mobileToolBarMenu_toolbarButton1 {
        width: 40%;
    }

    .mobileToolBarMenu_toolbarButton2 {
        width: 25%;
    }

    .searchItem {
        width: 100%;
        position: absolute;
        z-index: 1;
    }

    .searchItemInnerMain_Toolbar h5 {
        font-size: 1rem;
    }

}

@media (min-width:1440px) {
    .searchInput {
        width: 90%;
    }
    .toolbarButton1 {
        width: 65%;
        justify-content: space-around;
    }
}

@media (min-width:1080px) {
    .searchItem {
        top: 10% !important;
    }
}