* {
    margin: 0;
    padding: 0;
}

.mainDiv_ActivitiesHumanitarian {
    width: 100%;
    padding-top: 5rem;
}

.inner1ManiDiv_ActivitiesHumanitarian {
    width: 100%;
    display: flex;
    justify-content: center;
}

.InnerHeaderDiv_ActivitiesHumanitarian {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.InnerHeaderDiv_ActivitiesHumanitarian h1{
    font-weight: 600;
    text-transform: capitalize;
}

.InnerHeaderDiv_ActivitiesHumanitarian img {
    padding-bottom: 1rem;
}

.underLine_ActivitiesHumanitarian {
    height: 3px;
    background: #FFEE58;
    margin: 0.5rem;

}

.inner2MainDiv_ActivitiesHumanitarian {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 4rem;
    flex-direction: column;
    align-items: center;
}

.innerInnerUpperDiv_ActivitiesHumanitarian {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.innerUpperContentDiv_ActivitiesHumanitarian {
    padding-right: 1rem;
    width: 50%;
}
.innerDownContentDiv_ActivitiesHumanitarian {
    padding-left: 1rem;
    width: 50%;
}

.innerDownContentDiv_ActivitiesHumanitarian {
    padding-right: 0;
    padding-left: 1rem;
}

.innerUpperContentDiv_ActivitiesHumanitarian h3 {
    font-weight: 700;
    text-transform: capitalize;
}

.innerUpperContentDiv_ActivitiesHumanitarian p {
    line-height: 1.8;
}

.innerDownImg_ActivitiesHumanitarian {
    width: 50%;
}
.lowwerMainDiv_ActivitiesHumanitarian{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10rem;
}
.LowerinnerMainDiv_ActivitiesHumanitarian{
    width: 70%;
    background-image: url("../images/huanitarianBGImg.png");
    background-size: cover;
    display: flex;
    justify-content: space-around;
    text-align: center;
    height: max-content;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
}
.LowerinnerDiv_ActivitiesHumanitarian{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.LowerinnerMainDiv_ActivitiesHumanitarian h1{
    width: 68%;
    text-transform: capitalize;
    color: #ffffff;
}
.donateBtn_ActivitiesHumanitarian button{
    border-radius: 10px;
    background-color: #FFEE58;
    border: none;
}


@media (max-width:720px) {
    .innerInnerUpperDiv_ActivitiesHumanitarian {
        flex-direction: column;
    }
    .innerInner2UpperDiv_ActivitiesHumanitarian{
        flex-direction: column-reverse;
    }
    .innerUpperContentDiv_ActivitiesHumanitarian{
        width: 100%;
        padding-left: 0;
    }
    .innerUpperContentDiv_ActivitiesHumanitarian h3{
        /* text-align: center; */
    }
    .innerDownImg_ActivitiesHumanitarian{
        width: 100%;
    }
    .innerInner2UpperDiv_ActivitiesHumanitarian{
        padding-top: 4rem;
    }
    .innerDownImg_ActivitiesHumanitarian img{
        padding-top: 1rem;
    }
}