* {
    margin: 0;
    padding: 0;
}

.mainPAge_Tuberculosis {
    width: 100%;
}

.headerMAinDIv_Tuberculosis {
    background-image: url("../images/TbBG.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    padding: 2rem 0;
    margin-top: 4rem;
    position: relative;
    z-index: 1;
}
.headingDiv_Tuberculosis {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem;
}

.headingDiv_Tuberculosis h1 {
    color: #fff;
    font-weight: 600;
}



.headingDiv_Tuberculosis h3 {
    padding-top: 2rem;
    width: 84%;
    text-align: center;
    color: #fff;
    text-transform: capitalize;
}

.underLine_Tuberculosis {
    height: 3px;
    background: #FFEE58;
    margin-top: 1rem;
    width: 20%;
    position: relative;
}

.InnerMAinDiv_Tuberculosis {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MAinDiv_Tuberculosis {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 4rem;
}

.MainDiv_Tuberculosis {
    width: 80%;

}

.Header_Tuberculosis {
    text-transform: capitalize;
}

.Header_Tuberculosis h2 {
    font-weight: 600;
}

.underLineHeadingMainDiv_Tuberculosis {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 1rem;
}

.underLineHeading_Tuberculosis {
    height: 2px;
    background-color: #FFEE58;
    width: 5%;
}
.ContentMainDiv_Tuberculosis{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 5rem;
}
.ContentInnerMainDiv_Tuberculosis{
    width: 80%;
    display: flex;
    justify-content: space-between;
}
.contentImgMainDiv_Tuberculosis{
    width: 48%;
}
.contentImgMainDiv_Tuberculosis img{
    width: 100%;
}
.contentDiv_Tuberculosis{
    width: 48%;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: center;
}
.contentDiv_Tuberculosis h1{
    font-weight: 700;
    text-transform: capitalize;
    padding-bottom: 1rem;
}
.contentDiv_Tuberculosis h2{
    font-weight: 100;
    text-transform: capitalize;
}

