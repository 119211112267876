* {
    margin: 0;
    padding: 0;
}

.mainDiv_GalliersDetailsPage {
    width: 100%;
    display: flex;
    justify-content: center;
}

.innerMainDiv_GalliersDetailsPage {
    width: 80%;
    padding-top: 5rem;
}

.header_GalliersDetailsPage {
    width: 100%;
    display: flex;
    justify-content: center;
}

.header_GalliersDetailsPage h2 {
    text-transform: capitalize;
}

.underLine_ActivitiesHealthHealth {
    height: 2px;
    background-color: #ffee58;
    width: 30%;
}

.displayGalleryImg__GalliersDetailsPage {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 2rem 0;
    gap: 20px 0px;
}
.displayGalleryImgInner__GalliersDetailsPage img{
    width: 98%;
}