* {
    margin: 0;
    padding: 0;
}

.mainDiv_AboutUsFooter {
    width: 100%;
    padding-top: 5rem;
}

.inner1ManiDiv_AboutUsFooter {
    width: 100%;
    display: flex;
    justify-content: center;
}

.InnerHeaderDiv_AboutUsFooter {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.InnerHeaderDiv_AboutUsFooter h1 {
    font-weight: 600;
    text-transform: capitalize;
}

.InnerHeaderDiv_AboutUsFooter img {
    padding-bottom: 1rem;
}

.underLine_AboutUsFooter {
    height: 3px;
    background: #FFEE58;
    margin: 0.5rem;

}

.inner2MainDiv_AboutUsFooter {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 4rem;
    flex-direction: column;
    align-items: center;
}

.innerInnerUpperDiv_AboutUsFooter {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.innerUpperContentDiv_AboutUsFooter {
    padding-right: 1rem;
    width: 50%;
}

.innerDownContentDiv_AboutUsFooter {
    padding-left: 1rem;
    width: 50%;
}

.innerDownContentDiv_AboutUsFooter {
    padding-right: 0;
    padding-left: 1rem;
}

.innerUpperContentDiv_AboutUsFooter h3 {
    font-weight: 700;
    text-transform: capitalize;
}

.innerUpperContentDiv_AboutUsFooter p {
    line-height: 1.8;
}

.innerDownImg_AboutUsFooter {
    width: 50%;
}

.lowwerMainDiv_AboutUsFooter {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10rem;
}

.LowerinnerMainDiv_AboutUsFooter {
    width: 70%;
    background-image: url("../images/huanitarianBGImg.png");
    background-size: cover;
    display: flex;
    justify-content: space-around;
    text-align: center;
    height: max-content;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
}

.LowerinnerDiv_AboutUsFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.LowerinnerMainDiv_AboutUsFooter h1 {
    width: 68%;
    text-transform: capitalize;
    color: #ffffff;
}

.donateBtn_AboutUsFooter button {
    border-radius: 10px;
    background-color: #FFEE58;
    border: none;
}


@media (max-width:720px) {
    .innerInnerUpperDiv_AboutUsFooter {
        flex-direction: column;
    }

    .innerInner2UpperDiv_AboutUsFooter {
        flex-direction: column-reverse;
    }

    .innerUpperContentDiv_AboutUsFooter {
        width: 100%;
        padding-left: 0;
    }

    .innerUpperContentDiv_AboutUsFooter h3 {
        /* text-align: center; */
    }

    .innerDownImg_AboutUsFooter {
        width: 100%;
    }

    .innerInner2UpperDiv_AboutUsFooter {
        padding-top: 4rem;
    }

    .innerDownImg_AboutUsFooter img {
        padding-top: 1rem;
    }
}

.servicesProviderMainDiv_AvoutUsFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.servicesProviderLowerMainDiv_AboutUsFooter {
    width: 70%;
}

.servicesProviderLowerUpperMainDIv_AboutUsFooter {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 2rem;
}

.servicesProviderContentMainDiv_AboutUsFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.servicesProviderContentMainDiv_AboutUsFooter h2 {
    font-weight: 600;
    text-transform: capitalize;
}

.servicesProviderLowerLowerMainDIv_AboutUsFooter {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding-top: 3rem;
}

.lowerMainDivStartFundriser_AboutUsFooter {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 5rem;
}

.lowerInnerMainDivStartFundriser_AboutUsFooter {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lowerUpperMainDivStartFundriser_AboutUsFooter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.lowerUpperMainDivStartFundriser_AboutUsFooter h2 {
    /* width: 20%; */
    color: #4D4D4D;
}
.middleUnderLine_AboutUsFooter {
    height: 2px;
    background-color: #4D4D4D;
    width: 35%;
}


.lowerDownMainDivStartFundriser_AboutUsFooter{
    width: 100%;
    padding-top: 1rem;
    display: flex;
    justify-content: center;
}
.lowerDownMainDivStartFundriser_AboutUsFooter button{
    border-radius: 10px;
    background-color: #FFEE58;
    border: none;
}