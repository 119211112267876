* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    font-weight: 500;
}

.upperMainDiv_whyMasoom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mainDiv_whyMasoom {
    width: 90%;
    padding-top: 3rem;

}

.header_whyMasoom {
    text-align: center;
    font-size: 1.8rem;
}

.symbolize_img_whyMasoom {
    width: 5rem;
}

.symbolize1_whyMasoom {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 80%;
    margin-left: 13%;
    font-size: 1.6rem;
}

.symbolize1_whyMasoom h2 {
    font-weight: 200;
}

.victor1_whyMasoom {
    position: absolute;
    margin-left: 20%;
    margin-top: 1%;
    width: 18%;
}

.symbolize2_whyMasoom {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 60%;
    margin-left: 40%;
    margin-top: 8%;
    font-size: 1.6rem;
}

.symbolize2_whyMasoom h2 {
    width: 60%;
    text-align: center;
    font-weight: 200;
}

.victor2_whyMasoom {
    position: absolute;
    margin-left: 55%;
    margin-top: 1%;
    width: 20%;
}

.symbolize3_whyMasoom {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    margin-left: 5%;
    margin-top: 8%;
    font-size: 1.6rem;
}

.symbolize3_whyMasoom h2 {
    width: 60%;
    text-align: center;
    font-weight: 200;
}

.mobileSymbolize2 img {
    width: 160px;
    height: 160px;
}

.prevSvg {
    left: 3%;
    bottom: 50%;
}

.nxtSvg {
    right: 3%;
    bottom: 50%;
}

@media (min-width:320px) and (max-width:375px) {
    .header_whyMasoom {
        font-size: 1rem !important;
        padding: 2rem;
    }

    .symbolize_img_whyMasoom {
        width: 2rem;
    }

    .symbolize1_whyMasoom h2 {
        font-size: 1rem;
    }

    .symbolize2_whyMasoom {
        margin-top: 6%;
    }

    .symbolize3_whyMasoom {
        margin-top: 2%;
    }

    .symbolize3_whyMasoom h2 {
        font-size: 1rem;
    }

    .symbolize2_whyMasoom {
        margin-top: 0;
    }

    .symbolize2_whyMasoom h2 {
        font-size: 1rem;
    }

    .mobileSymbolize2 img {
        width: 90px;
        height: 90px;
    }

}

@media (min-width:375px) and (max-width:420px) {
    .header_whyMasoom {
        font-size: 1rem !important;
        padding: 2rem;
    }

    .symbolize_img_whyMasoom {
        width: 2rem;
    }

    .symbolize1_whyMasoom h2 {
        font-size: 1rem;
    }

    .symbolize2_whyMasoom {
        margin-top: 2%;
    }

    .symbolize3_whyMasoom {
        margin-top: 2%;
    }

    .symbolize3_whyMasoom h2 {
        font-size: 1rem;
    }

    .symbolize2_whyMasoom {
        margin-top: 0;
    }

    .symbolize2_whyMasoom h2 {
        font-size: 1rem;
    }

    .mobileSymbolize2 {
        width: 170px !important;
        height: 100px !important;
    }

    .mobileSymbolize2 img {
        width: 90px;
        height: 90px;
    }

    .mobileSymbolize2 img {
        width: 90px;
        height: 90px;
    }


}

@media (min-width:420px) and (max-width:580px) {
    .header_whyMasoom {
        font-size: 1rem !important;
        padding: 2rem;
    }

    .symbolize_img_whyMasoom {
        width: 2rem;
    }

    .symbolize1_whyMasoom h2 {
        font-size: 1rem;
    }

    .symbolize3_whyMasoom {
        margin-top: 2%;
    }

    .symbolize3_whyMasoom h2 {
        font-size: 1rem;
    }

    .symbolize2_whyMasoom {
        margin-top: 7%;
    }

    .symbolize2_whyMasoom h2 {
        font-size: 1rem;
    }

    .mobileSymbolize2 img {
        width: 100px;
        height: 100px;
    }

    .mobileSymbolize2 {
        width: 183px;
        height: 80px;
    }

}

@media (min-width:480px) and (max-width:580px) {

    .nxtSvg {
        margin-top: -60%;
    }

    .belowSliderImg {
        height: 30%;
    }

    .symbolize3_whyMasoom {
        margin-top: 10%;
    }

    .mobileSymbolize2 img {
        width: 183px;
        height: 80px;
    }

}

@media (min-width:580px) and (max-width:720px) {
    .header_whyMasoom {
        font-size: 1rem !important;
        padding: 2rem;
    }

    .symbolize_img_whyMasoom {
        width: 2rem;
    }

    .symbolize1_whyMasoom h2 {
        font-size: 1rem;
    }

    .symbolize2_whyMasoom {
        margin-top: 2%;
    }

    .symbolize3_whyMasoom {
        margin-top: 2%;
    }

    .symbolize3_whyMasoom h2 {
        font-size: 1rem;
    }

    .symbolize2_whyMasoom {
        margin-top: 8%;
    }

    .symbolize2_whyMasoom h2 {
        font-size: 1rem;
    }

    .symbolize3_whyMasoom {
        margin-top: 10%;
    }

    .mobileSymbolize2 img {
        width: 100px;
        height: 100px;
    }
}

@media (min-width:720px) and (max-width:1080px) {
    .header_whyMasoom {
        font-size: 1.55rem !important;
        padding: 2rem;
    }
}
@media (min-width:1080px) {
    .header_whyMasoom {
        font-size: 2rem !important;
        padding: 2rem;
    }
}