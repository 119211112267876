* {
    margin: 0;
    padding: 0;
}

.medicalSupportMainDiv {
    width: 100%;
}

.medicalSupportImage {
    height: 466px;
    background-image: url("https://image.slidesdocs.com/responsive-images/background/medical-spring-injection-hospital-doctor-cartoon-powerpoint-background_dcb1764b1e__960_540.jpg");
    opacity: 0.8;
}

.medicalSupportImage {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.medicalHeaderDiv {
    width: 50%;
}

.medicalHeaderDiv h2 {
    font-size: 3.4rem;
    font-weight: 700;
    background: #15CFB6;
    background: linear-gradient(to right, #15CFB6 0%, #2127CF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}
.medicalHeaderDiv h4{
    padding-top: 2rem;
    font-size: 1.2rem;
}
.medicalHeaderDiv strong{
    font-weight: 600;
}