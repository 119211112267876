* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    font-weight: 500;
}

.mainDiv_riseFund {
    width: 100%;
    /* padding: 2rem; */
    background-color: transparent;
}

.india_map_left1_riseFund {
    position: absolute;
}

.india_map_left2_riseFund {
    position: absolute;
    margin-top: 30%;
}

.india_map_right1_riseFund {
    position: absolute;
    margin-left: 84%;
}

.india_map_right2_riseFund {
    position: absolute;
    margin-top: 35%;
    margin-left: 81.9%;
}

.header_riseFund {
    text-align: center;
    font-size: 1.8rem;
    padding-top: 3rem;
    filter: brightness(0.5);
}

.containPara_riseFund {
    text-align: start;
    font-size: 1.6rem;
    filter: brightness(1);
    margin: 0 1rem 0 1rem;
}

.containPara_riseFund h2 {
    font-weight: 100;
    text-align: center;
}

.IndiaMap_riseFund {
    margin-top: 5%;
    display: flex;
    justify-content: center;
}
.IndiaMap_riseFund img{
    z-index: 1;
}

.cardmainDiv_riseFund {
    width: 100%;
    padding: 2rem;
}

.cardInnerDiv_riseFund {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 94%;
    margin-top: 5%;
    margin-left: 3%;
    display: grid;
    grid-template-columns: repeat(4 ,1fr);
    gap: 10px 10px;
}

.card1_riseFund {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 15rem;
    margin-left: 0% !important;
    border-radius: 10px;
    background-color: #DDEFE3;
    border: none;
}

.card1_riseFund img {
    width: 6rem;
    height: 6rem;
}

.images_riseFund {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.images_riseFund img {
    width: 92%;
    margin-top: 2%;
    border-radius: 10px;
}

@media (min-width:720px) and (max-width:1080px) {
    .IndiaMapmainDiv_riseFund {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .india_map_right1_riseFund {
        margin-left: 74.3%;
    }

    .india_map_right2_riseFund {
        margin-top: 60%;
        margin-left: 73.9%;
    }

    .india_map_left2_riseFund {
        margin-top: 45%;
    }

    .header_riseFund {
        font-size: 1.75rem;
    }

    .containPara_riseFund {
        font-size: 1.4rem;
    }

    .cardmainDiv_riseFund {
        margin-top: 0%;
    }
}

@media (min-width:1080px) and (max-width:1440px) {
    .IndiaMapmainDiv_riseFund {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .india_map_right1_riseFund {
        margin-left: 74.3%;
    }

    .india_map_right2_riseFund {
        margin-top: 40%;
        margin-left: 73.9%;
    }

    .india_map_left2_riseFund {
        margin-top: 40%;
    }

    .header_riseFund {
        font-size: 1.75rem;
    }

    .containPara_riseFund {
        font-size: 1.4rem;
    }

    .cardmainDiv_riseFund {
        margin-top: 0%;
    }
}

@media (min-width:320px) and (max-width:375px) {
    .india_map_left1_riseFund {
        width: 20%;
    }

    .india_map_left2_riseFund {
        width: 40%;
    }

    .india_map_right1_riseFund {
        width: 40%;
    }

    .india_map_right2_riseFund {
        width: 40%;
        margin-top: 100%;
    }
    .header_riseFund{
        font-size: 1rem;
    }
    .containPara_riseFund{
        font-size: 0.9rem;
    }
    .IndiaMap_riseFund{
        width: 100%;
    }
    .IndiaMap_riseFund img{
        width: 90%;
    }
    .card1_riseFund{
        height: 7rem;
    }
    .card1_riseFund img{
        width: 2rem;
        height: 2rem;
    }
    .card1_riseFund h3{
        font-size: 0.7rem;
    }


}
@media (min-width:375px) and (max-width:420px) {
    .india_map_left1_riseFund {
        width: 20%;
    }

    .india_map_left2_riseFund {
        width: 40%;
    }

    .india_map_right1_riseFund {
        width: 40%;
    }

    .india_map_right2_riseFund {
        width: 40%;
        margin-top: 100%;
    }
    .header_riseFund{
        font-size: 1rem;
    }
    .containPara_riseFund{
        font-size: 0.9rem;
    }
    .IndiaMap_riseFund{
        width: 100%;
    }
    .IndiaMap_riseFund img{
        width: 90%;
    }
    .card1_riseFund{
        height: 7rem;
    }
    .card1_riseFund img{
        width: 3rem;
        height: 3rem;
    }


}
@media (min-width:420px) and (max-width:580px) {
    .india_map_left1_riseFund {
        width: 20%;
    }

    .india_map_left2_riseFund {
        width: 40%;
    }

    .india_map_right1_riseFund {
        width: 40%;
    }

    .india_map_right2_riseFund {
        width: 40%;
        margin-top: 100%;
    }
    .header_riseFund{
        font-size: 1rem;
    }
    .containPara_riseFund{
        font-size: 0.9rem;
    }
    .IndiaMap_riseFund{
        width: 100%;
    }
    .IndiaMap_riseFund img{
        width: 90%;
    }
    .card1_riseFund{
        height: 7rem;
    }
    .card1_riseFund img{
        width: 3rem;
        height: 3rem;
    }


}
@media (min-width:580px) and (max-width:720px) {
    .india_map_left1_riseFund {
        width: 20%;
    }

    .india_map_left2_riseFund {
        width: 20%;
    }

    .india_map_right1_riseFund {
        width: 40%;
    }

    .india_map_right2_riseFund {
        width: 40%;
        margin-top: 90%;
    }
    .header_riseFund{
        font-size: 1rem;
    }
    .containPara_riseFund{
        font-size: 0.9rem;
    }
    .IndiaMap_riseFund{
        width: 100%;
    }
    .IndiaMap_riseFund img{
        width: 90%;
    }
    .card1_riseFund{
        height: 7rem;
    }
    .card1_riseFund img{
        width: 3rem;
        height: 3rem;
    }


}