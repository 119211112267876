* {
    margin: 0;
    padding: 0;
}

.mainDiv_ActivitiesEduction {
    width: 100%;
    padding-top: 5rem;
}

.inner1ManiDiv_ActivitiesEduction {
    width: 100%;
    display: flex;
    justify-content: center;
}

.InnerHeaderDiv_ActivitiesEduction {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.InnerHeaderDiv_ActivitiesEduction h1{
    font-weight: 600;
    text-transform: capitalize;
}

.InnerHeaderDiv_ActivitiesEduction img {
    padding-bottom: 1rem;
}

.underLine_ActivitiesEduction {
    height: 3px;
    background: #FFEE58;
    margin: 0.5rem;

}

.inner2MainDiv_ActivitiesEduction {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 4rem;
    flex-direction: column;
    align-items: center;
}

.innerInnerUpperDiv_ActivitiesEduction {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.innerUpperContentDiv_ActivitiesEduction {
    padding-right: 1rem;
    width: 50%;
}
.innerDownContentDiv_ActivitiesEduction {
    padding-left: 1rem;
    width: 50%;
}

.innerDownContentDiv_ActivitiesEduction {
    padding-right: 0;
    padding-left: 1rem;
}

.innerUpperContentDiv_ActivitiesEduction h3 {
    font-weight: 700;
    text-transform: capitalize;
}

.innerUpperContentDiv_ActivitiesEduction p {
    line-height: 1.8;
}

.innerDownImg_ActivitiesEduction {
    width: 50%;
}
.lowwerMainDiv_ActivitiesEduction{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10rem;
}
.LowerinnerMainDiv_ActivitiesEduction{
    width: 70%;
    background-image: url("../images/huanitarianBGImg.png");
    background-size: cover;
    display: flex;
    justify-content: space-around;
    text-align: center;
    height: max-content;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
}
.LowerinnerMainDiv_ActivitiesEduction h1{
    width: 68%;
    text-transform: capitalize;
    color: #ffffff;
}
.LowerinnerDiv_ActivitiesEduction{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.donateBtn_ActivitiesEduction button{
    border-radius: 10px;
    background-color: #FFEE58;
    border: none;
}


@media (max-width:720px) {
    .innerInnerUpperDiv_ActivitiesEduction {
        flex-direction: column;
    }
    .innerInner2UpperDiv_ActivitiesEduction{
        flex-direction: column-reverse;
    }
    .innerUpperContentDiv_ActivitiesEduction{
        width: 100%;
        padding-left: 0;
    }
    .innerUpperContentDiv_ActivitiesEduction h3{
        /* text-align: center; */
    }
    .innerDownImg_ActivitiesEduction{
        width: 100%;
    }
    .innerInner2UpperDiv_ActivitiesEduction{
        padding-top: 4rem;
    }
    .innerDownImg_ActivitiesEduction img{
        padding-top: 1rem;
    }
}