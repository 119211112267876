* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;

}

.upper1_div {
    display: flex;
    justify-content: space-around;
    padding: 4rem 3rem;
    /* margin-top: 5%; */
}

.upper1_div {
    width: 100%;
}

.upper1_div_1 {
    width: 35%;
    display: flex;
    align-items: center;
}

.upper1_div_2 {
    width: 60%;
}

.upper1_div_2 img {
    width: 100%;
}

.traingle {
    left: 32%;
    top: 41%;
    position: absolute;
}

.upper1_div_1 {
    width: 35%;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
}

.upper1_div h1 {
    /* width: 65%; */
}


.line1 {
    width: 3px;
    height: 80px;
    background: #000;
    position: absolute;
    margin-top: 4%;
}

.line2 {
    width: 218.468px;
    height: 2px;
    background: #000;
    position: absolute;
    margin-top: 4%;
    transform: rotate(-14deg);
}

.line3 {
    width: 3px;
    height: 78px;
    background: #000;
    position: absolute;
    margin-top: 1%;
    margin-left: 14%;

}

.line4 {
    width: 214px;
    height: 3px;
    background: #000;
    position: absolute;
    margin-left: 14%;
    margin-top: 2.3%;
}

.line5 {
    width: 3px;
    height: 78px;
    background: #000;
    position: absolute;
    margin-top: 1%;
    margin-left: 27.9%;
}

.line6 {
    width: 221.684px;
    height: 2px;
    background: #000;
    margin-top: 0.5%;
    margin-left: 59.3%;
    transform: rotate(-16deg);
}

.line7 {
    width: 3px;
    height: 80px;
    background: #000;
    position: absolute;
    margin-top: -3%;
    /* margin-left: 77.1%; */
}

.CurveLine1 {
    position: absolute;
    height: 15rem;
    margin-left: -13.5%;
    margin-top: -2%;
}

.img3 {
    filter: brightness(1.4);
    position: relative;
    z-index: 1;
}

.CurveLine2 {
    position: absolute;
    height: 15rem;
    margin-left: -19%;
    margin-top: 16%;
}

.images_pack {
    width: 50%;
    /* border: 1px solid red; */
}

.images_pack img {
    width: 30%;

}

.lowerDivMain {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -7%;
}

.lowerDiv {
    width: 50%;
    border: 2px solid black;
}

.lowerDiv1 {
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: space-around;
}

.lowerDiv1 h4 {
    margin-top: 10%;
    font-weight: 100;
}

.lowerDivtrust {
    width: 78%;
    /* margin-top: 2%; */
    padding: 1rem;
}

.traingle2 {
    position: absolute;
   left: 68%;
}

.lowerDivBtn {
    border: 1px solid;
    width: 15rem;
    height: 2.5rem;
    display: flex;
    /* margin-left: 5%; */
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;
    text-decoration: none;
    color: black;
    background-color: #FFEE58;
}

.star {
    position: absolute;
    left: 50%;
}

.rightCircleShade {
    width: 411px;
    height: 211px;
    flex-shrink: 0;
    /* border-radius: 411px; */
    border-radius: 411px 411px 0 0;
    background: rgba(221, 239, 227, 0.50);
    position: absolute;
    margin-left: 79.5%;
    margin-top: -15%;
    transform: translate(-50%, -50%);
    transform: rotate(270deg);
    /* top: 50%; */
    /* left: 50%; */
}
.lowerDiv1_1 h2{
    padding-top: 0.5rem;
}

.signUpInStart {
    width: 50%;
    font-family: "Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif;

    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid;
    background-color: #FFEE58;
}

.tranding_Div {
    width: 100%;
    padding: 2em;
    /* border: 1px solid red; */
}

.tranding_Div_upper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.tranding_Div_upper h3 {
    font-weight: 400;
}
.tranding_Div_upper h1 {
    font-size: 4rem;
}

.tranding_Div_lower {
    padding-top: 2rem;
    display: flex;
    justify-content: space-around;

}

/* .tranding_Div_lower img {
    width: 447px;
    height: 508px;
    flex-shrink: 0;
} */
.sliderImgMain {
    position: relative;
}

.sliderimg {
    width: 100%;
    height: 500px;
    object-fit: cover
}

.prevSvg {
    width: 50px;
    height: 50px;
    position: absolute;
    cursor: pointer;
    left: 1%;
    bottom: 50%;
}

.nxtSvg {
    cursor: pointer;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 3%;
    bottom: 50%;
    /* margin-right: -90%; */
    /* margin-top: -30%; */
}

/* .sliderImgMainDiv{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
} */
.belowSliderImg {
    padding: 1.5rem;
    width: 100%;
    height: 35%;
    /* border: 1px solid red; */
    position: absolute;
    font-size: 1.3rem;
    /* right: 50%; */
    /* left: 50%; */
    /* bottom: 1%; */
    top: 60%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}

.belowSliderImg button {
    /* margin-top: 15%; */
    width: 117px;
    height: 28px;
    border-radius: 5px;
    border: 1px solid;
    background-color: #FFEE58;
}

.Categories_belowSliderImg {
    display: flex;
    padding: 0;
    border-radius: 10px;
}

.catgriesSliderDataleft {
    width: 80%;
    padding: 2rem;
}

.catgriesSliderDataleftUpper {
    color: #fff;
}

.catgriesSliderDataRight {
    text-align: center;
    width: 20%;
    color: white;
    background-color: #ae893d;
    padding: 2rem;
    border-radius: 10px;
}

.categriesSliderRightPanelupper {
    color: #fff;
    font-size: 3rem;
}

.categriesSliderRightPanellower {
    font-size: 2.2rem;

}


@media (min-width:720px) and (max-width:1080px) {
    .upper1_div_1 {
        font-size: 1.4rem;
        filter: brightness(0.5);
        text-align: center;
    }
    .lowerDivBtnMain{
        display: flex;
        justify-content: start;
        padding: 1rem;
    }
    .lowerDivBtn{
        width: 50%;
        height: 3.5rem;
        font-size: 1.15rem;
    }

    .upper1_div_1 {
        width: 50%;
    }

    .upper1_div_2 {
        width: 50%;
    }
    .lowerDiv{
        width: 80%;
    }
    .lowerDivtrust{
        width: 80%;
    }
    .lowerDivtrust h1{
        font-weight: 400;
        font-size: 2rem;
    }

    .rightCircleShade {
        margin-left: 66.7%;
    }

    .tranding_Div_lowerMain {
        display: flex;
        flex-direction: column;
        margin-left: 2%;
    }

    .sliderimg {
        width: 100%;
    }

    .tranding_Div {
        margin-top: 10%;
    }

    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
        margin-top: 0;
    }

    .alice-carousel__prev-btn img {
        margin-top: -40%;
    }

    .alice-carousel__next-btn img {
        margin-top: -40%;
        margin-left: 43%;

    }

    .belowSliderImg {
        width: 100%;
    }

    .line1 {
        width: 3px;
        height: 10%;
        background: #000;
        position: absolute;
        margin-top: 4%;
    }

    .line2 {
        width: 13.8%;
        height: 2px;
        background: #000;
        position: absolute;
        margin-top: 4%;
        transform: rotate(-14deg);
    }

    .line3 {
        width: 3px;
        height: 11%;
        background: #000;
        position: absolute;
        margin-top: 1%;
        margin-left: 13%;
    }

    .line4 {
        width: 14%;
        height: 3px;
        background: #000;
        position: absolute;
        margin-left: 13%;
        margin-top: 2.3%;
    }

    .line5 {
        width: 3px;
        height: 78px;
        background: #000;
        position: absolute;
        margin-top: 1%;
        margin-left: 27%;
    }

    .line6 {
        width: 31%;
        height: 2px;
        background: #000;
        margin-top: 1.5%;
        margin-left: 59%;
        transform: rotate(-16deg);
    }

    .catgriesSliderDataleft {
        padding: 1rem;
        width: 75%;
    }

    .Categories_belowSliderImg button {
        width: 5rem;
    }

    .catgriesSliderDataRight {
        width: 25%;
    }

    .categriesSliderRightPanelupper {
        font-size: 1.6rem;
    }

    .categriesSliderRightPanellower {
        font-size: 1.2rem;
    }

}

@media (min-width:720px) and (max-width:850px) {
    .line7 {
        width: 2px;
        height: 10%;
        background: #000;
        position: absolute;
        margin-top: -5%;
        margin-left: 39.6%;
    }

    .CurveLine1 {
        height: 9rem;
    }

    .CurveLine2 {
        height: 9rem;
    }
}

@media (min-width:850px) and (max-width:980px) {
    .line7 {
        width: 2px;
        margin-left: 40%;
        margin-top: -5%;
    }

    .CurveLine1 {
        height: 10rem;
    }

    .CurveLine2 {
        height: 10rem;
    }
}

@media (min-width:980px) and (max-width:1080px) {
    .line7 {
        margin-top: -4%;
        margin-left: 40.4%;
        width: 2px;
    }

    .CurveLine1 {
        height: 11rem;
    }

    .CurveLine2 {
        height: 11rem;
    }
}

@media (min-width:1080px) and (max-width:1440px) {
    .upper1_div_1 {
        display: flex;
        justify-content: center;
        filter: brightness(0.5);
        font-size: 1.79rem;
        text-align: center;
        width: 50%;
    }

    .upper1_div_2{
        width: 50%;
    }
    .lowerDiv{
        width: 65%;
    }

    .rightCircleShade {
        margin-left: 66.7%;
    }

    .tranding_Div_lowerMain {
        display: flex;
        flex-direction: column;
        margin-left: 2%;
    }

    .sliderimg {
        width: 100%;
    }

    .tranding_Div {
        margin-top: 10%;
    }

    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
        margin-top: 0;
    }

    .alice-carousel__prev-btn img {
        margin-top: -30%;
    }

    .alice-carousel__next-btn img {
        margin-top: -30%;
        margin-left: 43%;

    }

    .belowSliderImg {
        width: 100%;
    }

    .line1 {
        width: 3px;
        height: 10%;
        background: #000;
        position: absolute;
        margin-top: 4%;
    }

    .line2 {
        width: 13.8%;
        height: 2px;
        background: #000;
        position: absolute;
        margin-top: 4%;
        transform: rotate(-14deg);
    }

    .line3 {
        width: 3px;
        height: 11%;
        background: #000;
        position: absolute;
        margin-top: 1%;
        margin-left: 13%;
    }

    .line4 {
        width: 14%;
        height: 3px;
        background: #000;
        position: absolute;
        margin-left: 13%;
        margin-top: 2.3%;
    }

    .line5 {
        width: 3px;
        height: 78px;
        background: #000;
        position: absolute;
        margin-top: 1%;
        margin-left: 27%;
    }

    .line6 {
        width: 31%;
        height: 2px;
        background: #000;
        margin-top: 1.5%;
        margin-left: 59%;
        transform: rotate(-16deg);
    }

}

@media (min-width:1080px) and (max-width:1165px) {
    .line7 {
        width: 2px;
        height: 10%;
        background: #000;
        position: absolute;
        margin-top: -5%;
        margin-left: 41%;
    }

    .line6 {
        width: 32%;
        margin-left: 58%;
    }

    .CurveLine1 {
        height: 9rem;
    }

    .CurveLine2 {
        height: 9rem;
    }
    .lowerDiv{
        width: 65%;
    }
    .lowerDivtrust h1{
        font-size: 1.62rem;
        font-weight: 300;
    }
}

@media (min-width:1160px) and (max-width:1280px) {
    .line7 {
        width: 2px;
        margin-left: 41.2%;
        margin-top: -5%;
    }

    .line6 {
        width: 32.2%;
        margin-left: 58%;
    }

    .line5 {
        width: 3px;
        height: 78px;
        background: #000;
        position: absolute;
        margin-top: 1%;
        margin-left: 27.4%;
    }

    .line3 {
        width: 3px;
        height: 11%;
        background: #000;
        position: absolute;
        margin-top: 1%;
        margin-left: 13.2%;
    }

    .line4 {
        width: 14.5%;
        height: 3px;
        background: #000;
        position: absolute;
        margin-left: 13%;
        margin-top: 2.5%;
    }

    .CurveLine1 {
        height: 10rem;
    }

    .CurveLine2 {
        height: 10rem;
    }
}

@media (min-width:1280px) and (max-width:1360px) {
    .line7 {
        margin-top: -4%;
        margin-left: 41.4%;
        width: 2px;
    }

    .line6 {
        width: 32.4%;
        margin-left: 57.8%;
    }

    .line5 {
        width: 3px;
        height: 78px;
        background: #000;
        position: absolute;
        margin-top: 1%;
        margin-left: 27.6%;
    }

    .line3 {
        width: 3px;
        height: 11%;
        background: #000;
        position: absolute;
        margin-top: 1%;
        margin-left: 13.4%;
    }

    .line4 {
        width: 14.7%;
        height: 3px;
        background: #000;
        position: absolute;
        margin-left: 13%;
        margin-top: 2.5%;
    }

    .CurveLine1 {
        height: 11rem;
    }

    .CurveLine2 {
        height: 11rem;
    }
}

@media (min-width:1360px) and (max-width:1440px) {
    .line7 {
        margin-top: -4%;
        margin-left: 41.8%;
        width: 2px;
    }

    .line6 {
        width: 33%;
        margin-left: 57.4%;
    }

    .line5 {
        width: 3px;
        height: 78px;
        background: #000;
        position: absolute;
        margin-top: 1%;
        margin-left: 27.9%;
    }

    .line3 {
        width: 3px;
        height: 11%;
        background: #000;
        position: absolute;
        margin-top: 1%;
        margin-left: 13.6%;
    }

    .line4 {
        width: 14.9%;
        height: 3px;
        background: #000;
        position: absolute;
        margin-left: 13%;
        margin-top: 2.7%;
    }


    .CurveLine1 {
        height: 11rem;
    }

    .CurveLine2 {
        height: 11rem;
    }
}


@media (min-width:320px) and (max-width:375px) {
    .upper1_div {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .upper1_div_1 {
        width: 100%;
    }

    .upper1_div_1 h1 {
        font-size: 1.8rem;

    }

    .upper1_div_2 {
        width: 100%;
    }

    .upper1_div_1 {
        width: 100%;
        text-align: center;
        font-size: 0.7rem;
        margin-bottom: 5%;
        margin-top: 5%;

    }

    .images_pack {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .upper1_div h1 {
        width: 100%;
        filter: brightness(1);
    }

    .line1 {
        margin-left: -90%;
        margin-top: 1%;
    }

    .line2 {
        width: 31%;
        margin-left: -60%;
    }

    .line3 {
        margin-left: -30%;
        margin-top: -6%;
    }

    .line4 {
        width: 31%;
        margin-left: 0%;
        margin-top: 0%;
    }

    .line5 {
        margin-left: 30%;
        margin-top: -7%;
    }

    .lowerDivMain {
        width: 100%;
        margin-top: 5%;
    }

    .lowerDiv {
        width: 100%;
    }

    .lowerDiv1_1 {
        font-size: 0.6rem;
    }

    .lowerDiv1_1 h1 {
        font-size: 1.3rem;
    }

    .lowerDivtrust {
        width: 100%;
    }

    .lowerDivtrust h1 {
        font-size: 1.5rem;
        font-weight: 300 !important;
    }

    .lowerDivBtnMain {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .tranding_Div {
        padding: 0;
        padding-top: 2rem;
    }

    .tranding_Div_upper h1 {
        font-size: 2rem !important;
    }

    .tranding_Div_upper h3 {
        font-size: 1.5rem !important;
    }

    .tranding_Div_lowerMain {
        padding: 2rem;
    }

    /* .traingle2 {
        margin-left: 0;
        width: 10%;
    } */

    /* .star {
        margin-left: 90%;
        width: 20%;
    } */

    .belowSliderImg h4 {
        font-size: 0.9rem;
    }

    .catgriesSliderDataleft {
        padding: 1rem;
        width: 60%;
    }

    .Categories_belowSliderImg button {
        width: 5rem;
    }

    .catgriesSliderDataRight {
        width: 40%;
    }

    .categriesSliderRightPanelupper {
        font-size: 1.6rem;
    }

    .categriesSliderRightPanellower {
        font-size: 1.2rem;
    }
}

@media (min-width:375px) and (max-width:420px) {
    .upper1_div {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .upper1_div_1 {
        width: 100%;
    }

    .upper1_div_1 h1 {
        font-size: 2rem;

    }

    .upper1_div_2 {
        width: 100%;
    }

    .upper1_div_1 {
        width: 100%;
        text-align: center;
        font-size: 0.7rem;
        margin-bottom: 5%;
        margin-top: 5%;

    }

    .images_pack {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .upper1_div h1 {
        width: 100%;
        filter: brightness(1);
    }

    .line1 {
        margin-left: -90%;
        margin-top: 1%;
    }

    .line2 {
        width: 31%;
        margin-left: -60%;
    }

    .line3 {
        margin-left: -30%;
        margin-top: -6%;
    }

    .line4 {
        width: 31%;
        margin-left: 0%;
        margin-top: 0%;
    }

    .line5 {
        margin-left: 30%;
        margin-top: -7%;
    }

    .lowerDivMain {
        width: 100%;
        margin-top: 5%;
    }

    .lowerDiv {
        width: 100%;
    }

    .lowerDiv1_1 {
        font-size: 0.6rem;
    }

    .lowerDiv1_1 h1 {
        font-size: 1.3rem;
    }

    .lowerDivtrust {
        width: 100%;
    }

    .lowerDivtrust h1 {
        font-size: 1.5rem;
        font-weight: 300 !important;
    }

    .lowerDivBtnMain {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .tranding_Div {
        padding: 0;
        padding-top: 2rem;
    }

    .tranding_Div_upper h1 {
        font-size: 2rem !important;
    }

    .tranding_Div_upper h3 {
        font-size: 1rem;
    }

    .tranding_Div_lowerMain {
        padding: 2rem;
    }

    .belowSliderImg h4 {
        font-size: 0.9rem;
    }

    /* .traingle2 {
        margin-left: 0;
        width: 10%;
    } */

    /* .star {
        margin-left: 90%;
        width: 20%;
    } */

    .rightCircleShade {
        display: none;
    }

    .catgriesSliderDataleft {
        padding: 1rem;
        width: 60%;
    }

    .Categories_belowSliderImg button {
        width: 5rem;
    }

    .catgriesSliderDataRight {
        width: 40%;
    }

    .categriesSliderRightPanelupper {
        font-size: 1.6rem;
    }

    .categriesSliderRightPanellower {
        font-size: 1.2rem;
    }
}

@media (min-width:420px) and (max-width:580px) {
    .upper1_div {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .upper1_div_1 {
        width: 100%;
    }

    .upper1_div_1 h1 {
        font-size: 2.3rem;

    }

    .upper1_div_2 {
        width: 100%;
    }

    .upper1_div_1 {
        width: 100%;
        text-align: center;
        font-size: 0.7rem;
        margin-bottom: 5%;
        margin-top: 5%;

    }

    .images_pack {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .upper1_div h1 {
        width: 100%;
        filter: brightness(1);
    }

    .line1 {
        margin-left: -90%;
        margin-top: 1%;
    }

    .line2 {
        width: 31%;
        margin-left: -60%;
    }

    .line3 {
        margin-left: -30%;
        margin-top: -6%;
    }

    .line4 {
        width: 31%;
        margin-left: 0%;
        margin-top: 0%;
    }

    .line5 {
        margin-left: 30%;
        margin-top: -7%;
    }

    .lowerDivMain {
        width: 100%;
        margin-top: 5%;
    }

    .lowerDiv {
        width: 100%;
    }

    .lowerDiv1_1 {
        font-size: 0.6rem;
    }

    .lowerDiv1_1 h1 {
        font-size: 1.3rem;
    }

    .lowerDivtrust {
        width: 100%;
    }

    .lowerDivtrust h1 {
        font-size: 1.5rem;
        font-weight: 300 !important;
    }

    .lowerDivBtnMain {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .tranding_Div {
        padding: 0;
        padding-top: 2rem;
    }

    .tranding_Div_upper h1 {
        font-size: 2rem !important;
    }

    .tranding_Div_upper h3 {
        font-size: 1rem;
    }

    .tranding_Div_lowerMain {
        padding: 2rem;
    }

    .belowSliderImg h4 {
        font-size: 0.9rem;
    }

    .rightCircleShade {
        display: none;
    }

    /* .star {
        margin-left: 90%;
        width: 20%;
    } */

    /* .traingle2 {
        margin-left: 0;
        width: 10%;
    } */

    .catgriesSliderDataleft {
        padding: 1rem;
        width: 60%;
    }

    .Categories_belowSliderImg button {
        width: 5rem;
    }

    .catgriesSliderDataRight {
        width: 40%;
    }

    .categriesSliderRightPanelupper {
        font-size: 1.6rem;
    }

    .categriesSliderRightPanellower {
        font-size: 1.2rem;
    }
}

@media (min-width:580px) and (max-width:720px) {
    .upper1_div {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .upper1_div_1 {
        width: 100%;
    }

    .upper1_div_1 h1 {
        font-size: 2.5rem;

    }

    .upper1_div_2 {
        width: 100%;
    }

    .upper1_div_1 {
        width: 100%;
        text-align: center;
        font-size: 0.7rem;
        margin-bottom: 5%;
        margin-top: 5%;

    }

    .images_pack {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .upper1_div h1 {
        width: 100%;
        filter: brightness(1);
    }

    .line1 {
        margin-left: -90%;
        margin-top: 1%;
    }

    .line2 {
        width: 31%;
        margin-left: -60%;
    }

    .line3 {
        margin-left: -30%;
        margin-top: -6%;
    }

    .line4 {
        width: 31%;
        margin-left: 0%;
        margin-top: 0%;
    }

    .line5 {
        margin-left: 30%;
        margin-top: -7%;
    }

    .lowerDivMain {
        width: 100%;
        margin-top: 5%;
    }

    .lowerDiv {
        width: 100%;
    }

    .lowerDiv1_1 {
        font-size: 0.6rem;
    }

    .lowerDiv1_1 h1 {
        font-size: 1.3rem;
    }

    .lowerDivtrust {
        width: 100%;
    }

    .lowerDivtrust h1 {
        font-size: 1.5rem;
        font-weight: 300 !important;
    }

    .lowerDivBtnMain {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .tranding_Div {
        padding: 0;
        padding-top: 2rem;
    }

    .tranding_Div_upper h1 {
        font-size: 2rem !important;
    }

    .tranding_Div_upper h3 {
        font-size: 1rem;
    }

    .tranding_Div_lowerMain {
        padding: 2rem;
    }

    .belowSliderImg h4 {
        font-size: 0.9rem;
    }

    .rightCircleShade {
        display: none;
    }

    /* .traingle2 {
        margin-left: 0;
        width: 10%;
    } */

    /* .star {
        margin-left: 90%;
        width: 20%;
    } */

    .catgriesSliderDataleft {
        padding: 1rem;
        width: 70%;
    }

    .Categories_belowSliderImg button {
        width: 5rem;
    }

    .catgriesSliderDataRight {
        width: 30%;
    }

    .categriesSliderRightPanelupper {
        font-size: 1.6rem;
    }

    .categriesSliderRightPanellower {
        font-size: 1.2rem;
    }
}

@media (min-width:1440px) and (max-width:1680px) {
    .upper1_div_1 {
        display: flex;
        justify-content: center;
        font-size: 2.2rem;
        text-align: center;
        filter: brightness(0.5);
        width: 50%;

    }

    .lowerDivBtnMain{
        display: flex;
        justify-content: start;
        padding: 1rem;
    }
    .lowerDivBtn{
        width: 50%;
        height: 3.5rem;
        font-size: 1.5rem;
    }
    .lowerDiv1_1 h1{
        font-size: 2.7rem;
    }
    .lowerDiv1_1 h2{
        font-size: 1.5rem;
        padding-top: 0.5rem;
    }
    .upper1_div_2{
        width: 50%;
    }
    .lowerDiv{
        width: 65%;
    }
    .lowerDivtrust h1{
        font-size: 2.4rem;
        font-weight: 300 !important;
    }

}
@media (min-width:1680px) and (max-width:1980px) {
    .upper1_div_1 {
        display: flex;
        justify-content: center;
        font-size: 2.6rem;
        text-align: center;
        filter: brightness(0.5);
        width: 50%;
    }
    .lowerDivBtnMain{
        display: flex;
        justify-content: start;
        padding: 1rem;
    }
    .lowerDivBtn{
        width: 30%;
        height: 3.5rem;
        font-size: 1.5rem;
    }
    .upper1_div_2{
        width: 50%;
    }
    .lowerDiv{
        width: 65%;
    }
    .lowerDivtrust h1{
        font-weight: 300 !important;
        font-size: 2.6rem;
    }
    .lowerDiv1_1 h1{
        font-size: 2.9rem;
    }
    .lowerDiv1_1 h2{
        font-size: 1.9rem;
    }
}
@media (min-width:1980px) and (max-width:2240px) {
    .upper1_div_1 {
        display: flex;
        justify-content: center;
        font-size: 2.8rem;
        text-align: center;
        filter: brightness(0.5);
        width: 50%;
    }
    .lowerDivBtnMain{
        display: flex;
        justify-content: start;
        padding: 1rem;
    }
    .lowerDivBtn{
        width: 30%;
        height: 3.5rem;
        font-size: 1.5rem;
    }
    .upper1_div_2{
        width: 50%;
    }
    .lowerDiv{
        width: 65%;
    }
    .lowerDivtrust h1{
        font-size: 2.8rem;
        font-weight: 300 !important;
    }
    .lowerDiv1_1 h1{
        font-size: 3.1rem;
    }
    .lowerDiv1_1 h2{
        font-size: 2.2rem;
    }
}
@media (min-width:2240px) and (max-width:2420px) {
    .upper1_div_1 {
        display: flex;
        justify-content: center;
        font-size: 3rem;
        text-align: center;
        filter: brightness(0.5);
        width: 50%;
    }
    .lowerDivBtnMain{
        display: flex;
        justify-content: start;
        padding: 1rem;
    }
    .lowerDivBtn{
        width: 30%;
        height: 3.5rem;
        font-size: 1.5rem;
    }
    .upper1_div_2{
        width: 50%;
    }
    .lowerDiv{
        width: 65%;
    }
    .lowerDivtrust h1{
        font-size: 3rem;
        font-weight: 300 !important;
    }
    .lowerDiv1_1 h1{
        font-size: 3.2rem;
    }
    .lowerDiv1_1 h2{
        font-size: 2.4rem;
    }
}
@media (min-width:2420px) {
    .upper1_div_1 {
        display: flex;
        justify-content: center;
        font-size: 3.3rem;
        text-align: center;
        filter: brightness(0.5);
        width: 50%;
    }
    .lowerDivBtnMain{
        display: flex;
        justify-content: start;
        padding: 1rem;
    }
    .lowerDivBtn{
        width: 30%;
        height: 3.5rem;
        font-size: 1.5rem;
    }
    .upper1_div_2{
        width: 50%;
    }
    .lowerDiv{
        width: 65%;
    }
    .lowerDivtrust h1{
        font-size: 3.2rem;
        font-weight: 300 !important;
    }
    .lowerDiv1_1 h1{
        font-size: 3.4rem;
    }

    .lowerDiv1_1 h2{
        font-size: 2.6rem;
    }
}

@media (min-width:1080px) and (max-width:1440px){
    .catgriesSliderDataleft{
        width: 70%;
    }
    .catgriesSliderDataRight{
        width: 30%;
    }
    .catgriesSliderDataRight{
        font-size: 1.8rem;
    }
    .categriesSliderRightPanellower{
        font-size: 1.6rem;
    }
    .lowerDivtrust h1{
        font-weight: 300 !important;
    }
    .lowerDivBtnMain{
        display: flex;
        justify-content: start;
        padding: 1rem;
    }
    .lowerDivBtn{
        width: 50%;
        height: 3.5rem;
        font-size: 1.7rem;
    }
    .tranding_Div_upper{
        font-size: 1.55rem !important;
    }
}
@media (min-width:1440px){
    .catgriesSliderDataleft{
        width: 70%;
    }
    .catgriesSliderDataRight{
        width: 30%;
    }
    .catgriesSliderDataRight{
        font-size: 1.8rem;
    }
    .categriesSliderRightPanellower{
        font-size: 1.6rem;
    }
    .lowerDivMain{
        margin-top: -6%;
    }
    .tranding_Div_upper{
        font-size: 2rem !important;
    }
}

@media (min-width:720px) and (max-width:880px){
    .upper1_div_1{
        font-size: 1.1rem;
    }
    .lowerDivMain{
        width: 90%;
        margin-top: -9%;
    }
    .lowerDivtrust h1{
        font-size: 1.3rem;
    }
}
@media (min-width:880px) and (max-width:1080px){
    .upper1_div_1{
        font-size: 1.2rem;
    }
    .lowerDivMain{
        width: 90%;
        margin-top: -7%;
    }
}