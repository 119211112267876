* {
    margin: 0;
    padding: 0;
}

.mainPAge_MasoomTrustActivities {
    width: 100%;
}

.headerMAinDIv_MasoomTrustActivities {
    background-image: url("../images//agendaBG.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    padding: 2rem 0;
    margin-top: 4rem;
    position: relative;
    z-index: 1;
}

.headingDiv_MasoomTrustActivities {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem;
}

.headingDiv_MasoomTrustActivities h1 {
    color: #fff;
    font-weight: 600;
}



.headingDiv_MasoomTrustActivities h3 {
    padding-top: 2rem;
    width: 84%;
    text-align: center;
    color: #fff;
    text-transform: capitalize;
}

.underLine_MasoomTrustActivities {
    height: 3px;
    background: #FFEE58;
    margin-top: 1rem;
    width: 80%;
    position: relative;
    left: 20%;
}

.mysoreOneInnerMAinDiv_MasoomTrustActivities {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MainmysoreOneMAinDiv_MasoomTrustActivities {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 4rem;
}

.mysoreOneMAinDiv_MasoomTrustActivities {
    width: 80%;

}

.mysoreOneHeader_MasoomTrustActivities {
    text-transform: capitalize;
}

.mysoreOneHeader_MasoomTrustActivities h2 {
    font-weight: 600;
}

.underLineMysorOneHeadingMainDiv_MasoomTruestActivities {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 1rem;
}

.underLineMysorOneHeading_MasoomTruestActivities {
    height: 2px;
    background-color: #FFEE58;
    width: 5%;
}

.mysorOneLocationMainDiv_MasoomTrustActivities {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 3rem;
}

.mysoreOneMoctionInnerDiv_MasoomTrustActivities {
    display: flex;
}

.mysoreOneMoctionInnerDiv_MasoomTrustActivities h2 {
    font-weight: 600;
    text-transform: uppercase;
    color: #464646;
}

.maysoreOneImgMainDiv_MasoomTrustActivities img {
    width: 100%;
    padding-top: 3rem;
}

.mysoreOneImgeContent_MasoomTrustActivities h2 {
    text-align: center;
    padding-top: 1rem;
    font-weight: 600;
}

.mysoreTwoInnerMAinDiv_MasoomTrustActivities {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MainmysoreTwoMAinDiv_MasoomTrustActivities {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 4rem 0;
    background: #DDEFE3;
    margin-top: 4rem;
}

.mysoreTwoMAinDiv_MasoomTrustActivities {
    width: 80%;
}

.mysoreTwoHeader_MasoomTrustActivities {
    text-transform: capitalize;
}

.mysoreTwoHeader_MasoomTrustActivities h2 {
    font-weight: 600;
}

.underLineMysorTwoHeadingMainDiv_MasoomTruestActivities {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 1rem;
}

.underLineMysorTwoHeading_MasoomTruestActivities {
    height: 2px;
    background-color: #A4A4A4;
    width: 5%;
}

.mysorTwoLocationMainDiv_MasoomTrustActivities {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 3rem;
}

.mysoreTwoMoctionInnerDiv_MasoomTrustActivities {
    display: flex;
}

.mysoreTwoMoctionInnerDiv_MasoomTrustActivities h2 {
    font-weight: 600;
    text-transform: uppercase;
    color: #464646;
}

.maysoreTwoImgMainDiv_MasoomTrustActivities img {
    /* width: 100%; */
    padding-top: 3rem;
}

.mysoreTwoImgeContent_MasoomTrustActivities h2 {
    text-align: center;
    padding-top: 1rem;
    font-weight: 600;
}

.imgMainDiv_MasoomTrustActivities {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.UdyagiriInnerMAinDiv_MasoomTrustActivities {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MainUdyagiriMAinDiv_MasoomTrustActivities {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 4rem 0;
    /* background: #DDEFE3; */
    /* margin-top: 4rem; */
}

.UdyagiriMAinDiv_MasoomTrustActivities {
    width: 80%;
}

.UdyagiriHeader_MasoomTrustActivities {
    text-transform: capitalize;
}

.UdyagiriHeader_MasoomTrustActivities h2 {
    font-weight: 600;
}

.underLineUdyagiriHeadingMainDiv_MasoomTruestActivities {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 1rem;
}

.underLineUdyagiriHeading_MasoomTruestActivities {
    height: 2px;
    background-color: #A4A4A4;
    width: 5%;
}

.UdyagiriLocationMainDiv_MasoomTrustActivities {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 3rem;
}

.UdyagiriMoctionInnerDiv_MasoomTrustActivities {
    display: flex;
}

.UdyagiriMoctionInnerDiv_MasoomTrustActivities h2 {
    font-weight: 600;
    text-transform: uppercase;
    color: #464646;
}

.UdyagiriImgMainDiv_MasoomTrustActivities img {
    width: 100%;
    height: -webkit-fill-available;
    padding-top: 3rem;
}

.UdyagiriImgeContent_MasoomTrustActivities h2 {
    text-align: center;
    padding-top: 1rem;
    font-weight: 600;
}

.UdyagiriimgMainDiv_MasoomTrustActivities {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.SrirangapatanaInnerMAinDiv_MasoomTrustActivities {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MainSrirangapatanaMAinDiv_MasoomTrustActivities {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 4rem 0;
    background: #DDEFE3;
    margin-top: 4rem;
}

.SrirangapatanaMAinDiv_MasoomTrustActivities {
    width: 80%;
}

.SrirangapatanaHeader_MasoomTrustActivities {
    text-transform: capitalize;
}

.SrirangapatanaHeader_MasoomTrustActivities h2 {
    font-weight: 600;
}

.underLineSrirangapatanaHeadingMainDiv_MasoomTruestActivities {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 1rem;
}

.underLineSrirangapatanaHeading_MasoomTruestActivities {
    height: 2px;
    background-color: #A4A4A4;
    width: 5%;
}

.SrirangapatanaLocationMainDiv_MasoomTrustActivities {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 3rem;
}

.SrirangapatanaMoctionInnerDiv_MasoomTrustActivities {
    display: flex;
}

.SrirangapatanaMoctionInnerDiv_MasoomTrustActivities h2 {
    font-weight: 600;
    text-transform: uppercase;
    color: #464646;
}

.SrirangapatanaImgMainDiv_MasoomTrustActivities img {
    width: 100%;
    height: -webkit-fill-available;
    padding-top: 3rem;
}

.SrirangapatanaImgeContent_MasoomTrustActivities h2 {
    text-align: center;
    padding-top: 1rem;
    font-weight: 600;
}

.SrirangapatanaimgMainDiv_MasoomTrustActivities {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.tumkurInnerMAinDiv_MasoomTrustActivities {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MaintumkurMAinDiv_MasoomTrustActivities {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 4rem 0;
    /* background: #DDEFE3; */
    /* margin-top: 4rem; */
}

.tumkurMAinDiv_MasoomTrustActivities {
    width: 80%;
}

.tumkurHeader_MasoomTrustActivities {
    text-transform: capitalize;
}

.tumkurHeader_MasoomTrustActivities h2 {
    font-weight: 600;
}

.underLinetumkurHeadingMainDiv_MasoomTruestActivities {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 1rem;
}

.underLinetumkurHeading_MasoomTruestActivities {
    height: 2px;
    background-color: #A4A4A4;
    width: 5%;
}

.tumkurLocationMainDiv_MasoomTrustActivities {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 3rem;
}

.tumkurMoctionInnerDiv_MasoomTrustActivities {
    display: flex;
}

.tumkurMoctionInnerDiv_MasoomTrustActivities h2 {
    font-weight: 600;
    text-transform: uppercase;
    color: #464646;
}

.tumkurImgMainDiv_MasoomTrustActivities img {
    width: 100%;
    height: -webkit-fill-available;
    padding-top: 3rem;
}

.tumkurImgeContent_MasoomTrustActivities h2 {
    text-align: center;
    padding-top: 1rem;
    font-weight: 600;
}

.tumkurimgMainDiv_MasoomTrustActivities {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.ShivamoggaInnerMAinDiv_MasoomTrustActivities {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MainShivamoggaMAinDiv_MasoomTrustActivities {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 4rem 0;
    background: #DDEFE3;
    margin-top: 4rem;
}

.ShivamoggaMAinDiv_MasoomTrustActivities {
    width: 80%;
}

.ShivamoggaHeader_MasoomTrustActivities {
    text-transform: capitalize;
}

.ShivamoggaHeader_MasoomTrustActivities h2 {
    font-weight: 600;
}

.underLineShivamoggaHeadingMainDiv_MasoomTruestActivities {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 1rem;
}

.underLineShivamoggaHeading_MasoomTruestActivities {
    height: 2px;
    background-color: #A4A4A4;
    width: 5%;
}

.ShivamoggaLocationMainDiv_MasoomTrustActivities {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 3rem;
}

.ShivamoggaMoctionInnerDiv_MasoomTrustActivities {
    display: flex;
}

.ShivamoggaMoctionInnerDiv_MasoomTrustActivities h2 {
    font-weight: 600;
    text-transform: uppercase;
    color: #464646;
}

.ShivamoggaImgMainDiv_MasoomTrustActivities img {
    width: 100%;
    height: -webkit-fill-available;
    padding-top: 3rem;

}

.ShivamoggaImgeContent_MasoomTrustActivities h2 {
    text-align: center;
    padding-top: 1rem;
    font-weight: 600;
}

.ShivamoggaimgMainDiv_MasoomTrustActivities {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
}

.ShivamoggaimgMainDiv_MasoomTrustActivities img {
    width: 100%;
    object-fit: fill;
    height: -webkit-fill-available !important;
}
.ShivamoggaimgLowerMainDiv_MasoomTrustActivities{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
}
.ShivamoggaimgLowerInnerMainDiv_MasoomTrustActivities{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 1rem;
}