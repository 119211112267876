* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
}

.forgotPasswordTopShade_OPT {
    width: 421px;
    height: 421px;
    flex-shrink: 0;
    border-radius: 421px;
    background: rgba(221, 239, 227, 0.50);
    position: absolute;
    margin-top: -30%;
    margin-left: 10%;
}

.mainDiv_forgetPassword_OPT {
    width: 100%;
    display: flex;
}

.mainLeftDiv_forgetPassword_OPT {
    width: 60%;
    padding: 4rem 0rem 0rem 4rem;
    /* border: 1px solid red; */
}

.upperDiv_forgotPassword_OPT {
    text-align: center;
}

.upperDiv_forgotPassword_OPT h1 {
    font-size: 2.4rem;
    margin-bottom: 2%;
}

.upperDiv_forgotPassword_OPT h5 {
    font-weight: 500;
    margin-bottom: 3%;
}

.forgetEmail_OPT {
    display: flex;
    align-items: center;
    width: 60%;
    /* border: 1px solid; */
    border-bottom: 3px solid black;
    margin-top: 2%;

}

.signEmailLogo_forgotPassword_OPT {
    width: 10%;
    display: flex;
    align-items: center;
    border: none;
}

.signEmailLogo_forgotPassword_OPT_logo {
    filter: brightness(0.5);
}

.forgotEmailInput_OPT {
    width: 90%;
}

.forgotEmailInput_OPT input {
    width: 100%;
    border: none;
    font-size: 1.3rem;
    text-align: center;
}

.input_forgotPassowrd_OPT:focus {
    outline: none;
}

.input_forgotPassowrd_OPT :focus {
    outline: none;
}

.forgotPasswordOR_OPT {
    text-align: center;
    width: 60%;
    margin-top: 4%;
}

.btnReset_OPT {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5%;
    /* border: 1px solid; */
}

.btnReset_OPT button {
    width: 45%;
    height: 2.8rem;
    font-size: 1.5rem;
    background-color: #FFEE58;
    border: 1px solid;
    border-radius: 10px;
}

.forgotPasswordbottomShade_OPT {
    position: absolute;
    top: 65%;
    left: -8%;
    transform: translate(-50%, -50%);
    height: 210.5px;
    width: 421px;
    border-radius: 210px 200px 0 0;
    background-color: rgba(221, 239, 227, 0.50);
    position: absolute;
    margin-top: 10%;
    margin-left: 10%;
    z-index: 10;
}

.mainrightDiv_forgetPassword_OPT {
    z-index: 10;
}

.forgotPasswordRightShade_OPT {
    z-index: 0;
    width: 249px;
    height: 205px;
    flex-shrink: 0;
    background-color: rgba(221, 239, 227, 0.50);
    border-radius: 249px 249px 200px 0;
    position: absolute;
    margin-left: 80%;
    margin-top: -2%;
}

@media (max-width:1080px) {
    .forgotPasswordRightShade_OPT {
        display: none;
    }

    .mainrightDiv_forgetPassword_OPT {
        display: none;
    }

    .forgotPasswordTopShade_OPT {
        display: none;
    }

    .mainLeftDiv_forgetPassword_OPT {
        width: 100%;
    }

    .mainLeftDiv_forgetPassword_OPT {
        padding: 1.5rem;
    }

    .forgetEmail_OPT {
        width: 80%;
    }
}

@media (min-width:320px) and (max-width:720px) {
    .upperDiv_forgotPassword_OPT h1 {
        font-size: 1.8rem;
    }
}

@media (min-width:580px) and (max-width:1080px) {
    .forgetEmail_OPT {
        width: 60%;
    }
}