* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    font-weight: 500;
}

.mainDiv_Success {
    width: 100%;
    margin-top: 2%;
    /* border: 1px solid red; */
}

.header_Success {
    text-align: center;
    font-size: 1.8rem;
}

.prevSvg_Success {
    width: 50px;
    height: 50px;
    position: absolute;
    cursor: pointer;
    bottom: 50%;
    left: 3%;
}

.NxtSvg_Success {
    cursor: pointer;
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 50%;
    right: 3%;
    
}

.sliderImgMainDiv_Success {
    display: flex;
}

.sliderimg_Success {
    width: 100%;
    height: 100% !important;
    border-radius: 15px;
    /* object-fit: none */
}

.belowSliderImg_Success {
    padding: 2rem;
    width: 97%;
    position: absolute;
    top: 60%;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    height: -webkit-fill-available;
}

.belowSliderImg_Success h4 {
    font-weight: 100;
    font-size: 1rem;
}

.cardContain_Success {
    padding: 3rem;
}

.readMore_Success {
    border: none;
    margin-top: 2%;
    background-color: transparent;
    font-size: 1.8rem;
    color: white;
}

.belowSliderImg_SS_lower_Success {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.belowSliderImg_SS_lower_Success h3 {
    font-size: 1.4rem;
    color: white;
}

.belowSliderImg_SS_lower_Success button {
    width: 8rem;
    height: 2.7rem;
    font-size: 1.4rem;
    border-radius: 10px;
    background-color: #FFEE58;
}


.stepLower_Div_Success {
    width: 100%;
    /* margin-top: 4%; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.StepLower_DivBtn_Success {
    width: 60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 20%;
}

.start_Btn_Success {
    width: 390px;
    height: 50px;
    border-radius: 10px;
    background-color: #FFEE58;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;
}

.talk_Btn_Success {
    width: 200px;
    border-radius: 10px;
    height: 50px;
    background-color: transparent;
    border: 1px solid;
}

.chat_Btn_Success {
    width: 60%;
    margin-left: 20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 3%;
    padding: 1rem;
}

.chat_Btn_Success h4 {
    font-weight: 100;
    font-size: 1.2rem;
}

.chatUS_Btn_Success {
    width: 200px;
    border-radius: 10px;
    height: 50px;
    background-color: transparent;
    border: 1px solid;
}

@media (min-width:720px) and (max-width:1080px) {
    .belowSliderImg_Success {
        width: 100%;
        height: 100%;
        font-size: 1.2rem;
        top: 45%;
        height: -webkit-fill-available;
    }

    .StepLower_DivBtn_Success {
        width: 80%;
    }

    .chat_Btn_Success {
        width: 75%;
    }
}

@media (min-width:1080px) and (max-width:1440px) {
    .belowSliderImg_Success {
        width: 100%;
        height: 100%;
        top: 50%;
        font-size: 1.5rem;
        height: -webkit-fill-available;
    }

    .StepLower_DivBtn_Success {
        width: 80%;
    }

    .chat_Btn_Success {
        width: 75%;
    }
}

@media (min-width:320px) and (max-width:375px) {
    .header_Success {
        font-size: 1rem;
    }

    .cardContain_Success {
        padding: 1rem;
    }

    .belowSliderImg_Success {
        font-size: 0.6rem;
        height: -webkit-fill-available;
    }

    .readMore_Success {
        font-size: 0.6rem;

    }

    .StepLower_DivBtn_Success {
        margin-left: 0;
        width: 100%;
    }

    .start_Btn_Success {
        font-size: 0.7rem;
        width: 65%;
    }

    .talk_Btn_Success {
        width: 25%;
        font-size: 0.7rem;
    }

    .chat_Btn_Success {
        width: 95%;
        margin-left: 0;
    }

    .chat_Btn_Success h4 {
        font-size: 0.8rem;
    }

    .chatUS_Btn_Success {
        width: 53%;
        font-size: 0.7rem;
    }

    .belowSliderImg_Success {
        font-size: 0.5rem;
        padding: 0.5rem;
        width: 100%;
        top: 45%;
        height: -webkit-fill-available;
    }

    .readMore_Success {
        font-size: 0.6rem;
    }

    .belowSliderImg_SS_lower_Success {
        margin-top: 2%;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
    }

    .belowSliderImg_SS_lower_Success h3 {
        font-size: 1rem;
        width: 100%;
    }

    .belowSliderImg_SS_lower_Success button {

        width: 50%;
        /* height: 1rem; */
        font-size: 1rem;

    }


}
@media (min-width:375px) and (max-width:420px) {
    .header_Success {
        font-size: 1rem;
    }

    .cardContain_Success {
        padding: 1rem;
    }

    .belowSliderImg_Success {
        font-size: 0.6rem;
        height: -webkit-fill-available;
    }

    .readMore_Success {
        font-size: 0.6rem;

    }

    .StepLower_DivBtn_Success {
        margin-left: 0;
        width: 100%;
    }

    .start_Btn_Success {
        font-size: 0.7rem;
        width: 65%;
    }

    .talk_Btn_Success {
        width: 25%;
        font-size: 0.7rem;
    }

    .chat_Btn_Success {
        width: 95%;
        margin-left: 0;
    }

    .chat_Btn_Success h4 {
        font-size: 0.8rem;
    }

    .chatUS_Btn_Success {
        width: 53%;
        font-size: 0.7rem;
    }

    .belowSliderImg_Success {
        font-size: 0.5rem;
        padding: 0.5rem;
        width: 100%;
        top: 60%;
        height: -webkit-fill-available;
    }

    .readMore_Success {
        font-size: 0.6rem;
    }

    .belowSliderImg_SS_lower_Success {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .belowSliderImg_SS_lower_Success h3 {
        font-size: 1rem;
        width: 75%;
    }

    .belowSliderImg_SS_lower_Success button {

        width: 25%;
        /* height: 1.4rem; */
        font-size: 1rem;

    }


}
@media (min-width:420px) and (max-width:580px) {
    .header_Success {
        font-size: 1rem;
    }

    .cardContain_Success {
        padding: 1rem;
    }

    .belowSliderImg_Success {
        font-size: 0.6rem;
        height: -webkit-fill-available;
    }

    .readMore_Success {
        font-size: 0.6rem;

    }

    .StepLower_DivBtn_Success {
        margin-left: 0;
        width: 100%;
    }

    .start_Btn_Success {
        font-size: 0.7rem;
        width: 65%;
    }

    .talk_Btn_Success {
        width: 25%;
        font-size: 0.7rem;
    }

    .chat_Btn_Success {
        width: 95%;
        margin-left: 0;
    }

    .chat_Btn_Success h4 {
        font-size: 0.8rem;
    }

    .chatUS_Btn_Success {
        width: 53%;
        font-size: 0.7rem;
    }

    .belowSliderImg_Success {
        font-size: 0.5rem;
        padding: 0.5rem;
        width: 100%;
        top: 62%;
        height: -webkit-fill-available;
    }

    .readMore_Success {
        font-size: 0.6rem;
    }

    .belowSliderImg_SS_lower_Success {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .belowSliderImg_SS_lower_Success h3 {
        font-size: 1rem;
        width: 75%;
    }

    .belowSliderImg_SS_lower_Success button {

        width: 25%;
        /* height: 1.4rem; */
        font-size: 1rem;

    }


}

@media (min-width:475px) and (max-width:520px){
    .belowSliderImg_Success {
        font-size: 0.5rem;
        padding: 0.5rem;
        width: 100%;
        top: 67%;
        height: -webkit-fill-available;
    }
}
@media (min-width:520px) and (max-width:580px){
    .belowSliderImg_Success {
        font-size: 0.5rem;
        padding: 0.5rem;
        width: 100%;
        top: 70%;
        height: -webkit-fill-available;
    }
}
@media (min-width:580px) and (max-width:720px) {
    .header_Success {
        font-size: 1rem;
    }

    .cardContain_Success {
        padding: 1rem;
    }

    .belowSliderImg_Success {
        font-size: 0.6rem;
        height: -webkit-fill-available;
    }

    .readMore_Success {
        font-size: 0.6rem;

    }

    .StepLower_DivBtn_Success {
        margin-left: 0;
        width: 100%;
    }

    .start_Btn_Success {
        font-size: 0.7rem;
        width: 65%;
    }

    .talk_Btn_Success {
        width: 25%;
        font-size: 0.7rem;
    }

    .chat_Btn_Success {
        width: 95%;
        margin-left: 0;
    }

    .chat_Btn_Success h4 {
        font-size: 0.8rem;
    }

    .chatUS_Btn_Success {
        width: 53%;
        font-size: 0.7rem;
    }

    .belowSliderImg_Success {
        font-size: 0.5rem;
        padding: 0.5rem;
        width: 100%;
        top: 70%;
        height: 7rem;
        height: -webkit-fill-available;
    }

    .readMore_Success {
        font-size: 0.6rem;
    }

    .belowSliderImg_SS_lower_Success {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .belowSliderImg_SS_lower_Success h3 {
        font-size: 1rem;
        width: 75%;
    }

    .belowSliderImg_SS_lower_Success button {

        width: 25%;
        /* height: 1.rem; */
        font-size: 1rem;

    }


}
@media (min-width:1440px){
    .sliderImgMain_Success{
        width: 100%;
    }
    .sliderimg_Success{
        width: inherit;
    }
    .belowSliderImg_Success{
        width: 100%;
        height: -webkit-fill-available;
    }
}
@media (min-width:720px){
    .sliderImgMain_Success{
        width: 100%;
    }
}