* {
    margin: 0;
    padding: 0;
    font-family: "Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
}

.mainDiv_AboutUsPage {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5%;
}

.innerMainDiv_AboutUsPage {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.inner1MainDiv_AboutUsPage {
    width: 50%;
    /* border: 1px solid ; */
}

.upperDivConatin_AboutUsPage {
    border: 1px solid rgba(22, 22, 22, 0.14);
    margin-top: 5%;
    border-radius: 15px;
}

.inner2MainDiv_AboutUsPage {
    width: 45%;
    /* border: 1px solid red; */
}

.upperMainImgDiv_AboutUsPage {
    width: 100%;
}

.upperDivConatin_AboutUsPage {
    padding: 2rem;
}

.header_AboutUsPage {
    text-align: center;
    margin-bottom: 3%;
}

.upperMainImgDiv_AboutUsPage img {
    /* width: 100%; */
    border-radius: 15px;
}

.postUpper_AboutUsPage {
    display: flex;
}

.postDown_AboutUsPage {
    display: flex;
}

.post1_1_AboutUsPage {
    font-weight: 400;
}

.post2_2_AboutUsPage {
    font-weight: 400;
}

.btnMainDiv_AboutUsPage {
    display: flex;
    justify-content: center;
    margin-top: 5%;
}

.btnInnerDiv_AboutUsPage {
    display: flex;
    justify-content: space-around;
    width: 50%;
}

.btnWhastapp_AboutUsPage {
    width: 30%;
    height: 2.5rem;
    border-radius: 5px;
    border: 1px solid;
    background-color: #00CE73;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btnWhastapp_AboutUsPage img {
    margin-right: 5%;
}

.btnFaceBook_AboutUsPage img {
    margin-right: 5%;
}

.btnFaceBook_AboutUsPage {
    width: 30%;
    height: 2.5rem;
    border-radius: 5px;
    border: 1px solid;
    background-color: #2C5A98;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lowerMoneyValueh1_AboutUsPage {
    margin-top: 5%;
}

.lowerIndicatorDiv_AboutUsPage {
    margin-top: 2%;
}

.lowerIndicatorDiv_AboutUsPage img {
    width: 100%;
    /* background-color: #FFEE58; */
}

/* .lowerIndicatorDiv_AboutUsPage img:after {
    content: '\A';
    position: absolute;
    background: red;
    top: 20; bottom: 0;
    left: 0; 
    width: 50%;
} */
.lowerDivBtn_AboutUsPage {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
}

.lowerDivBtn_AboutUsPage button {
    width: 40%;
    height: 3rem;
    border-radius: 10px;
    border: 1px solid;
    background-color: #FFEE58;
}

.inner2LowerMainDiv_AboutUsPage {
    border: 1px solid rgba(22, 22, 22, 0.14);
    border-radius: 15px;
    /* padding: 2rem; */
}

.lower2Header_AboutUsPage {
    text-align: center;
    border-bottom: 1px solid rgba(22, 22, 22, 0.14);
    ;
}

.lower2Header_AboutUsPage h2 {
    padding: 2rem;
}

.lowerDonationSummery_AboutUsPage {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}

.donorNameMainDiv_AboutUsPage {
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    padding: 0.5rem;
}

.donorName_AboutUsPage {
    display: flex;
    width: 50%;
    justify-content: space-around;
    align-items: center;
}

.donorNameProfileImg_AboutUsPage {
    width: 20%;
}

.donorNameUsername_AboutUsPage {
    width: 75%;
}

.prevSvg_AboutUs {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 3%;
    bottom: 50%;
    cursor: pointer;
}

.nxtSvg_AboutUs {
    cursor: pointer;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 3%;
    bottom: 50%;
    /* margin-right: -90%; */
    /* margin-top: -30%; */
}


@media (min-width:320px) and (max-width:375px) {
    .upperDivConatin_AboutUsPage {
        padding: 1rem;
    }

    .innerMainDiv_AboutUsPage {
        display: flex;
        flex-direction: column;
    }

    .inner1MainDiv_AboutUsPage {
        width: 100%;
    }

    .inner2MainDiv_AboutUsPage {
        width: 100%;
    }

    .header_AboutUsPage {
        font-size: 1rem;
    }

    .post1_bold_AboutUsPage {
        font-size: 0.8rem;
    }

    .post1_1_AboutUsPage {
        font-size: 0.8rem;
        font-weight: 100;
    }

    .post2_1_bold_AboutUsPage {
        font-size: 0.8rem;
    }

    .post2_2_AboutUsPage {
        font-size: 0.8rem;
        font-weight: 100;
    }

    .contain_AboutUsPage {
        font-size: 0.6rem;
    }

    .btnInnerDiv_AboutUsPage {
        width: 100%;
    }

    .inner2MainDiv_AboutUsPage {
        margin-top: 5%;
    }

    .donorName_AboutUsPage {
        width: 50%;
    }
}

@media (min-width:375px) and (max-width:420px) {
    .upperDivConatin_AboutUsPage {
        padding: 1rem;
    }

    .innerMainDiv_AboutUsPage {
        display: flex;
        flex-direction: column;
    }

    .inner1MainDiv_AboutUsPage {
        width: 100%;
    }

    .inner2MainDiv_AboutUsPage {
        width: 100%;
    }

    .header_AboutUsPage {
        font-size: 1rem;
    }

    .post1_bold_AboutUsPage {
        font-size: 0.8rem;
    }

    .post1_1_AboutUsPage {
        font-size: 0.8rem;
        font-weight: 100;
    }

    .post2_1_bold_AboutUsPage {
        font-size: 0.8rem;
    }

    .post2_2_AboutUsPage {
        font-size: 0.8rem;
        font-weight: 100;
    }

    .contain_AboutUsPage {
        font-size: 0.6rem;
    }

    .btnInnerDiv_AboutUsPage {
        width: 100%;
    }

    .inner2MainDiv_AboutUsPage {
        margin-top: 5%;
    }

    .donorName_AboutUsPage {
        width: 50%;
    }
}

@media (min-width:420px) and (max-width:580px) {
    .upperDivConatin_AboutUsPage {
        padding: 1rem;
    }

    .innerMainDiv_AboutUsPage {
        display: flex;
        flex-direction: column;
    }

    .inner1MainDiv_AboutUsPage {
        width: 100%;
    }

    .inner2MainDiv_AboutUsPage {
        width: 100%;
    }

    .header_AboutUsPage {
        font-size: 1rem;
    }

    .post1_bold_AboutUsPage {
        font-size: 0.8rem;
    }

    .post1_1_AboutUsPage {
        font-size: 0.8rem;
        font-weight: 100;
    }

    .post2_1_bold_AboutUsPage {
        font-size: 0.8rem;
    }

    .post2_2_AboutUsPage {
        font-size: 0.8rem;
        font-weight: 100;
    }

    .contain_AboutUsPage {
        font-size: 0.6rem;
    }

    .btnInnerDiv_AboutUsPage {
        width: 100%;
    }

    .inner2MainDiv_AboutUsPage {
        margin-top: 5%;
    }

    .donorName_AboutUsPage {
        width: 40%;
    }
}

@media (min-width:580px) and (max-width:720px) {
    .upperDivConatin_AboutUsPage {
        padding: 1rem;
    }

    .innerMainDiv_AboutUsPage {
        display: flex;
        flex-direction: column;
    }

    .inner1MainDiv_AboutUsPage {
        width: 100%;
    }

    .inner2MainDiv_AboutUsPage {
        width: 100%;
    }

    .header_AboutUsPage {
        font-size: 1rem;
    }

    .post1_bold_AboutUsPage {
        font-size: 0.8rem;
    }

    .post1_1_AboutUsPage {
        font-size: 0.8rem;
        font-weight: 100;
    }

    .post2_1_bold_AboutUsPage {
        font-size: 0.8rem;
    }

    .post2_2_AboutUsPage {
        font-size: 0.8rem;
        font-weight: 100;
    }

    .contain_AboutUsPage {
        font-size: 0.6rem;
    }

    .btnInnerDiv_AboutUsPage {
        width: 100%;
    }

    .inner2MainDiv_AboutUsPage {
        margin-top: 5%;
    }

    .donorName_AboutUsPage {
        width: 30%;
    }
}

@media (min-width:720px) and (max-width:1080px) {
    .upperDivConatin_AboutUsPage {
        padding: 1rem;
    }

    .innerMainDiv_AboutUsPage {
        display: flex;
        flex-direction: column;
    }

    .inner1MainDiv_AboutUsPage {
        width: 100%;
    }

    .inner2MainDiv_AboutUsPage {
        width: 100%;
    }

    .header_AboutUsPage {
        font-size: 1rem;
    }

    .post1_bold_AboutUsPage {
        font-size: 0.8rem;
    }

    .post1_1_AboutUsPage {
        font-size: 0.8rem;
        font-weight: 100;
    }

    .post2_1_bold_AboutUsPage {
        font-size: 0.8rem;
    }

    .post2_2_AboutUsPage {
        font-size: 0.8rem;
        font-weight: 100;
    }

    .contain_AboutUsPage {
        font-size: 0.6rem;
    }

    .btnInnerDiv_AboutUsPage {
        width: 100%;
    }

    .inner2MainDiv_AboutUsPage {
        margin-top: 5%;
    }

    .donorName_AboutUsPage {
        width: 30%;
    }
}