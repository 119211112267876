* {
    margin: 0;
    padding: 0;
}

.headermainDiv_Tips {
    background: url(https://d1vdjc70h9nzd9.cloudfront.net/images/corporate-header-bg.jpg) center 0 no-repeat;
    color: #fff;
    background-size: 100% 100%;
    min-height: 335px;
    display: flex;
    align-items: center;
    width: 100%;
}

.headerMianInner_Tips {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.headerMianInner_Tips h1 {
    font-size: 3rem;
    text-align: center;
    font-weight: 700;

}

.headerMianInner_Tips h2 {
    font-size: 2rem;
    margin-bottom: 1%;
}

.headerMianInner_Tips h3 {
    font-size: 2rem;
}

.secondMainDiv_tips {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding-left: 10%; */

}

.secondTips1point h1{
    font-size: 25px;
    font-weight: 700;
}
.secondTips1point p{
    font-weight: 100;
    margin-bottom: 20px;
    font-size: 1.1rem;
}
.secondTips1point {
    width: 80%;
    padding: 2rem;
}
.secondTips1point ul{
    padding-left: 2.5rem;
}
.secondTips1point li{
    padding: 1rem 0 0rem 0;
}
.thirdMainDiv{
    width: 100%;
    background: url(https://d1vdjc70h9nzd9.cloudfront.net/images/fi-ngo-footer-bg.png) top center;
    padding: 30px 0;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 31px;
}
.thirdMainDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
.thirdMainDiv a{
    text-decoration: none;
}
.thirdMainDiv h1{
    font-size: 2.2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
}

.navBarTips{
    width: 100%;
    border: 1px solid red;
    padding: 2rem;
}


.sectionNav{
    font-size: 0.8rem;
    padding: 18px 36px;
    font-weight: 200;
    color: black;
    text-decoration: none;
}