* {
    margin: 0;
    padding: 0;
}

.mainDiv_ActivitiesHealth {
    width: 100%;
    padding-top: 5rem;
}

.inner1ManiDiv_ActivitiesHealth {
    width: 100%;
    display: flex;
    justify-content: center;
}

.InnerHeaderDiv_ActivitiesHealth {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.InnerHeaderDiv_ActivitiesHealth h1{
    font-weight: 600;
    text-transform: capitalize;
}

.InnerHeaderDiv_ActivitiesHealth img {
    padding-bottom: 1rem;
}

.underLine_ActivitiesHealth {
    height: 3px;
    background: #FFEE58;
    margin: 0.5rem;

}

.inner2MainDiv_ActivitiesHealth {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 4rem;
    flex-direction: column;
    align-items: center;
}

.innerInnerUpperDiv_ActivitiesHealth {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.innerUpperContentDiv_ActivitiesHealth {
    padding-right: 1rem;
    width: 50%;
}
.innerDownContentDiv_ActivitiesHealth {
    padding-left: 1rem;
    width: 50%;
}

.innerDownContentDiv_ActivitiesHealth {
    padding-right: 0;
    padding-left: 1rem;
}

.innerUpperContentDiv_ActivitiesHealth h3 {
    font-weight: 700;
    text-transform: capitalize;
}

.innerUpperContentDiv_ActivitiesHealth p {
    line-height: 1.8;
}

.innerDownImg_ActivitiesHealth {
    width: 50%;
}
.lowwerMainDiv_ActivitiesHealth{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10rem;
}
.LowerinnerMainDiv_ActivitiesHealth{
    width: 70%;
    background-image: url("../images/huanitarianBGImg.png");
    background-size: cover;
    display: flex;
    justify-content: space-around;
    text-align: center;
    height: max-content;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
}
.LowerinnerDiv_ActivitiesHealth{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.LowerinnerMainDiv_ActivitiesHealth h1{
    width: 68%;
    text-transform: capitalize;
    color: #ffffff;
}
.donateBtn_ActivitiesHealth button{
    border-radius: 10px;
    background-color: #FFEE58;
    border: none;
}


@media (max-width:720px) {
    .innerInnerUpperDiv_ActivitiesHealth {
        flex-direction: column;
    }
    .innerInner2UpperDiv_ActivitiesHealth{
        flex-direction: column-reverse;
    }
    .innerUpperContentDiv_ActivitiesHealth{
        width: 100%;
        padding-left: 0;
    }
    .innerUpperContentDiv_ActivitiesHealth h3{
        /* text-align: center; */
    }
    .innerDownImg_ActivitiesHealth{
        width: 100%;
    }
    .innerInner2UpperDiv_ActivitiesHealth{
        padding-top: 4rem;
    }
    .innerDownImg_ActivitiesHealth img{
        padding-top: 1rem;
    }
}