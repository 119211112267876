* {
    margin: 0;
    padding: 0;
}

.mainPAge_OtherActivities {
    width: 100%;
}

.headerMAinDIv_OtherActivities {
    background-image: url("../images//agendaBG.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    padding: 2rem 0;
    margin-top: 4rem;
    position: relative;
    z-index: 1;
}
.headingDiv_OtherActivities {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem;
}

.headingDiv_OtherActivities h1 {
    color: #fff;
    font-weight: 600;
}



.headingDiv_OtherActivities h3 {
    padding-top: 2rem;
    width: 84%;
    text-align: center;
    color: #fff;
    text-transform: capitalize;
}

.underLine_OtherActivities {
    height: 3px;
    background: #FFEE58;
    margin-top: 1rem;
    width: 45%;
    position: relative;
}

.InnerMAinDiv_OtherActivities {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MAinDiv_OtherActivities {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 4rem;
}

.MainDiv_OtherActivities {
    width: 80%;

}

.Header_OtherActivities {
    text-transform: capitalize;
}

.Header_OtherActivities h2 {
    font-weight: 600;
}

.underLineHeadingMainDiv_MasoomTruestActivities {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 1rem;
}

.underLineHeading_MasoomTruestActivities {
    height: 2px;
    background-color: #FFEE58;
    width: 5%;
}
.ContentMainDiv_OtherActivities{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 4rem;
}
.ContentInnerMainDiv_OtherActivities{
    width: 80%;
    display: flex;
    justify-content: space-between;
}
.contentImgMainDiv_OtherActivities{
    width: 48%;
}
.contentImgMainDiv_OtherActivities img{
    width: 100%;
}
.contentDiv_OtherActivities{
    width: 48%;
    display: flex;
    align-items: center;
}
.contentDiv_OtherActivities h2{
    font-weight: 600;
}

