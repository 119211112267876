* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
}

.forgotPasswordTopShade {
    width: 421px;
    height: 421px;
    flex-shrink: 0;
    border-radius: 421px;
    background: rgba(221, 239, 227, 0.50);
    position: absolute;
    margin-top: -30%;
    margin-left: 10%;
}

.mainDiv_forgetPassword {
    width: 100%;
    display: flex;
}

.mainLeftDiv_forgetPassword {
    width: 60%;
    /* border: 1px solid red; */
}

.upperDiv_forgotPassword {
    text-align: center;
}

.upperDiv_forgotPassword h1 {
    font-size: 2.4rem;
    margin-bottom: 2%;
}

.upperDiv_forgotPassword h5 {
    font-weight: 500;
    margin-bottom: 3%;
}

.forgetEmail {
    display: flex;
    align-items: center;
    width: 60%;
    /* border: 1px solid; */
    border-bottom: 3px solid black;
    margin-top: 2%;

}

.signEmailLogo_forgotPassword {
    width: 10%;
}

.signEmailLogo_forgotPassword {
    width: 10%;
    display: flex;
    align-items: center;
    border: none;
}

.forgotEmailInput {
    width: 90%;
}

.forgotEmailInput input {
    width: 100%;
    border: none;
    font-size: 1.3rem;
    text-align: center;
}

.input_forgotPassowrd:focus {
    outline: none;
}

.input_forgotPassowrd :focus {
    outline: none;
}

.forgotPasswordOR {
    text-align: center;
    width: 60%;
    margin-top: 4%;
}

.btnReset {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5%;
    /* border: 1px solid; */
}

.btnReset a {
    width: 45%;
    height: 2.8rem;
    font-size: 1.5rem;
    background-color: #FFEE58;
    border: 1px solid;
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgotPasswordbottomShade {
    position: absolute;
    top: 65%;
    left: -8%;
    transform: translate(-50%, -50%);
    height: 210.5px;
    width: 421px;
    border-radius: 210px 200px 0 0;
    background-color: rgba(221, 239, 227, 0.50);
    ;
    position: absolute;
    margin-top: 10%;
    margin-left: 10%;
    z-index: 10;
}

.mainrightDiv_forgetPassword {
    z-index: 10;
}

.forgotPasswordRightShade {
    z-index: 0;
    width: 249px;
    height: 205px;
    flex-shrink: 0;
    background-color: rgba(221, 239, 227, 0.50);
    border-radius: 249px 249px 200px 0;
    position: absolute;
    margin-left: 80%;
    margin-top: -2%;
}

@media (max-width:1080px) {
    .mainrightDiv_forgetPassword {
        display: none;
    }

    .forgotPasswordRightShade {
        display: none;
    }

    .forgotPasswordTopShade {
        display: none;
    }

    .mainLeftDiv_forgetPassword {
        width: 100%;
    }

    .signEmailLogo_forgotPassword {
        width: 20%;
    }
}