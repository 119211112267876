* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    font-weight: 500;
}

.mainDiv_login {
    width: 100%;
    display: flex;
    height: 100vh;
}

.logoMobile {
    width: 50%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
}

.logoMobile img {
    margin-top: 15%;
    filter: brightness(100%);
    z-index:1;
}

.LoginLeftShade {
    position: absolute;
    width: fit-content;
    width: 43%;
}

.loginLowerDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin-right: 5%;
}

.userLoginDiv {
    padding: 2rem;
}

.headerLoginDiv {
    padding: 2rem;
}

.userAndPassword {
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userAndPassword img {
    width: 2.5rem;
}

.userAndPasswprd h4 {
    text-align: end;
}

.loginUser {
    /* border: 1px solid; */
    width: 80%;
    display: flex;
    border-bottom: 2px solid black;
}

.loginUser input {
    border: none;
    text-align: center;
    width: 100%;
}

.loginPassword {
    width: 80%;
    margin-top: 2%;
    display: flex;
    border-bottom: 2px solid black;
}

.loginPassword input {
    border: none;
    width: 100%;
    text-align: center;
    
}
.input{
    text-align: left;
    font-size: 1.3rem;
}
.password{
    font-size: 1.3rem;
    text-align: center;
}
.input:focus{
    outline: none;
}
.password:focus{
    outline: none;
}

.forgetPassword{
    width: 80%;
    text-align: end;
}
.forgetPassword a{
    color: black;
}

.createLogin {
    display: flex;
}

.createLogin h4 {
    font-weight: 400;
}

.createLogin a {
    color: black;
}

.loginBtn button {
    margin-top: 20%;
    width: 20rem;
    height: 2.5rem;
    border-radius: 5px;
    background-color: #FFEE58;
    font-size: 1.5rem;
    cursor: pointer;
}

@media (min-width:320px) and (max-width:375px){
    .logoMobile{
        display: none;
    }
    .loginLowerDiv{
        width: 100%;
    }
    .headerLoginDiv{
        padding: 1rem;
    }
    .userAndPassword{
        padding: 1rem;
    }
    .loginBtn{
        width: 80%;
    }
    .loginBtn button{
        width: 100%;
    }
}
@media (min-width:375px) and (max-width:420px){
    .logoMobile{
        display: none;
    }
    .LoginLeftShade{
        width: 30%;
    }
    .loginLowerDiv{
        width: 100%;
    }
    .headerLoginDiv{
        padding: 1rem;
    }
    .userAndPassword{
        padding: 1rem;
    }
    .loginBtn{
        width: 80%;
    }
    .loginBtn button{
        width: 100%;
    }
}
@media (min-width:420px) and (max-width:580px){
    .logoMobile{
        display: none;
    }
    .LoginLeftShade{
        width: 30%;
    }
    .loginLowerDiv{
        width: 100%;
    }
    .headerLoginDiv{
        padding: 1rem;
    }
    .userAndPassword{
        padding: 1rem;
    }
    .loginBtn{
        width: 80%;
    }
    .loginBtn button{
        width: 100%;
    }
}
@media (min-width:580px) and (max-width:720px){
    .logoMobile{
        display: none;
    }
    .LoginLeftShade{
        width: 30%;
    }
    .loginLowerDiv{
        width: 100%;
    }
    .headerLoginDiv{
        padding: 1rem;
    }
    .userAndPassword{
        padding: 1rem;
    }
    .loginBtn{
        width: 80%;
    }
    .loginBtn button{
        width: 100%;
    }
}