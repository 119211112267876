* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: '"Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif';
    font-weight: 500;
}

.mainDiv_gallery {
    /* border: 1px solid red; */
    background-color: #DDEFE3;
    margin: 0 1rem 0 1rem;
    border-radius: 25px;
}

.headerDiv_gallery {
    text-align: center;
    padding: 2rem;
    font-size: 1.8rem;
}

.ourGalleryMainDiv {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
}

.imageFirstDiv_gallery {
    width: 100%;
    display: flex;

}

.imageInnerFirst_gallery {
    width: 49%;
    padding: 2rem;
}

.imageInnerFirst_gallery img {
    width: 100%;
    border-radius: 15px;
}


.imageInnerSecond_gallery {
    width: 100%;
    padding: 2rem 2rem 0rem 0rem;
}

.imageInnerSecond_gallery img {
    width: 100%;
    margin-left: 2%;
    border-radius: 15px;
    height: -webkit-fill-available;
}

.imageSecondDiv_gallery {
    width: 100%;
    display: flex;

}

.imageInner_First_First_gallery {
    width: 50%;
    padding: 2rem;
}

.imageInner_First_First_gallery img {
    width: 100%;
    border-radius: 15px;
}

.imageInner_Second_Second_gallery {
    width: 48%;
    padding: 2rem 0rem 0rem 2rem;
}

.imageInner_Second_Second_gallery img {
    width: 48%;
    margin-left: 2%;
    border-radius: 15px;
}

.Btn_gallery {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
}

.Btn_gallery button {
    width: 13rem;
    font-size: 1.4rem;
    height: 2.5rem;
    border-radius: 5px;
    border: 1px solid;
    background-color: #FFEE58;
    cursor: pointer;
}
/* .Btn_gallery button:hover {
    background-color: white;
    color: black;
} */

@media (min-width:320px) and (max-width:375px) {
    .headerDiv_gallery {
        font-size: 1rem;
    }

    .imageInnerFirst_gallery {
        padding: 0.5rem;
    }

    .imageInnerSecond_gallery {
        padding: 0.5rem 0.5rem 0 0;
    }

    .imageInner_Second_Second_gallery {
        padding: 0.5rem 0 0 0.5rem;
    }

    .imageInner_First_First_gallery {
        padding: 0.5rem;
    }
    .mainDiv_gallery {
        margin-top: 25%;
    }
}

@media (min-width:375px) and (max-width:420px) {
    .headerDiv_gallery {
        font-size: 1rem;
    }

    .imageInnerFirst_gallery {
        padding: 0.5rem;
    }

    .imageInnerSecond_gallery {
        padding: 0.5rem 0.5rem 0 0;
    }

    .imageInner_Second_Second_gallery {
        padding: 0.5rem 0 0 0.5rem;
    }

    .imageInner_First_First_gallery {
        padding: 0.5rem;
    }
    .mainDiv_gallery {
        margin-top: 25%;
    }
}

@media (min-width:420px) and (max-width:580px) {
    .headerDiv_gallery {
        font-size: 1rem;
    }

    .imageInnerFirst_gallery {
        padding: 0.5rem;
    }

    .imageInnerSecond_gallery {
        padding: 0.5rem 0.5rem 0 0;
    }

    .imageInner_Second_Second_gallery {
        padding: 0.5rem 0 0 0.5rem;
    }

    .imageInner_First_First_gallery {
        padding: 0.5rem;
    }
    .mainDiv_gallery {
        margin-top: 25%;
    }
}

@media (min-width:580px) and (max-width:720px) {
    .headerDiv_gallery {
        font-size: 1rem;
    }

    .imageInnerFirst_gallery {
        padding: 0.5rem;
    }

    .imageInnerSecond_gallery {
        padding: 0.5rem 0.5rem 0 0;
    }

    .imageInner_Second_Second_gallery {
        padding: 0.5rem 0 0 0.5rem;
    }

    .imageInner_First_First_gallery {
        padding: 0.5rem;
    }
    .mainDiv_gallery {
        margin-top: 15%;
    }
}

@media (min-width:720px) and (max-width:1080px) {
    .mainDiv_gallery {
        margin-top: 25%;
    }
}
@media (min-width:1080px) and (max-width:1980px) {
    .mainDiv_gallery {
        margin-top: 15%;
    }
}

@media (min-width:1980px){
    .mainDiv_gallery{
        margin-top: 15%;
    }
}