* {
    margin: 0;
    padding: 0;
}

.mainPAge_WomenEmpowerment {
    width: 100%;
}

.headerMAinDIv_WomenEmpowerment {
    background-image: url("../images//agendaBG.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    padding: 2rem 0;
    margin-top: 4rem;
    position: relative;
    z-index: 1;
}
.headingDiv_WomenEmpowerment {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem;
}

.headingDiv_WomenEmpowerment h1 {
    color: #fff;
    font-weight: 600;
}



.headingDiv_WomenEmpowerment h3 {
    padding-top: 2rem;
    width: 84%;
    text-align: center;
    color: #fff;
    text-transform: capitalize;
}

.underLine_WomenEmpowerment {
    height: 3px;
    background: #FFEE58;
    margin-top: 1rem;
    width: 35%;
    position: relative;
}

.InnerMAinDiv_WomenEmpowerment {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MAinDiv_WomenEmpowerment {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 4rem;
}

.MainDiv_WomenEmpowerment {
    width: 80%;

}

.Header_WomenEmpowerment {
    text-transform: capitalize;
}

.Header_WomenEmpowerment h2 {
    font-weight: 600;
}

.underLineHeadingMainDiv_WomenEmpowerment {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 1rem;
}

.underLineHeading_WomenEmpowerment {
    height: 2px;
    background-color: #FFEE58;
    width: 5%;
}
.ContentMainDiv_WomenEmpowerment{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 5rem;
}
.ContentInnerMainDiv_WomenEmpowerment{
    width: 80%;
    display: flex;
    justify-content: space-between;
}
.contentImgMainDiv_WomenEmpowerment{
    width: 48%;
}
.contentImgMainDiv_WomenEmpowerment img{
    width: 100%;
}
.contentDiv_WomenEmpowerment{
    width: 48%;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: center;
}
.contentDiv_WomenEmpowerment h1{
    font-weight: 700;
    text-transform: capitalize;
    padding-bottom: 1rem;
}
.contentDiv_WomenEmpowerment h2{
    font-weight: 100;
    text-transform: capitalize;
}

