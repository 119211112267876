.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    cursor: pointer;
    /* background-color: rgb(180, 216, 247); */
    border-radius: 15px;
}

.progress-container {
    /* height will be fixed in all the container */

    /* height: 20px; */
    width: 100%;
    background-color: rgb(237, 225, 225);
    position: relative;
    border-radius: 15px;
}

.indicator {
    /* height will be fixed in all the container */

    height: 20px;
    background-color: #FFAF65;
    width: 50%;
    animation: inidicator 1s;
    transition: 1s ease-in-out;
    border-radius: 15px;
}
.orange-progress-bar .progress-bar {
    background-color: #FFAF65;
  }

.inicatorcircle {
    /* height will be fixed in all the container */

    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: orangered;
    position: absolute;
    left: 48%;
    top: 0%;
    animation: inidicatorcircle 1s;
    transition: 1s ease-in-out;
}

@keyframes inidicatorcircle {
    from {
        left: 0%;
    }

    to {
        left: 48%;
    }
}

@keyframes inidicator {
    from {
        width: 0%;
    }

    to {
        width: 50%;
    }
}



.progress-check{
    height: 20px;
    width: 100%;
    border-radius: 5px;
    position: relative;
    box-shadow: 5px -10px 12px 3px rgba(0,0,0,0.3) inset;
    /* padding: 8px; */
    /* border: 1px solid; */
}
.fill{
    background: linear-gradient(45deg, rgba(255,255,255,0.5) 25%,transparent 25%,transparent 50%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0.5) 75%,transparent 75%, transparent);
    background-size: 40px;
    border-radius: 5px;
    animation: move 0.5s linear infinite;
}
@keyframes move{
    100%{
        background-position: 40px 0px;
    }
}
.a{
    background-color: #FFAF65;
    width: 65%;
    height: 100%;
}