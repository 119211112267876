* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
}

.mainContainer_footer {
    margin-top: 5%;
    background-color: #DDEFE3;
    height: auto;
}

.mainDivUpperDiv_footer {
    display: flex;
    justify-content: center;
}

.mainDivUpper_footer {
    position: absolute;
    width: 33%;
    opacity: 0.2;
}

.mainDiv_footer {
    margin-top: 1rem;
    height: auto;
    padding: 1em;
    font-family: "Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif;

}

.upperFooter_footer {
    display: flex;
}

.logo_footer {
    width: 30%;
    padding: 1rem 2rem 0rem 2rem;
}

.cause_footer {
    width: 17.5%;
}

.work_footer {
    width: 17.5%;
}

.aboutus_footer {
    width: 17.5%;
}

.support_footer {
    width: 17.5%;
}

.logoImg_footer img {
    width: 109px;
    height: 109px;
}

.socilaLogo_footer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 2%;
}

.socilaLogo_footer img {
    height: 200px;
    width: 200px;
}

.logo_footer h5 {
    font-weight: 100;
}

.cause_footer h5 {
    font-weight: 100;
}

.work_footer h5 {
    font-weight: 100;
}

.aboutus_footer h5 {
    font-weight: 100;
}

.support_footer h5 {
    font-weight: 100;
}

.paymentImg_footer {
    width: 80%;
    display: flex;
    justify-content: end;
}

.paymentImg_footer img {
    width: 40px;
    height: 40px;
    margin-left: 2%;
}

.copyRight {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 5%;
}

.copyRight a {
    text-decoration: none;
    color: black;
    display: flex;
}

.wezbocloudImg {
    width: 3rem;
}
.aboutus_footerDetails{
    display: flex;
    flex-direction: column;
}
.dropDownNews_Footer a{
    color: #7a7a7a;
    line-height: 1.5;
}
.dropDownNews_Footer a:hover{
    color: black;
    cursor: pointer;
}

@media (max-width:680px) {
    .cause_footerDetails {
        display: none;
    }

    .work_footerDetails {
        display: none;
    }

    .aboutus_footerDetails {
        display: none;
    }

    .support_footerDetails {
        display: none;
    }

    .upperFooter_footer {
        display: flex;
        flex-direction: column;
    }

    .logo_footer {
        border-bottom: 1px solid;
        width: 100%;
    }

    .work_footer {
        width: 100%;
        padding-left: 2rem;
    }

    .cause_footer {
        width: 100%;
        padding-left: 2rem;
    }

    .aboutus_footer {
        width: 100%;
        padding-left: 2rem;
    }

    .support_footer {
        width: 100%;
        padding-left: 2rem;
    }

    .mainDivUpper_footer {
        display: none;
    }

    .logoImg_footer {
        display: flex;
        justify-content: center;
    }

    .mobileViewFooter {
        display: flex;
        justify-content: space-between;
    }

    .cause_footer {
        margin-top: 5%;
    }

}

@media (min-width:680px) {
    .addIcon {
        display: none;
    }

}

@media (max-width:680px) {
    .paymentImg_footer {
        display: none;
    }

    .copyRight h3 {
        font-size: 0.7rem;
    }
}

@media (min-width:1440px) {
    .mainContainer_footer {
        height: auto;
    }

    .mainDiv_footer {
        height: auto;
    }

    .mainDivUpper_footer {
        width: auto;
    }
}