* {
    margin: 0;
    padding: 0;
}

.mainDiv_withdraw {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 3rem;
}

.innerMain_withdraw {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: center;
}

.innerMainLeftDiv {
    display: flex;
    flex-direction: column;
}

.icon_withdraw {
    width: 2%;
    display: flex;
    align-items: center;
}

.icon_withdraw img {
    width: 100%;
}

.heading_withdraw {
    padding-left: 5%;
    font-size: 1rem;
    display: flex;
    align-items: center;
}

.InnerMainRightDiv {
    width: 40%;
    padding: 1.5rem;
}

.innerInnerMain_withdraw {
    display: flex;
    padding: 1.5rem;
    border-bottom: 1px solid #eae6e6;
}

.InnerMainRightDivInner {
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid #eae6e6;
}

.InnerMainRightDivInnerImgDiv {
    width: 4%;
}

.InnerMainRightDivInnerImg {
    width: 100%;
}

.InnerMainRightDivInnerContentDiv {
    padding-left: 1rem;
}
.InnerMainRightDivInnerContentDiv h2{
    font-size: 1rem;
}
.InnermainDownDiv_withdraw{
    padding-top: 1rem;
}
.InnermainDownDiv_withdraw h2{
    color: red;
    font-size: 1rem;
}
