* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Maison Neue Book", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
}

.upperMianDiv_Cate {
    width: 100%;
    /* border: 2px solid; */
    filter: brightness(10);
    text-align: center;
    margin-top: 2%;
}

.categoriesUpperDiv {
    /* text-decoration: underline; */
    line-height: 3rem;
}

.categoriesList {
    line-height: 3rem;
    display: flex;
    justify-content: space-around;
}

.categoriesList a {
    font-weight: 400;
}

.Categories_Div_lower {
    display: flex;
    justify-content: space-around;
    padding: 2rem 0.3rem 0 0.3rem;

}

.Categories_prevSvg {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 1%;
    bottom: 50%;
    cursor: pointer;
}

.Categories_nxtSvg {
    cursor: pointer;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 3%;
    bottom: 50%;
    /* margin-right: -90%; */
    /* margin-top: -30%; */
}

.Categories_sliderImgMainDiv {
    /* width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; */
    width: 98%;
}

.Categories_sliderImgMain {
    position: relative;
}

.Categories_sliderimg {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 10px;
}

.Categories_belowSliderImg {
    padding: 2rem;
    width: 100%;
    height: 40%;
    /* border: 1px solid red; */
    position: absolute;
    font-size: 1.3rem;
    /* right: 50%; */
    /* left: 50%; */
    /* bottom: 1%; */
    top: 60%;
    background-color: rgba(0, 0, 0, 0.5);
}

.Categories_belowSliderImg button {
    width: 10rem;
    height: 2rem;
    border-radius: 5px;
    border: 1px solid;
    background-color: #FFEE58;
}

.savingMore {
    display: flex;
    flex-direction: column;
}

.savingMoreInner {
    display: flex;
    justify-content: space-around;
}

.savingMore1 {
    width: 40%;
    display: flex;
    justify-content: center;
}

.savingMore2 {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.savingh1_2 {
    font-size: 5rem;
}

.savingh1_1 {
    font-size: 2.8rem;
    font-weight: 400;
}

.savingBtn {
    width: 20rem;
    height: 3rem;
    border-radius: 10px;
    border: 1px solid;
    background-color: #FFEE58;
    font-size: 1.5rem;
}

.conatin-talkUsMainDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 4%;
}

.conatin-talkUs {
    width: 70%;
    display: flex;
    justify-content: space-around;
}

.conatin-talkUs button {
    width: 10rem;
    height: 3rem;
    font-size: 1.5rem;
    border-radius: 10px;
    border: 1px solid;

}

.loadMore_CategoriesBTN {
    width: 100%;
    display: flex;
    justify-content: center;
}

.loadMore_CategoriesBTN button {
    width: 10rem;
    height: 3rem;
    font-size: 1.5rem;
    border: 1px solid;
    border-radius: 10px;
    background-color: #FFEE58;
    cursor: pointer;
}

.Categories_belowSliderImg {
    display: flex;
    padding: 0;
    border-radius: 10px;
    height: -webkit-fill-available;
    bottom: 1%;
}

.catgriesSliderDataleft {
    width: 80%;
    padding: 2rem;
}

.catgriesSliderDataleftUpper {
    color: #fff;
}

.catgriesSliderDataRight {
    text-align: center;
    width: 20%;
    color: white;
    background-color: #ae893d;
    padding: 1rem;
    border-radius: 10px;
}

.categriesSliderRightPanelupper {
    color: #fff;
    font-size: 3rem;
}

.categriesSliderRightPanellower {
    font-size: 2.2rem;

}

@media (min-width:720px) and (max-width:1080px) {
    .catgriesSliderDataleft {
        padding: 1rem;
        width: 75%;
    }

    .Categories_belowSliderImg button {
        width: 5rem;
    }

    .catgriesSliderDataRight {
        width: 25%;
    }

    .categriesSliderRightPanelupper {
        font-size: 2rem;
    }

    .categriesSliderRightPanellower {
        font-size: 1.5rem !important;
    }
}

@media (min-width:320px) and (max-width:375px) {
    .categoriesList {
        display: none;
    }

    .categoriesUpperDiv {
        display: flex;
        justify-content: center;
    }

    .categoriesHeader {
        display: flex;
        align-items: center;
        color: black;
    }

    .MobilecategoriesList {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .MobilecategoriesListDetails {
        width: 80%;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        filter: brightness(1);

    }
    .MobilecategoriesListDetails a{
        color: black;
        text-decoration: none;
    }

    .upperMianDiv_Cate {
        border: none;
    }

    .MobilecategoriesList a {
        padding: 0.5rem;
    }

    .Categories_nxtSvg {
        margin-left: 32%;
        margin-top: -85%;
    }

    .Categories_prevSvg {
        margin-top: -85%;
    }

    .savingMoreInner {
        display: flex;
        flex-direction: column;
    }

    .savingMore1 {
        width: 100%;
    }

    .savingMore2 {
        width: 100%;
    }

    .savingh1_1 {
        font-size: 2rem;
    }

    .savingMore1 img {
        width: 100%;
    }

    .savingh1_2 {
        font-size: 2.5rem;
    }

    .savingBtn {
        width: 80%;
        font-size: 1.5rem;
    }

    .conatin-talkUs {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .conatin-talkUs h2 {
        font-size: 1.2rem;
    }

    .conatin-talkUs button {
        font-size: 1.2rem;
    }

    .catgriesSliderDataleft {
        padding: 1rem;
        width: 60%;
    }

    .Categories_belowSliderImg button {
        width: 5rem;
    }

    .catgriesSliderDataRight {
        width: 40%;
    }

    .categriesSliderRightPanelupper {
        font-size: 1.6rem;
    }

    .categriesSliderRightPanellower {
        font-size: 1.2rem !important;
    }
}

@media (min-width:375px) and (max-width:420px) {
    .categoriesList {
        display: none;
    }

    .categoriesUpperDiv {
        display: flex;
        justify-content: center;
    }

    .categoriesHeader {
        display: flex;
        align-items: center;
        color: black;
    }

    .MobilecategoriesList {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .MobilecategoriesListDetails {
        width: 80%;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        filter: brightness(1);

    }

    .upperMianDiv_Cate {
        border: none;
    }

    .MobilecategoriesList a {
        padding: 0.5rem;
    }

    .Categories_nxtSvg {
        margin-left: 32%;
        margin-top: -85%;
    }

    .Categories_prevSvg {
        margin-top: -85%;
    }

    .savingMoreInner {
        display: flex;
        flex-direction: column;
    }

    .savingMore1 {
        width: 100%;
    }

    .savingMore2 {
        width: 100%;
    }

    .savingh1_1 {
        font-size: 2rem;
    }

    .savingMore1 img {
        width: 100%;
    }

    .savingh1_2 {
        font-size: 2.5rem;
    }

    .savingBtn {
        width: 80%;
        font-size: 1.5rem;
    }

    .conatin-talkUs {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .conatin-talkUs h2 {
        font-size: 1.2rem;
    }

    .conatin-talkUs button {
        font-size: 1.2rem;
    }

    .catgriesSliderDataleft {
        padding: 1rem;
        width: 60%;
    }

    .Categories_belowSliderImg button {
        width: 5rem;
    }

    .catgriesSliderDataRight {
        width: 40%;
    }

    .categriesSliderRightPanelupper {
        font-size: 1.6rem;
    }

    .categriesSliderRightPanellower {
        font-size: 1.3rem !important;
    }
}

@media (min-width:420px) and (max-width:580px) {
    .categoriesList {
        display: none;
    }

    .categoriesUpperDiv {
        display: flex;
        justify-content: center;
    }

    .categoriesHeader {
        display: flex;
        align-items: center;
        color: black;
    }

    .MobilecategoriesList {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .MobilecategoriesListDetails {
        width: 80%;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        filter: brightness(1);

    }

    .upperMianDiv_Cate {
        border: none;
    }

    .MobilecategoriesList a {
        padding: 0.5rem;
    }

    .Categories_nxtSvg {
        margin-left: 32%;
        margin-top: -65%;
    }

    .Categories_prevSvg {
        margin-top: -65%;
    }

    .savingMoreInner {
        display: flex;
        flex-direction: column;
    }

    .savingMore1 {
        width: 100%;
    }

    .savingMore2 {
        width: 100%;
    }

    .savingh1_1 {
        font-size: 2rem;
    }

    .savingMore1 img {
        width: 100%;
    }

    .savingh1_2 {
        font-size: 2.5rem;
    }

    .savingBtn {
        width: 80%;
        font-size: 1.5rem;
    }

    .conatin-talkUs {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .conatin-talkUs h2 {
        font-size: 1.2rem;
    }

    .conatin-talkUs button {
        font-size: 1.2rem;
    }

    .catgriesSliderDataleft {
        padding: 1rem;
        width: 60%;
    }

    .Categories_belowSliderImg button {
        width: 5rem;
    }

    .catgriesSliderDataRight {
        width: 40%;
    }

    .categriesSliderRightPanelupper {
        font-size: 1.7rem;
    }

    .categriesSliderRightPanellower {
        font-size: 1.4rem !important;
    }
}

@media (min-width:580px) and (max-width:780px) {
    .categoriesList {
        display: none;
    }

    .categoriesUpperDiv {
        display: flex;
        justify-content: center;
    }

    .categoriesHeader {
        display: flex;
        align-items: center;
        color: black;
    }

    .MobilecategoriesList {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .MobilecategoriesListDetails {
        width: 80%;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        filter: brightness(1);

    }

    .upperMianDiv_Cate {
        border: none;
    }

    .MobilecategoriesList a {
        padding: 0.5rem;
    }

    .Categories_nxtSvg {
        margin-left: 32%;
        margin-top: -45%;
    }

    .Categories_prevSvg {
        margin-top: -45%;
    }

    .savingMoreInner {
        display: flex;
        flex-direction: column;
    }

    .savingMore1 {
        width: 100%;
    }

    .savingMore2 {
        width: 100%;
    }

    .savingh1_1 {
        font-size: 2rem;
    }

    .savingMore1 img {
        width: 100%;
    }

    .savingh1_2 {
        font-size: 2.5rem;
    }

    .savingBtn {
        width: 80%;
        font-size: 1.5rem;
    }

    .conatin-talkUs {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .conatin-talkUs h2 {
        font-size: 1.2rem;
    }

    .conatin-talkUs button {
        font-size: 1.2rem;
    }

    .catgriesSliderDataleft {
        padding: 1rem;
        width: 70%;
    }

    .Categories_belowSliderImg button {
        width: 5rem;
    }

    .catgriesSliderDataRight {
        width: 30%;
    }

    .categriesSliderRightPanelupper {
        font-size: 1.8rem;
    }

    .categriesSliderRightPanellower {
        font-size: 1.4rem !important;
    }
}

@media (min-width:780px) {
    .MobilecategoriesListDetails {
        display: flex;
        /* flex-direction: row; */
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 3rem;

    }

    .categoriesList a {
        border: 1px solid black;
        border-radius: 10px;
        padding: 0.5rem;
        font-size: 1rem;
    }

    .categoriesList {
        line-height: 1;
    }

}

@media (min-width:780px) and (max-width:1080px) {
    .categoriesList a {
        font-size: 0.6rem;
    }

    .categoriesUpperDiv {
        display: flex;
        justify-content: center;
    }

    .categoriesHeader {
        display: flex;
        align-items: center;
        color: black;
    }
}

@media (min-width:780px) {
    .dropdownBrowserFundrasier {
        display: none;
    }
}
@media (min-width:1080px) and (max-width:1440px){
    .catgriesSliderDataleft{
        width: 70%;
    }
    .catgriesSliderDataRight{
        width: 30%;
    }
    .catgriesSliderDataRight{
        font-size: 1.8rem;
    }
    .categriesSliderRightPanellower{
        font-size: 1.6rem;
    }
}
@media (min-width:1440px){
    .catgriesSliderDataleft{
        width: 70%;
    }
    .catgriesSliderDataRight{
        width: 30%;
    }
    .catgriesSliderDataRight{
        font-size: 1.8rem;
    }
    .categriesSliderRightPanellower{
        font-size: 1.6rem;
    }
}