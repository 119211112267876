*{
    margin: 0;
    padding: 0;
}
.mainPAge_Agenda{
    width: 100%;
}
.headerMAinDIv_Agenda{
    background-image: url("../images//agendaBG.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    padding: 2rem 0;
    margin-top: 4rem;
    position: relative;
    z-index: 1;
}
.headingDiv_Agenda{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem;
}
.headingDiv_Agenda h1{
    color: #fff;
    font-weight: 600;
}
.underline_Agenda{
    border-bottom: 2px solid red;
    border-bottom-width: 40%;
    padding-top: 2rem !important;
}
.headingDiv_Agenda h3{
    padding-top: 2rem;
    width: 84%;
    text-align: center;
    color: #fff;
    text-transform: capitalize;
}
.underLine_Agenda {
    height: 3px;
    background: #FFEE58;
    margin-top: 1rem;
    width: 50%;
}
.focusMainDiv_Agenda{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 4rem;
}
.focusInnerMainDiv_Agenda{
    width: 80%;
}
.focusHeadingMainDiv_Agenda{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.focusHeadingMainDiv_Agenda h1{
    font-weight: 600;
    text-transform: capitalize;
}
.underLineFocus_Agenda {
    height: 4px;
    background: #FFEE58;
    margin-top: 1rem;
    width: 8%;
}
.focusContentInnerMainDiv_Agenda{
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
    padding: 1rem;
    box-shadow: 0px 0px 5px 0px #0000002b;

}
.focusContentMainDiv_Agenda{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 1rem;
    padding-top: 4rem;
}
.focusContentDiv_Agenda{
    padding: 1rem;
}
.focusContentDiv_Agenda h1{
    line-height: 1.5;
    font-weight: 500;
    text-transform: capitalize;
}
.focusNumberDiv_Agenda h2{
    font-weight: 800;
    color: #00000038;
}

.lowwerMainDiv_Agenda{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 8rem;
}
.LowerinnerMainDiv_Agenda{
    /* width: 70%; */
    background-image: url("../images/huanitarianBGImg.png");
    background-size: cover;
    display: flex;
    justify-content: space-around;
    text-align: center;
    height: max-content;
    flex-direction: column;
    align-items: center;
    /* border-radius: 10px; */
}
.LowerinnerMainDiv_Agenda h1{
    width: 68%;
    text-transform: capitalize;
    color: #ffffff;
}   
.LowerinnerDiv_Agenda{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.underLineLower_Agenda {
    height: 3px;
    background: #FFEE58;
    margin: 0.5rem;

}
.donateBtn_Agenda button{
    border-radius: 5px;
    background-color: #FFEE58;
    border: none;
}
.lowerMainDivStartFundriser_Agenda {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 5rem;
}
.lowerInnerMainDivStartFundriser_Agenda {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.lowerUpperMainDivStartFundriser_Agenda {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.lowerUpperMainDivStartFundriser_Agenda h2 {
    /* width: 20%; */
    color: #4D4D4D;
    font-weight: 600;
}
.middleUnderLine_Agenda {
    height: 2px;
    background-color: #4D4D4D;
    width: 30%;
}
.lowerDownMainDivStartFundriser_Agenda{
    width: 100%;
    padding-top: 1rem;
    display: flex;
    justify-content: center;
}
.lowerDownMainDivStartFundriser_Agenda button{
    border-radius: 5px;
    background-color: #FFEE58;
    border: none;
}